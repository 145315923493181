import { useState } from "react"
import { ITag } from "../treeExtensions/FieldInterfaces"
import { Tag } from "./Tag"
import { Title } from "./Title"
import { ValidationResponse } from "./ValidationResponse"
import { useModel } from "../hooks/useModel"

export interface props {
    title: string,
    hideTitle?: boolean,
    modelPath: string,
    tags: ITag[],
    onSelect: (selectedTags: ITag[]) => ValidationResponse | undefined
}

interface internalTag {
    tag: ITag,
    currentlySelected: boolean | undefined
}

export const TagGroup = (props: props) => {

    const model = useModel();

    const getInitialValue = () => {
        let previouslySelectedTags: string[] | undefined = undefined;
        if(props.modelPath != undefined) {
            previouslySelectedTags = model.Get(props.modelPath)
        }
        const newTagRecords = props.tags.map((tag) => {
            return {
                tag: tag,
                currentlySelected: previouslySelectedTags != undefined && previouslySelectedTags.includes(tag.tagId)
            } as internalTag
        });

        return newTagRecords;
    }

    const [tagRecords, setTagRecords] = useState(getInitialValue());

    const handleTagMutualExclusion = (currentlyClickedTag: internalTag, copyTagRecords: internalTag[]) => {
        if(currentlyClickedTag.tag.turnOff != undefined) {

            currentlyClickedTag.tag.turnOff.forEach((tagToTurnOff) => {

                let tagToTurnOffIndex = -1;
                tagRecords.forEach((t, index) => {
                    if(t.tag.tagId == tagToTurnOff) {
                        tagToTurnOffIndex = index;
                    }
                });

                if(tagToTurnOffIndex !== -1) {
                    copyTagRecords[tagToTurnOffIndex].currentlySelected = false;
                    setTagRecords(copyTagRecords);
                } else {
                    throw `Can't find excluded tag index for tag ${tagToTurnOff}`;
                }
            })
        }
    }

    const tagClicked = (index: number, selected: boolean) => {
        const copyTagRecords = Array.from(tagRecords);

        const currentlyClickedTag = copyTagRecords[index];

        currentlyClickedTag.currentlySelected = selected;

        handleTagMutualExclusion(currentlyClickedTag, copyTagRecords);

        const currentlySelectedTags = copyTagRecords.filter((copy) => copy.currentlySelected == true)
        const modelData = currentlySelectedTags.map((copy) => copy.tag.tagId)

        model.Set(props.modelPath, modelData)

        props.onSelect(currentlySelectedTags.map(t => t.tag));
    }

    return <div>
        <Title text={props.title} className={`mt-7 ${props.hideTitle == true ? "hidden" : ""}`} />
        {tagRecords.map((record, index) => {
            return <Tag key={index} title={record.tag.tagId} initiallySelected={false} onClick={(selected) => tagClicked(index, selected)} currentlySelected={record.currentlySelected} />
        })}
    </div>
}
