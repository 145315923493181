//https://dev.to/bytebodger/synchronous-state-with-react-hooks-1k4f

import { useState } from 'react';

export const useSynchronousState = <T>(initialValue: T) => {
    const [state, updateState] = useState<T>(initialValue);

    let current = state;
    const get = (): T => current;

    const set = (newValue: T): T => {
        current = newValue;
        updateState(newValue);
        return current;
    }

    return {
        get,
        set,
    }
}
