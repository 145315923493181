import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldConstraints, FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const captureAccessInstructionsPrependedMessagePath = "model.jobCommentPrependedMessages.accessInstructions";

class CaptureAccessInstructionsTreeNodeData extends JbpTreeNodeData {
    constructor() {
        super("Please provide any additional information regarding parking, access, or directions", [
            {
                fieldType: FieldType.TextArea,
                title: "Access Instructions",
                placeHolder: "Tell us how to access the site including detailed descriptions.",
                modelPath: "model.results.accessInstructions",
                isValid: (_value: string) => {
                    return {
                        isValid: true
                    }
                },
                onChange: (value: string) => {
                    if(value.trim().length > 0) {
                        Model.GetModel().Set(captureAccessInstructionsPrependedMessagePath, `Access Instructions - ${value}\n`)
                    }
                    else {
                        Model.GetModel().Set(captureAccessInstructionsPrependedMessagePath, ``)
                    }
                },
                getDynamicFieldConstraints: () => {
                    return {
                        maxLength: 400
                    } as FieldConstraints;
                }
            } as IEditableLabelField
        ])
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(captureAccessInstructionsPrependedMessagePath);
    }
}

export class CaptureAccessInstructionsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {
    constructor() {
        super("accessInstructions", new CaptureAccessInstructionsTreeNodeData())
    }
}
