import { TreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./IWorkflowFactory";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { WarrantOfficeDetailsNode } from "../nodes/WarrantOfficerDetailsNode";
import { JobCommentsForWarrantNode } from "../nodes/JobCommentsForWarrantNode";
import { WhatThreeWordsCommentsNode } from "../nodes/WhatThreeWordsCommentsNode";
import { SiteAddressDetailsNode } from "../nodes/SiteAddressDetailsNode";

export class BookJobWarrantWorkflowFactory extends IWorkflowFactory {

    constructor(mpan: string, serviceLine: string) {
        super(mpan, serviceLine);
    }

    private warrantOfficerCoordinationMessageNode = new InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>("liftOnSiteMessageNode", new JbpTreeNodeData("Please ensure that you agree the job date & time with the warrant officer who will be attending", []));
    private jobCommentsNode = new JobCommentsForWarrantNode(this.mpan, this.serviceLine);

    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);
        const warrantOfficeDetailsNode = new WarrantOfficeDetailsNode();
        const what3WordsCommentsNode = new WhatThreeWordsCommentsNode();
        const siteAddressDetailsNode = new SiteAddressDetailsNode();

        builder
            .AddTreeNode(this.warrantOfficerCoordinationMessageNode, IWorkflowFactory.voidWorkflowRelationship)
            .AddTreeNode(warrantOfficeDetailsNode, IWorkflowFactory.voidWorkflowRelationship)
            .AddTreeNode(siteAddressDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(what3WordsCommentsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.jobCommentsNode, IWorkflowFactory.sequenceRelationship);

        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }
}
