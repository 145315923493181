import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

const IndicatorGrey = "#B3B3BE";
const IndicatorGreen = "#00FFB9";
const IndicatorBlue = "#00CCCC";
const IndicatorRed = "#FF7687";
const IndicatorYellow = "#FFD732";

export enum IndicatorType {
    Default = IndicatorGrey,
    Active = IndicatorGreen,
    Success = IndicatorBlue,
    Error = IndicatorRed,
    Info = IndicatorYellow
}

export interface props {
    type: IndicatorType,
    isFirst: boolean,
    isLast: boolean,
    title: string
}

export const WorkflowIndicator = (props: props) => {

    const getIsFirstClass = () => props.isFirst ? "wf-is-first" : "";
    const getIsLastClass = () => props.isLast ? "wf-is-last" : "";

    const isLastIsFirstClass = `${getIsFirstClass()} ${getIsLastClass()}`

    const defaultWorkflowIndicator = (title: string) => {
        return (
            <svg id={`workflow-indicator-${ConvertStringToHtmlId(title)}`} className={`wf-default ${isLastIsFirstClass}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM3.2 16C3.2 23.0692 8.93075 28.8 16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 8.93075 23.0692 3.2 16 3.2C8.93075 3.2 3.2 8.93075 3.2 16Z" fill="#B3B3BE" />
            </svg>
        )
    }
    const activeWorkflowIndicator = (title: string) => {
        return (
            <svg id={`workflow-indicator-${ConvertStringToHtmlId(title)}`} className={`wf-active ${isLastIsFirstClass}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM3.2 16C3.2 23.0692 8.93075 28.8 16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 8.93075 23.0692 3.2 16 3.2C8.93075 3.2 3.2 8.93075 3.2 16Z" fill="#00FFB9" />
                <path d="M22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16Z" fill="#00FFB9" />
            </svg>
        )
    }
    const successWorkflowIndicator = (title: string) => {
        return (
            <svg id={`workflow-indicator-${ConvertStringToHtmlId(title)}`} className={`wf-success ${isLastIsFirstClass}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.4688 16.5438L14.4 22.475L24.2896 12.5454L22.0272 10.283L14.4 17.9502L10.7312 14.2814L8.4688 16.5438Z" fill="#00CCCC" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.1632 7.1648 0 16 0C24.8368 0 32 7.1632 32 16C32 24.8352 24.8368 32 16 32C7.1648 32 0 24.8352 0 16ZM3.2 16C3.2 23.0576 8.9424 28.8 16 28.8C23.0592 28.8 28.8 23.0576 28.8 16C28.8 8.9424 23.0592 3.2 16 3.2C8.9424 3.2 3.2 8.9424 3.2 16Z" fill="#00CCCC" />
            </svg>
        )
    }
    const errorWorkflowIndicator = (title: string) => {
        return (
            <svg id={`workflow-indicator-${ConvertStringToHtmlId(title)}`} className={`wf-error ${isLastIsFirstClass}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6 8V17.6H14.4V8H17.6Z" fill="#FF7687" />
                <path d="M16 24.8C17.3255 24.8 18.4 23.7255 18.4 22.4C18.4 21.0745 17.3255 20 16 20C14.6745 20 13.6 21.0745 13.6 22.4C13.6 23.7255 14.6745 24.8 16 24.8Z" fill="#FF7687" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM3.2 16C3.2 23.0692 8.93075 28.8 16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 8.93075 23.0692 3.2 16 3.2C8.93075 3.2 3.2 8.93075 3.2 16Z" fill="#FF7687" />
            </svg>
        )
    }

    const infoWorkflowIndicator = (title: string) => {
        return (
            <svg id={`workflow-indicator-${ConvertStringToHtmlId(title)}`} className={`wf-info ${isLastIsFirstClass}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6 8V17.6H14.4V8H17.6Z" fill="#FFD732" />
                <path d="M16 24.8C17.3255 24.8 18.4 23.7255 18.4 22.4C18.4 21.0745 17.3255 20 16 20C14.6745 20 13.6 21.0745 13.6 22.4C13.6 23.7255 14.6745 24.8 16 24.8Z" fill="#FFD732" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM3.2 16C3.2 23.0692 8.93075 28.8 16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 8.93075 23.0692 3.2 16 3.2C8.93075 3.2 3.2 8.93075 3.2 16Z" fill="#FFD732" />
            </svg>
        )
    }

    const indicator = (title: string) => {
        switch(props.type) {
            case IndicatorType.Active:
                return activeWorkflowIndicator(title);
            case IndicatorType.Default:
                return defaultWorkflowIndicator(title);
            case IndicatorType.Error:
                return errorWorkflowIndicator(title);
            case IndicatorType.Success:
                return successWorkflowIndicator(title);
            case IndicatorType.Info:
                return infoWorkflowIndicator(title);
            default:
                throw `${props.type} not identified as a valid indicator type`
        }
    }

    const getConnectorColour = () => {
        switch(props.type) {
            case IndicatorType.Active:
                return "border-solid-bold-green";
            case IndicatorType.Default:
                return "border-secondary-deep-blue-30";
            case IndicatorType.Error:
                return "border-feedback-red-text";
            case IndicatorType.Success:
                return "border-interactive-coral";
            case IndicatorType.Info:
                return "border-feedback-yellow";
            default:
                throw `${props.type} not identified as a valid indicator type`
        }
    }

    const connector = (
        <div className="h-full">
            <div className={`${getConnectorColour()} border-[2px] h-full inline-block`}></div>
            <div className="h-full inline-block"></div>
        </div>
    )

    return (
        <div className="basis-auto flex flex-col mr-5 pl-5">
            <div className="basis-6 self-center">
                {props.isFirst ? <span></span> : connector}
            </div>
            <div className="self-center">
                {indicator(props.title)}
            </div>
            <div className="flex-auto self-center relative">
                {props.isLast ? <span></span> : connector}
            </div>
        </div>
    )
}
