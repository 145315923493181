export type validHeights = "h-2" | "h-4" | "h-6" | "h-8" | "h-10" | "h-12" | "h-14" | "h-16" | "h-18" | "h-20" | "h-22" | "h-24" | "h-26" | "h-28" | "h-30" | "h-40"

export interface props {
    className?: string
    count: number,
    height: validHeights
}



export const GenericLoadingElement = (props: props) => {

    const numberOfLoadingElements = Array.from(Array(props.count).keys())

    return (
        <div className={`animate-pulse-elevation0 pb-2 pt-2 mb-4 ${props.className}`}>
            {numberOfLoadingElements.map((_element, index) => {
                return <div key={index} className={`animate-pulse-elevation1 ${props.height} mt-2 first:mt-0 ml-2 mr-2`}></div>
            })}
        </div>
    )
}
