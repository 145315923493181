import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { IndicatorType } from "../components/WorkflowIndicator";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export const useWorkflowIndicator = (node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) => {
    const isFirst = node.IsRoot();
    const isLast = node.Data().IsLastNodeInRenderList();
    const indicatorType = node.Data().WorkflowIndicator() == undefined ? IndicatorType.Default : node.Data().WorkflowIndicator();

    return {
        isFirst: isFirst,
        indicatorType: indicatorType == undefined ? IndicatorType.Default : indicatorType,
        isLast: isLast
    }
}
