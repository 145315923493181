import { Button } from "../../components/Button"
import { ITreeNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode"
import { Title } from "../../components/Title"
import { JbpTreeNodeData } from "../../treeExtensions/JbpTreeNodeData"
import { IJbpChildNodeRelationship } from "../../treeExtensions/IJbpChildNodeRelationship"
import { Fields } from "../Fields"
import { WorkflowIndicator } from "../../components/WorkflowIndicator"
import { useWorkflowIndicator } from "../../hooks/useWorkflowIndicator"

export interface props {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    mpan: string,
    serviceLine: string
}

export const TerminateView = (props: props) => {
    const { indicatorType, isFirst, isLast } = useWorkflowIndicator(props.node);

    return (
        <div id="terminate-view" className="flex flex-row">
            <WorkflowIndicator type={indicatorType} isFirst={isFirst} isLast={isLast} title={props.node.Data().Title()} />
            <div className="mb-5">
                <Title className="mt-7 mb-5" text={props.node.Data().Title()} />
                <Fields node={props.node} />
                <Button primary={true} text="Start again" icon={true} disabled={false} link={`/site/${props.mpan}/${props.serviceLine}`} />
            </div>
        </div>
    )
}
