type callbackT = (value: any) => void;

export enum ObservableEvents {
    SiteTypeUpdated,
    UserInfoLoaded,
    SubmitEditedJobForProcessing,
    JobEditSuccessful
}

export class Observable {

    private static observable: Observable | undefined;
    private channels: Map<ObservableEvents, callbackT[]>

    private constructor() {
        this.channels = new Map<ObservableEvents, callbackT[]>;
    }

    public static GetObservable() {
        if(Observable.observable == undefined) {
            Observable.observable = new Observable();
        }

        return Observable.observable;
    }

    public static RemoveObservable() {
        Observable.observable = undefined
    }

    public emit(channel: ObservableEvents, value?: any) {
        const channelCallbacks = this.channels.get(channel);

        if(channelCallbacks == undefined) {
            this.channels.set(channel, []);
        }
        else {
            channelCallbacks.forEach((cb) => {
                if(cb != undefined) {
                    cb(value)
                }
            })
        }
    }

    // Need to stop the same obervables being set up, hash the value being looked at and then check before adding it to the observables array.
    // This should resolve the issue with the over fetching of the job types seen in the technical debt issue:
    public observe(channel: ObservableEvents, cb: callbackT) {
        const channelCallbacks = this.channels.get(channel);
        if(channelCallbacks == undefined) {
            this.channels.set(channel, [cb]);
        } else {
            const noDuplicateCbFound = channelCallbacks.filter(callback => callback.toString() === cb.toString()).length === 0;

            if(noDuplicateCbFound) {
                this.channels.set(channel, [...channelCallbacks, cb]);
            } else {
                console.log("Ignoring callback add")
            }
        }
    }

    public clearChannels() {
        this.channels.clear();
    }

    public removeFromObservation(channel: ObservableEvents) {
        this.channels.delete(channel);
    }

}
