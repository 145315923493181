import { Title } from "../components/Title"
import { SiteDetail } from "./SiteDetail";
import { MeterDetail } from "./MeterDetail";
import { JobHistory } from "./JobHistory";
import { useState } from "react";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { SiteDetailsLoading } from "./loadingElements/SiteDetailsLoading";
import { MeterDetailsLoading } from "./loadingElements/MeterDetailLoading";
import { UserPermissions, getSiteErrorType, getSiteSuccessType } from "../../../APIDocs/apiTypes";
import { useStoreSite } from "../hooks/useStoreSite";
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { Logger } from "../Logger";
import { JobHistoryLoading } from "./loadingElements/JobHistoryLoading";
import { Model } from "../Model";

export const Site = () => {

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const [dataErrorOccurred, setDataErrorOccurred] = useState(false)
    const [fetchCounter, setFetchCounter] = useState(0);
    const storeSiteData = useStoreSite();

    const onSiteData = (siteData: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, siteData, setDataErrorOccurred);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Log(`Data found to not exist -> ${err?.msg}`)
        setDataErrorOccurred(true);
    }

    const getSiteData = () => {
        if(fetchCounter < 1) {
            setFetchCounter(fetchCounter + 1);
            fetchSiteData();
        }
    }

    const { isLoading: siteDataLoading, error: siteError, data: siteData, refetch: fetchSiteData } = useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site'], `jbp/${mpan}/site`, false, serviceLine, onSiteData, onSiteDataError)

    const userPermissionsString: string = Model.GetModel().Get("viewModel.fetchedData.UserPermissionsForCurrentUser");
    let userPermissionsFromModel: UserPermissions | undefined = undefined;
    try {
        userPermissionsFromModel = JSON.parse(userPermissionsString);
        switch(serviceLine) {
            case "BNO":
                if(userPermissionsFromModel?.canUseBNOServiceLine == undefined || userPermissionsFromModel?.canUseBNOServiceLine == false) {
                    return <div>You do not have permissions to view this Service line: {serviceLine}</div>
                }
                break;
            case "DNO":
                if(userPermissionsFromModel?.canUseDNOServiceLine == undefined || userPermissionsFromModel?.canUseDNOServiceLine == false) {
                    return <div>You do not have permissions to view this Service line: {serviceLine}</div>
                }
                break;
            case "HH":
                if(userPermissionsFromModel?.canUseHHServiceLine == undefined || userPermissionsFromModel?.canUseHHServiceLine == false) {
                    return <div>You do not have permissions to view this Service line: {serviceLine}</div>
                }
                break;
            case "NHH":
                if(userPermissionsFromModel?.canUseNHHServiceLine == undefined || userPermissionsFromModel?.canUseNHHServiceLine == false) {
                    return <div>You do not have permissions to view this Service line: {serviceLine}</div>
                }
                break;
            case "SUBMETER":
                if(userPermissionsFromModel?.canUseSubmeterServiceLine == undefined || userPermissionsFromModel?.canUseSubmeterServiceLine == false) {
                    return <div>You do not have permissions to view this Service line: {serviceLine}</div>
                }
                break;
            default:
                return <div>You do not have permissions to view this Service line: {serviceLine}</div>
        }
    } catch(error) {
        if(error instanceof SyntaxError) {
            //ignore failed parsing, will sort itself out on re-render
            //console.warn('Failed to parse user perms, this is expected until the model is populated at which point a re-render will happen and be successful');
        } else {
            console.error(error);
        }
    }

    getSiteData();

    const siteDetailsJBP = 'w-1/6 inline-block align-top';
    const meterDetailsJBP = 'inline-block w-1/6';
    const jobHistoryJBP = 'inline-block w-4/6 align-top';

    return (
        <div id="jbp-site-information-screen">
            <div className="ml-10">
                <Title className="mt-7 mb-5" text={`MPAN: ${mpan}`} />
                {siteDataLoading ?
                    <div>
                        <SiteDetailsLoading className={siteDetailsJBP}></SiteDetailsLoading>
                        <MeterDetailsLoading className={meterDetailsJBP}></MeterDetailsLoading>
                        <JobHistoryLoading className={jobHistoryJBP} showTitle={true}></JobHistoryLoading>
                    </div>
                    :
                    <div>
                        <SiteDetail className={siteDetailsJBP} dataErrorOccurred={dataErrorOccurred} data={siteData} error={siteError} />
                        <MeterDetail className={meterDetailsJBP} meters={siteData?.Meters} dataErrorOccurred={dataErrorOccurred} />
                        <JobHistory mpan={mpan} serviceLine={serviceLine} className={jobHistoryJBP} siteData={siteData} dataErrorOccurred={dataErrorOccurred} />
                    </div>
                }
            </div>
        </div>
    )
}
