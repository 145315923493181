import { GenericLoadingElement } from "../../components/GenericLoadingElement"

export interface props {
    className?: string
}

export const MeterDetailsLoading = (props: props) => {

    return (
        <div className={`jbp-meter-details-loading pl-5 pr-5 ${props.className}`} >
            <h1 className={`mt-7 text-xl mb-8`}>Meter Details:</h1>
            <GenericLoadingElement height="h-40" count={5} />
        </div>
    )
}
