import { WorkflowView } from "./WorkflowView";
import { BookJobSiteJobTypeWorkflowFactory } from "../../factories/BookJobSiteJobTypeWorkflowFactory";
import { useDefinedParams } from "../../hooks/useDefinedParams";
import { getSiteSuccessType, getSiteErrorType } from "../../../../APIDocs/apiTypes";
import { useJbpQuery, RestTypes } from "../../hooks/useJbpQuery";
import { useStoreSite } from "../../hooks/useStoreSite";
import { Logger } from "../../Logger";

export const BookJobSiteJobType = () => {
    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const storeSiteData = useStoreSite();

    const onSiteData = (data: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, data);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Log(`Data found to not exist -> ${err.msg}`)
    }

    const { refetch } = useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site-bookJobSiteJobType'], `jbp/${mpan}/site`, false, serviceLine, onSiteData, onSiteDataError)

    if(storeSiteData.dataStored == false) {
        refetch();
        return <span></span>
    }

    return <WorkflowView mpan={mpan} serviceLine={serviceLine} title="Pre Booking for" workflowFactory={new BookJobSiteJobTypeWorkflowFactory(mpan, serviceLine)} />
}
