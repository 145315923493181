import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { IWorkflowFactory } from "../factories/IWorkflowFactory";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, ITypographyField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class CompleteBookingNode extends CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    constructor(mpan: string, serviceLine: string) {
        const relationship = {
            workflowButtonText: "Book Job",
            relationshipModelPath: IWorkflowFactory.sequenceRelationship.relationshipModelPath,
            modelValueWhenRelationshipIsTrue: IWorkflowFactory.sequenceRelationship.modelValueWhenRelationshipIsTrue,
            navigateToPathOnSelect: `/bookingResult/${mpan}/${serviceLine}`
        } as IJbpChildNodeRelationship

        const typographyField = {
            fieldType: FieldType.Typography,
            text: "Take a moment to ensure your entries are correct and then go ahead, complete the booking"
        } as ITypographyField;

        super("completeBooking", new JbpTreeNodeData("Complete your booking", [typographyField]), relationship);
    }
}
