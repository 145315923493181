import { ExternalLink } from "../components/ExternalLink"
import { Title } from "../components/Title"
import { Typography } from "../components/Typography"

export const _404 = () => {
    return (
        <div id="jbp-404">
            <div className="ml-10">
                <Title className="mt-7 mb-5" text="Page not found" />
                <Typography className="mb-2" content="We're sorry, the resource you're looking for cannot be found." />
                <Typography className="inline-block mr-1" content="Why not try"></Typography><ExternalLink className="inline-block" link="/" linkText="searching for your site" />
            </div>
        </div>
    )
}
