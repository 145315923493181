import { ChoiceNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ChoiceNode"
import { ITreeNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode"
import { Title } from "../../components/Title"
import { WorkflowIndicator } from "../../components/WorkflowIndicator"
import { useChildNodeUndoBehaviour } from "../../hooks/useChildNodeUndoBehaviour"
import { useWorkflowIndicator } from "../../hooks/useWorkflowIndicator"
import { JbpTreeNodeData } from "../../treeExtensions/JbpTreeNodeData"
import { IJbpChildNodeRelationship } from "../../treeExtensions/IJbpChildNodeRelationship"
import { Fields } from "../Fields"
import { WorkflowButtons } from "../WorkflowButtons"

export interface props {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    mpan: string
}

export const ExclusiveChoiceView = (props: props) => {

    const childNodeUndoBehaviour = useChildNodeUndoBehaviour();
    const { indicatorType, isFirst, isLast } = useWorkflowIndicator(props.node);

    const nodeSelected = (node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) => {
        const choiceNode: ChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship> = props.node as ChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>;
        choiceNode.SetChoiceChosen(true);
        childNodeUndoBehaviour.childNodeSelected(node, props.node);
    }

    return (
        <div id="exclusive-choice-view" className="flex flex-row">
            <WorkflowIndicator type={indicatorType} isFirst={isFirst} isLast={isLast} title={props.node.Data().Title()} />
            <div className="mb-5">
                <Title className="mt-7 mb-5" text={props.node.Data().Title()} />
                <Fields node={props.node} />
                <WorkflowButtons node={props.node} mpan={props.mpan} disabled={false} nodeSelected={nodeSelected} />
            </div>
        </div>
    )
}
