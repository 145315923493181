import { GenericLoadingElement } from "../../components/GenericLoadingElement"

export interface props {
    className?: string
}

export const SiteDetailsLoading = (props: props) => {

    return (
        <div className={`jbp-site-details-loading ${props.className}`}>
            <h1 className={`mt-7 text-xl mb-8`}>Site Details:</h1>
            <GenericLoadingElement height="h-10" count={10}></GenericLoadingElement>
        </div>
    )
}
