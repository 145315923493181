import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { RenderFlagSetter } from "../visitors/RenderFlagSetter";
import { UndoModelFieldVisitor } from "../visitors/UndoModelFieldVisitor";
import { UndoModelRelationshipVisitor } from "../visitors/UndoModelRelationshipVisitor";
import { SatisfiedFalseNodeFlagSetter } from "../visitors/SatisfiedFalseNodeFlagSetter"

export const useChildNodeUndoBehaviour = () => {
    return {
        childNodeSelected: (node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, propsNode: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) => {
            propsNode.SetIsSatisfied(true);

            propsNode.Children().forEach((child) => {
                if(child.node != node && child.node.IsSatisfied()) {
                    child.node.Children().forEach((grandChild) => {
                        grandChild.node.Accept(new UndoModelRelationshipVisitor(), undefined, child.node);
                    })
                    child.node.Accept(new RenderFlagSetter(), undefined, propsNode);
                    child.node.Accept(new UndoModelFieldVisitor(), undefined, propsNode);
                    child.node.Accept(new SatisfiedFalseNodeFlagSetter())
                }
            })
        }
    }
}
