import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const liftOnSitePrependedMessagePath = "model.jobCommentPrependedMessages.liftOnSite";

class LiftOnSiteTreeNodeData extends JbpTreeNodeData {
    constructor(title: string, fields: IField[]) {
        super(title, fields);
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(liftOnSitePrependedMessagePath)
    }
}

export class LiftOnSiteNode extends ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    public static LiftOnSiteSelectedHandler() {
        Model.GetModel().Set(liftOnSitePrependedMessagePath, "Lift on site\n")
    }
    public static LiftOnSiteNotSelectedHandler() {
        Model.GetModel().Set(liftOnSitePrependedMessagePath, "")
    }

    constructor() {
        super("liftOnSiteNode", new LiftOnSiteTreeNodeData("Is there a lift on site?", []))
    }
}
