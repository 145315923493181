import { UserPermissions, getBookingErrorType, getBookingSuccessType } from "../../../APIDocs/apiTypes"
import { Title } from "../components/Title";
import { Card } from "../components/Card";
import { SiteAddress } from "./SiteAddress";
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { GenericLoadingElement } from "../components/GenericLoadingElement";
import { useNavigate } from "react-router-dom";
import { Typography } from "../components/Typography";
import { SiteContact } from "./SiteContact";
import { CancelJob } from "./CancelJob";
import { EditJob } from "./EditJob";
import { useStoreBooking } from "../hooks/useStoreBooking";
import { Observable, ObservableEvents } from "../Observable";
import { Model } from "../Model";
import { RebookJob } from "./RebookJob";
import { DateTime } from "luxon";
import { useEffect } from "react";

export const Job = () => {

    const [bookingId, mpan, serviceLine] = useDefinedParams(["bookingId", "mpan", "serviceLine"]);

    const navigate = useNavigate();
    const storeBooking = useStoreBooking();

    const onSuccess = (bookingData: getBookingSuccessType) => {
        storeBooking.storeSite(bookingData);
    }

    const { isLoading, data, refetch } = useJbpQuery<getBookingSuccessType, getBookingErrorType>(RestTypes.Get, undefined, [`${bookingId}-getJobDetails`], `jbp/${mpan}/booking/${bookingId}`, true, serviceLine, onSuccess);

    const standardPadding = "p-4";

    useEffect(() => {
        Observable.GetObservable().observe(ObservableEvents.JobEditSuccessful, () => {
            console.log("triggering refetch for new job data.")
            refetch();
        });

        return () => {
            Observable.RemoveObservable();
        }
    }, []);



    const userPermissionsString: string = Model.GetModel().Get("viewModel.fetchedData.UserPermissionsForCurrentUser");
    let userPermissionsFromModel: UserPermissions | undefined = undefined;
    try {
        userPermissionsFromModel = JSON.parse(userPermissionsString);
    } catch(error) {
        console.warn('Failed to parse user perms, this is expected until the model is populated at which point a re-render will happen and be successful');
    }

    const convertEpochMsToDateString = (epochMsString: number | undefined) => {
        return epochMsString != undefined ? DateTime.fromMillis(epochMsString).toFormat("dd/MM/yyyy") : "Not set";
    }


    if(isLoading == true) {
        return <GenericLoadingElement count={5} height="h-20" />
    }
    else if(data != undefined) {
        const isWindowBooking = data.BookingType === "windowBooking";
        const renderOutcomeForJob = data.VisitOutcome != undefined && data.VisitOutcome.Description != undefined && data.VisitOutcome.Description != '';
        const renderFieldComments = data.FieldComments != undefined && data.FieldComments != '';
        const renderAssignedOperative = data.AssignedOperative != undefined && data.AssignedOperative != '';
        const renderAppointmentTime = data.ScheduledDate != undefined && data.ScheduledDate != '' && !isWindowBooking;
        const renderLastUpdatedBy = data.LastUpdatedBy != undefined && data.LastUpdatedBy != '';

        //windows
        const renderWindowDetailCards = data.WindowScheduleDate == undefined && isWindowBooking;
        const renderWindowScheduleDate = data.WindowScheduleDate != undefined && isWindowBooking;

        //need to confirm we have JobTypeCode else offers retrieval will fail.
        const isJobRebookable = data.Status === "Cancelled" && userPermissionsFromModel?.canRebook === true && data.JobTypeCode != undefined;

        const windowStartDate = convertEpochMsToDateString(data.StartDate);
        const windowEndDate = convertEpochMsToDateString(data.EndDate);

        return (
            <div id="jbp-job-screen">
                <div className="ml-10 mr-10">
                    <Title className="mt-7 mb-5" text={`Job: ${data.BookingId}`} />
                    <div className="flex">
                        <Card render={true} identifier="addressDetails" className={`${standardPadding} mr-4 h-1/3`}>
                            <SiteAddress siteAddress={data.AddressDetails} />
                        </Card>
                        {
                            data.SiteContactDetails == undefined
                                ?
                                <span></span>
                                :
                                <Card className={`${standardPadding} mr-4 h-1/2`} render={true} identifier="siteContactDetails">
                                    <SiteContact contactDetails={data.SiteContactDetails} />
                                </Card>
                        }
                        <div className="mr-4">
                            <Card render={renderAppointmentTime} identifier="apptTime" className={`${standardPadding} mb-4`}>
                                <Title text="Appointment Time" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={data.ScheduledDate} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={renderWindowScheduleDate} identifier="apptTime" className={`${standardPadding} mb-4`}>
                                <Title text="Window Dates" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={data.WindowScheduleDate} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={renderWindowDetailCards} identifier="windowStart" className={`${standardPadding} mb-4`}>
                                <Title text="Window Start Date" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={windowStartDate} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={renderWindowDetailCards} identifier="windowEnd" className={`${standardPadding} mb-4`}>
                                <Title text="Window End Date" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={windowEndDate} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={true} identifier="jobStatus" className={`${standardPadding} mb-4`}>
                                <Title text="Current Job Status" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={data.Status} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={true} identifier="jobBooked" className={`${standardPadding} mb-4`}>
                                <Title text="Booked Job" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={data.JobTypeDesc} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card className={`${standardPadding} mb-4`} identifier="visitOutcome" render={renderOutcomeForJob}>
                                <Title text="Outcome" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={`${data?.VisitOutcome?.Description}`} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={true} identifier="jobComments" className={`${standardPadding} mb-4`}>
                                <Title text="Comments" className="text-xs font-thin text-solid-bold-green" />
                                <Typography content={data.JobComments == undefined ? "No comments found" : data.JobComments} className="mt-4 whitespace-pre-line" />
                            </Card>
                            {
                                userPermissionsFromModel?.canViewAdvancedJobDetails == true ?
                                    <div>
                                        <Card render={renderAssignedOperative} identifier="assignedOperative" className={`${standardPadding} mb-4`}>
                                            <Title text="Assigned Operative" className="text-xs font-thin text-solid-bold-green" />
                                            <Title text={`${data.AssignedOperative}`} className="mt-4 font-bold text-base" />
                                        </Card>
                                        <Card render={true} identifier="jobStatus" className={`${standardPadding} mb-4`}>
                                            <Title text="Job Status Id" className="text-xs font-thin text-solid-bold-green" />
                                            <Title text={`${data.JobStatusId}`} className="mt-4 font-bold text-base" />
                                        </Card>
                                        <Card render={renderFieldComments} identifier="fieldComments" className={`${standardPadding} mb-4`}>
                                            <Title text="Field Comments" className="text-xs font-thin text-solid-bold-green" />
                                            <Title text={`${data.FieldComments}`} className="mt-4 font-bold text-base" />
                                        </Card>
                                        <Card render={true} identifier="bookingAgent" className={`${standardPadding} mb-4`}>
                                            <Title text="Booking Agent" className="text-xs font-thin text-solid-bold-green" />
                                            <Title text={`${data.BookingAgent}`} className="mt-4 font-bold text-base" />
                                        </Card>
                                        <Card render={renderLastUpdatedBy} identifier="lastUpdatedBy" className={`${standardPadding} mb-4`}>
                                            <Title text="Last Updated By" className="text-xs font-thin text-solid-bold-green" />
                                            <Title text={`${data.LastUpdatedBy}`} className="mt-4 font-bold text-base" />
                                        </Card>
                                    </div>
                                    :
                                    <span></span>
                            }
                            {
                                data.IsCancellable === true && userPermissionsFromModel?.canCancelBooking === true ?
                                    <CancelJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine}></CancelJob>
                                    :
                                    <span></span>
                            }
                            {
                                data.IsEditable === true && userPermissionsFromModel?.canEditBooking === true ?
                                    <EditJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine} booking={data}></EditJob>
                                    :
                                    <span></span>
                            }
                            {
                                isJobRebookable ?
                                    <RebookJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine} booking={data}></RebookJob>
                                    :
                                    <span></span>
                            }
                        </div>

                    </div>
                </div>
            </div >
        )
    }
    else {
        navigate('/404')
        return <span></span>
    }
}
