import { Title } from "./Title"

export interface props {
    title: string;
    className?: string,
    children: JSX.Element[];
}

export const Modal = (props: props) => {
    return (
        <div className="">
            <div className="fixed top-0 left-0 bottom-0 w-full h-screen bg-solid-deep-blue z-0 opacity-70" ></div>
            <div className={`${props.className} fixed top-1/3 left-1/3 z-10 p-10 bg-elevation-1 pl-12 pr-12`}>
                <Title text={props.title} className="mb-3 font-bold" />
                {props.children}
            </div>
        </div>
    )
}


export const FormModal = (props: props) => {
    return (
        <div className="">
            <div className="fixed top-0 left-0 bottom-0 w-full h-screen bg-solid-deep-blue z-0 opacity-70" ></div>
            <div className={`${props.className} fixed top-0 left-1/4 z-10 p-10 bg-elevation-1 pl-12 pr-12 w-1/2 h-screen overflow-auto border-y-2 border-interactive-coral`}>
                <Title text={props.title} className="mb-3 font-bold" />
                {props.children}
            </div>
        </div>
    )
}
