import { IDropdownField, IEditableLabelField, IExternalLink, IField, IMultiButtonField, IMultiButtonsField, IRadioButtonField, IRadioButtonsField, ITag, ITagGroup, ITextField, ITypographyField } from "../treeExtensions/FieldInterfaces";
import { FieldType } from "../treeExtensions/FieldInterfaces";
import { TextInput } from "../components/TextInput";
import { Dropdown } from "../components/Dropdown";
import { Typography } from "../components/Typography";
import { ExternalLink } from "../components/ExternalLink";
import { EditableLabel } from "../components/EditableLabel";
import { TextArea } from "../components/TextArea";
import { RadioButtonGroup } from "../components/RadioButtonGroup";
import { TagGroup } from "../components/TagGroup";
import { ValidationResponse } from "../components/ValidationResponse";
import { MultiButtonGroup } from "../components/MultiButtonGroup";
import { useModel } from "../hooks/useModel";

export class FieldsFactory {
    public static makeField(field: IField, index: number, fieldIsValid: (valid: boolean) => void) {
        switch(field.fieldType) {
            case FieldType.TextInput:
                return FieldsFactory.makeTextInput(field, fieldIsValid, index);
            case FieldType.TextArea:
                return FieldsFactory.makeTextArea(field, fieldIsValid, index);
            case FieldType.EditableLabelField:
                return FieldsFactory.makeEditableLabel(field, fieldIsValid, index);
            case FieldType.DropDown:
                return FieldsFactory.makeDropdown(field, fieldIsValid, index);
            case FieldType.Typography:
                return FieldsFactory.makeTypography(field, fieldIsValid, index);
            case FieldType.ExternalLink:
                return FieldsFactory.makeExternalLink(field, fieldIsValid, index);
            case FieldType.RadioButtons:
                return FieldsFactory.makeRadioButtons(field, fieldIsValid, index);
            case FieldType.TagGroup:
                return FieldsFactory.makeTagGroup(field, fieldIsValid, index);
            case FieldType.MultiButtons:
                return FieldsFactory.makeMultiButtons(field, fieldIsValid, index);
            default:
                break;
        }
    }

    private static makeTagGroup(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const tagGroup: ITagGroup = field as ITagGroup;

        const onSelect = (selectedTags: ITag[]) => {
            const selectedTagIdentifiers = selectedTags.map(t => t.tagId);
            const dataIsValid = tagGroup.isValid(selectedTagIdentifiers);
            if(tagGroup.onChange != undefined) tagGroup.onChange(selectedTagIdentifiers)

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onSelect calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };

        return <TagGroup key={index} title={tagGroup.title} hideTitle={tagGroup.hideTitle} modelPath={tagGroup.modelPath} onSelect={onSelect} tags={tagGroup.tags} />;
    }

    private static makeRadioButtons(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const radioButtons: IRadioButtonsField = field as IRadioButtonsField;

        const onSelect = (field: IRadioButtonField) => {
            const dataIsValid = radioButtons.isValid(field.title);
            if(radioButtons.onChange != undefined) radioButtons.onChange(field.title);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };

        return <RadioButtonGroup key={index} title={radioButtons.title} hideTitle={radioButtons.hideTitle} modelPath={radioButtons.modelPath} buttons={radioButtons.buttons} mandatory={radioButtons.mandatory} onSelect={onSelect}></RadioButtonGroup>;
    }

    private static makeMultiButtons(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const multiButtons: IMultiButtonsField = field as IMultiButtonsField;

        const onSelect = (field: IMultiButtonField) => {
            const dataIsValid = multiButtons.isValid(field.title);
            if(multiButtons.onChange != undefined) multiButtons.onChange(field.modelValueWhenSelected);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };

        return <MultiButtonGroup key={index} title={multiButtons.title} hideTitle={multiButtons.hideTitle} modelPath={multiButtons.modelPath} buttons={multiButtons.buttons} mandatory={multiButtons.mandatory} onSelect={onSelect}></MultiButtonGroup>;
    }

    private static makeExternalLink(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const linkField: IExternalLink = field as IExternalLink;

        return <ExternalLink key={index} link={linkField.link} linkText={linkField.displayText} />;
    }

    private static makeTypography(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const typographyField: ITypographyField = field as ITypographyField;

        return <Typography key={index} content={typographyField.text} />;
    }

    private static makeDropdown(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const dropdownField: IDropdownField = field as IDropdownField;

        const onSelect = (index: number, value: string) => {
            const dataIsValid = dropdownField.isValid(value);
            if(dropdownField.onChange != undefined) dropdownField.onChange(value);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };

        return <Dropdown key={index} elementsDataSource={dropdownField.elementsModelPath} modelPath={field.modelPath} placeholder={dropdownField.placeHolder} initialValue={dropdownField.initialValue} title={dropdownField.title} mandatory={dropdownField.mandatory} onSelect={onSelect} />;
    }

    private static makeEditableLabel(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const editableLabel: IEditableLabelField = field as IEditableLabelField;
        const model = useModel();

        const onBlur = (value: string): ValidationResponse | undefined => {
            model.Set(editableLabel.modelPath, value);
            const dataIsValid = editableLabel.isValid(value);

            if(editableLabel.onChange != undefined) editableLabel.onChange(value);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };
        return <EditableLabel key={index} title={editableLabel.title} placeholder={editableLabel.placeHolder} modelPath={editableLabel.modelPath} initialValue={editableLabel.initialValue} onBlur={onBlur}></EditableLabel>;
    }

    private static makeTextArea(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const textField: ITextField = field as ITextField;

        const onBlur = (value: string): ValidationResponse | undefined => {
            const dataIsValid = textField.isValid(value);
            if(textField.onChange != undefined) textField.onChange(value);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };

        const constraints = textField.getDynamicFieldConstraints && textField.getDynamicFieldConstraints();
        const maxLength = constraints?.maxLength;

        return <TextArea key={index} maxLength={maxLength} title={textField.title} initialValue={textField.initialValue} placeholder={textField.placeHolder} mandatory={textField.mandatory} onBlur={onBlur} modelPath={textField.modelPath}></TextArea>;
    }

    private static makeTextInput(field: IField, fieldIsValid: (valid: boolean) => void, index: number) {
        const textField: ITextField = field as ITextField;

        const onBlur = (value: string): ValidationResponse | undefined => {
            const dataIsValid = textField.isValid(value);
            if(textField.onChange != undefined) textField.onChange(value);

            if(dataIsValid == undefined) {
                throw 'The fields factory requires all fields to return a valid ValidationResponse from onBlur calls';
            }
            else {
                fieldIsValid(dataIsValid.isValid);
            }

            return dataIsValid;
        };
        return <TextInput key={index} title={textField.title} placeholder={textField.placeHolder} mandatory={textField.mandatory} onBlur={onBlur}></TextInput>;
    }
}
