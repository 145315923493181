import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { IndicatorType } from "../components/WorkflowIndicator";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { RenderableNodeVisitor } from "../visitors/RenderableNodeVisitor";
import { RenderFlagSetter } from "../visitors/RenderFlagSetter";
import { WorkflowIndicatorVisitor } from "../visitors/WorkflowIndicatorVisitor";

export const useGetRenderableNodes = () => {
    return (root: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) => {
        const renderFlagSetter = new RenderFlagSetter();
        const renderableNodeVisitor = new RenderableNodeVisitor();
        const workflowIndicatorVisitor = new WorkflowIndicatorVisitor();
        root.Accept(renderFlagSetter);
        root.Accept(renderableNodeVisitor);
        root.Accept(workflowIndicatorVisitor);

        const suitableNodes = renderableNodeVisitor.nodesSuitableForRendering;

        suitableNodes.forEach((renderData, index) => {
            renderData.node.onInitialise();
            renderData.node.Data().SetIsLastNodeInRenderList(index == suitableNodes.length - 1);
        })

        const lastNode = suitableNodes[suitableNodes.length - 1];

        if(lastNode.node.Data().WorkflowIndicator() == IndicatorType.Default) {
            lastNode.node.Data().SetWorkflowIndicator(IndicatorType.Active);
        }

        return suitableNodes;
    }
}
