import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { ConditionalChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ConditionalChoiceNode";
import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { TerminateNode } from "../../../../../libs/ts/WorkflowTree/Nodes/TerminateNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { CompleteFactory } from "../factories/CompleteFactory";
import { CondtionalChoiceFactory } from "../factories/ConditionalChoiceFactory";
import { ExclusiveChoiceFactory } from "../factories/ExclusiveChoiceFactory";
import { IViewFactory } from "../factories/IViewFactory";
import { SequenceFactory } from "../factories/SequenceFactory";
import { TerminateFactory } from "../factories/TerminateFactory";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { InfoFactory } from "../factories/InfoFactory";

export interface RenderData {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    viewFactory: IViewFactory
}

export class RenderableNodeVisitor extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    public nodesSuitableForRendering: RenderData[] = [];

    public pushToRenderArray(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, factory: IViewFactory, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        if(node.Data().ShouldRender(pathStack) === true) {
            this.nodesSuitableForRendering.push({ node: node, viewFactory: factory });
        }
    }

    protected override VisitCompleteNode(node: CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new CompleteFactory();
        this.pushToRenderArray(node, factory, pathStack);
    }

    protected override VisitTerminateNode(node: TerminateNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new TerminateFactory();
        this.pushToRenderArray(node, factory, pathStack);
    }

    protected override VisitSequenceNode(node: SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new SequenceFactory();
        this.pushToRenderArray(node, factory, pathStack);
    }

    protected override VisitInfoNode(node: InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new InfoFactory();
        this.pushToRenderArray(node, factory, pathStack);
    }

    protected override VisitConditionalChoiceNode(node: ConditionalChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new CondtionalChoiceFactory();
        this.pushToRenderArray(node, factory, pathStack);
    }

    protected override VisitExclusiveChoiceNode(node: ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const factory = new ExclusiveChoiceFactory()
        this.pushToRenderArray(node, factory, pathStack);
    }
}
