import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { Typography } from "../components/Typography";
import { getBookingSuccessType } from "../../../APIDocs/apiTypes"
import { useStoreBookingForRebooking } from "../hooks/useStoreBooking";
import { Model } from "../Model";
import { useNavigate } from "react-router-dom"


export interface props {
    bookingId: string,
    mpan: string,
    serviceLine: string
    booking: getBookingSuccessType
}

export const RebookJob = (props: props) => {

    const [showRebookModal, setShowRebookModal] = useState(false);
    const [userType, setUserType] = useState(undefined);
    const modalTitle = "Rebook Job";
    const navigate = useNavigate();

    const storeBooking = useStoreBookingForRebooking();
    useEffect(() => {
        storeBooking.storeSite(props.booking, props.serviceLine);
        setUserType(Model.GetModel().Get("viewModel.fetchedData.UserTypeForCurrentUser"));
    }, []);



    const navigateToGetOffers = () => {
        if(userType === "INTERNAL") {
            navigate(`/bookJob/internalOffers/${props.mpan}/${props.serviceLine}`);
        }
        else if(userType === "SUPPLIER") {
            navigate(`/bookJob/offers/${props.mpan}/${props.serviceLine}`);
        }
    }

    return (
        <div>
            {
                userType != undefined ?
                    <Button text="Rebook Job" primary={true} onClick={() => { setShowRebookModal(true) }} disabled={false} className="mt-2 inline-block" />
                    :
                    <span></span>
            }
            {
                showRebookModal ?
                    <Modal title={modalTitle} >
                        <Typography content={`Confirm you wish to rebook this job. All details/comments from this job will be carried over to the new booking.`} className="mb-3" />
                        <Button primary={false} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => navigateToGetOffers()} />
                        <Button primary={false} text="Cancel" className="mr-4 mt-5" disabled={false} onClick={() => setShowRebookModal(false)} />
                    </Modal>
                    :
                    <span></span>
            }
        </div>
    )
}
