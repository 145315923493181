import { useEffect } from "react"
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode"
import { FieldsFactory } from "../factories/FieldsFactory"
import { useSynchronousState } from "../hooks/useSynchronousState"
import { JbpTreeNodeData, ValidState } from "../treeExtensions/JbpTreeNodeData"
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship"
import { fieldHasNoActionsToValidate } from "../treeExtensions/FieldInterfaces";
import { useModel } from "../hooks/useModel"


export interface props {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    isValid?: (valid: boolean) => void,
    fieldsUpdated?: () => void
}

export const Fields = (props: props) => {

    const model = useModel();

    const getInitialValue = () => {
        return props.node.Data().Fields().map((field) => {
            if(fieldHasNoActionsToValidate(field)) {
                props.node.Data().SetFieldAsValid(field, ValidState.valid);
                return true;
            }
            else if(field.modelPath != undefined) {
                const modelData = model.Get(field.modelPath);
                if(modelData == undefined) {
                    return false;
                }
                const response = field.isValid(modelData);
                return response != undefined && response.isValid == true;
            }
            else {
                return false;
            }
        })
    }

    const validFieldsArray = useSynchronousState(getInitialValue())

    props.node.Data().Fields().forEach((f) => {
        if(fieldHasNoActionsToValidate(f)) {
            props.node.Data().SetFieldAsValid(f, ValidState.valid);
        }
    });

    const allFieldsAreValid = (arrayToCheck: boolean[]) => arrayToCheck.filter((val) => val == false).length == 0

    const checkAllFieldsAreValid = (valid: boolean, index: number) => {
        const field = props.node.Data().Fields()[index];
        props.node.Data().SetFieldAsValid(field, valid ? ValidState.valid : ValidState.invalid);

        const newValidArray = Array.from(validFieldsArray.get());
        newValidArray[index] = valid;
        validFieldsArray.set(newValidArray)

        const fieldsValid = allFieldsAreValid(newValidArray)

        if(props.isValid != undefined) {
            props.isValid(fieldsValid);
        }

        if(props.fieldsUpdated != undefined) {
            props.fieldsUpdated();
        }
    }

    useEffect(() => {
        if(props.node.Data().Fields().length == 0 && props.isValid != undefined) {
            props.isValid(true);
        }
    }, [])

    return (
        <div className="jbp-workflow-fields mb-7 mr-10">
            {
                props.node.Data().Fields().map((field, index) => {
                    return FieldsFactory.makeField(field, index, (valid: boolean) => checkAllFieldsAreValid(valid, index));
                })
            }
        </div>
    )
}
