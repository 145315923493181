import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { ExclusiveChoiceView } from "../elements/nodeViews/ExclusiveChoiceView";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IViewFactory } from "./IViewFactory";

export class ExclusiveChoiceFactory extends IViewFactory {
    public override MakeView(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, mpan: string, _serviceLine: string): JSX.Element {
        return <ExclusiveChoiceView key={node.Identifier()} node={node} mpan={mpan} />;
    }
}
