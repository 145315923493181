import './index.css'

import { Header } from "./elements/Header"
import { Footer } from './elements/Footer'
import { PageContainer } from './elements/PageContainer'

//TODO: We should handle to login/authentication setup at this stage when we first hit the application before rendering anything.

function App() {
    return (
        <div>
            <Header />
            <PageContainer />
            <Footer />
        </div>
    )
}

export default App
