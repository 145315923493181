import { ChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ChoiceNode";
import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { ConditionalChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ConditionalChoiceNode";
import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { TerminateNode } from "../../../../../libs/ts/WorkflowTree/Nodes/TerminateNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { IndicatorType } from "../components/WorkflowIndicator";
import { IViewFactory } from "../factories/IViewFactory";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { Model } from "../Model";

export interface RenderData {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    viewFactory: IViewFactory
}

export class WorkflowIndicatorVisitor extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    protected override VisitCompleteNode(node: CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        this.handleNodesWithFields(node);
    }

    protected override VisitTerminateNode(node: TerminateNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        node.Data().SetWorkflowIndicator(IndicatorType.Error);
    }

    protected override VisitSequenceNode(node: SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        this.handleNodesWithFields(node);

        if(node.Data().Fields().length == 0) {
            node.Data().SetWorkflowIndicator(IndicatorType.Success);
        }
    }

    protected override VisitInfoNode(node: InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        this.handleNodesWithFields(node);

        if(node.Data().Fields().length == 0) {
            node.Data().SetWorkflowIndicator(IndicatorType.Info);
        }
    }

    protected override VisitConditionalChoiceNode(node: ConditionalChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        this.handleChoiceNode(node);
    }

    protected override VisitExclusiveChoiceNode(node: ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        this.handleChoiceNode(node);
    }

    private handleChoiceNode(node: ChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        node.Data().SetWorkflowIndicator(undefined);

        node.Children().map((child) => {
            if(child.relationship.relationshipModelPath != undefined) {
                if(Model.GetModel().Get(child.relationship.relationshipModelPath) === child.relationship.modelValueWhenRelationshipIsTrue && node.ChoiceChosen() == false) {
                    node.SetChoiceChosen(true);
                }
            }
        });

        if(node.Data().Fields().length > 0) {
            this.handleNodesWithFields(node);
        }

        if(node.Data().WorkflowIndicator() != IndicatorType.Error) {
            if(node.ChoiceChosen()) {
                node.Data().SetWorkflowIndicator(IndicatorType.Success);
            }
            else {
                node.Data().SetWorkflowIndicator(IndicatorType.Default);
            }
        }
    }

    private handleNodesWithFields(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        if(node.Data().ShouldntRenderForAnyPathStack()) {
            node.Data().SetWorkflowIndicator(undefined);
        }
        else {
            let indicator: IndicatorType | undefined = undefined;
            const nodeData = node.Data()
            const containsMandatedFields = nodeData.ContainsMandatedFields();
            const containsOptionalFields = nodeData.ContainsOptionalFields();

            if(nodeData.ErrorExistsOnAnyField() === true) {
                indicator = IndicatorType.Error;
            }
            else if(containsMandatedFields && containsOptionalFields) {
                if(nodeData.AllMandatedFieldsAreValid() === true && nodeData.AllOptionalFieldsAreValid() === true) {
                    indicator = IndicatorType.Success;
                }
            }
            else if(containsMandatedFields == false && containsOptionalFields == false) {
                // on the assumption that this is for text only nodes with no fields.
                indicator = IndicatorType.Default;
            }
            else if(containsMandatedFields && containsOptionalFields == false) {
                if(nodeData.MandatedFieldsExistThatAreUnattempted() === true) {
                    indicator = IndicatorType.Default;
                }
                else if(nodeData.AllMandatedFieldsAreValid() === true) {
                    indicator = IndicatorType.Success;
                }
            }
            else if(containsMandatedFields == false && containsOptionalFields) {
                if(nodeData.OptionalFieldsExistThatAreUnattempted() === true) {
                    indicator = IndicatorType.Default;
                }
                else if(nodeData.AllOptionalFieldsAreValid() === true) {
                    indicator = IndicatorType.Success;
                }
            }

            if(indicator === undefined) {
                indicator = IndicatorType.Default;
            }

            nodeData.SetWorkflowIndicator(indicator);
        }
    }
}
