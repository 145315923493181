import { useEffect, useState } from "react";
import { Logger } from "../Logger";
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";
import { useModel } from "../hooks/useModel";
import { ValidationResponse } from "./ValidationResponse";
import { useSynchronousState } from "../hooks/useSynchronousState";

export interface props {
    elementsDataSource: string,
    modelPath: string,
    placeholder: string,
    mandatory: boolean,
    title: string,
    initialValue?: string,
    onSelect?: (index: number, value: string) => ValidationResponse | undefined,
    elementsForDisplay?: string[],
    classProps?: string
}

export const Dropdown = (props: props) => {

    const model = useModel();

    const getInitialValues = () => {
        let elements = [];

        if(props.elementsForDisplay != undefined) {
            elements = props.elementsForDisplay;
        } else {
            elements = model.Get(props.elementsDataSource);
        }

        const defaultValue = props.initialValue != null ? props.initialValue : model.Get(props.modelPath);
        let priorIndex: number | undefined = undefined;
        let priorValue: string | undefined = undefined;
        if(defaultValue != undefined) {
            const index = elements.indexOf(defaultValue);

            if(index != -1) {
                priorValue = defaultValue;
                priorIndex = index;
            }
        }

        return {
            elements: elements,
            previouslySelectedElement: priorValue == undefined ? props.placeholder : priorValue,
            previouslySelectedIndex: priorIndex
        }
    }

    const { elements, previouslySelectedElement, previouslySelectedIndex } = getInitialValues();

    const [inputIsFocused, setInputIsFocused] = useState(false);
    const [selectedElement, setSelectedElement] = useState<string | undefined>(previouslySelectedElement);
    const selectedElementIndex = useSynchronousState<number | undefined>(previouslySelectedIndex);
    const [errorMessage, setErrorMessage] = useState("");

    // There is a gotcha here, if the value you need to set on the model is being set in the onSelect, this validate function will replace what is on the model for that path(if you are using the same model value)
    // For example when selecting the site type, the site type code is set on the model in the onSelect function, so we don't want to set the site type description on the model here(it was overwriting the site type code value that was being manually set)
    const validateEntry = () => {
        const selectedIndex = selectedElementIndex.get();
        if(props.onSelect != undefined && selectedIndex != undefined) {
            const validationResponse = props.onSelect(selectedIndex, elements[selectedIndex]);
            if(validationResponse) {
                if(validationResponse.isValid) {
                    setErrorMessage("");
                    model.Set(props.modelPath, elements[selectedIndex])
                }
                else {
                    if(validationResponse.errorMessage != undefined) {
                        setErrorMessage(validationResponse.errorMessage);
                    }
                    else {
                        setErrorMessage("An unknown error occurred")
                        Logger.Log(`Validation response object implied that an error was present, yet the error message was not defined: ${validationResponse}`)
                    }
                }
            }
        }
    }

    useEffect(() => {
        validateEntry();
    }, [selectedElementIndex.get()])

    const onElementSelect = (index: number) => {
        if(index <= elements.length) {
            setSelectedElement(elements[index]);
            selectedElementIndex.set(index);
        }
        setInputIsFocused(false);
    }

    const onInputClick = () => {
        setInputIsFocused(!inputIsFocused);
    }

    const errorExists = () => errorMessage != undefined && errorMessage != ""
    const errorControl = () => errorExists() ? <label className={`text-feedback-red text-xs jbp-text-field-validation-message-${ConvertStringToHtmlId(props.title)}`}>{errorMessage}</label> : <br />

    const labelTextColour = "text-solid-bold-green";
    const elementsClass = inputIsFocused ? "block max-h-full overflow-y-scroll" : "hidden";
    const inputFieldTextColour = selectedElement == props.placeholder ? "text-secondary-deep-blue-30" : ""

    const elementControl = () => {
        return (
            <div id={`jbp-dropdown-elements-${ConvertStringToHtmlId(props.title)}-${elements.length}`} className={`${elementsClass} z-10 absolute bg-interactive-bold-green-db-12 min-w-[30em] w-fit border-solid-bold-green border-[1px]`}>
                {elements.map((element: string, index: number) => {
                    return <div key={index} className="bg-interactive-bold-green-db-12 hover:bg-interactive-light-teal-100" onClick={() => { onElementSelect(index) }}>
                        <div className="pl-3 pt-3 pb-3"><span className="jbp-dropdown-option">{element}</span></div>
                    </div>
                })}
            </div>
        )
    }

    return (
        <div className={`mb-2 min-w-[30em] w-fit  ${props.classProps}`}>
            <div className="bg-interactive-bold-green-db-12 border-b-solid-bold-green border-b-[1px] min-w-[30em] w-fit cursor-pointer" onClick={onInputClick}>
                <label className={`jbp-dropdown ml-3 text-xs cursor-pointer ${labelTextColour}`}><span>{props.mandatory == true ? '*' : ''}</span>{props.title}</label>
                <input type="text" readOnly id={`jbp-drop-down-selected-element-${ConvertStringToHtmlId(props.title)}`} className={`${inputFieldTextColour} cursor-pointer w-full pl-3 pr-5 pb-1 bg-interactive-bold-green-db-12 focus:outline-none bg-origin-content bg-no-repeat bg-[length:12px_7px] bg-[right_bottom_20px]`} style={{ backgroundImage: "url(/dropdownArrow.png)" }} value={selectedElement} data-placeholder={props.placeholder} />
                {elementControl()}
            </div>
            <div>
                {errorControl()}
            </div>
        </div>
    )
}
