import { GenericLoadingElement } from "../../components/GenericLoadingElement"

export interface props {
    className?: string
    showTitle?: boolean
}

export const JobHistoryLoading = (props: props) => {

    return (
        <div className={`jbp-job-history-loading pr-5 ${props.className}`} >
            {props.showTitle === true ? <h1 className={`mt-7 text-xl mb-8`}>Job History:</h1> : <span></span>}
            <GenericLoadingElement count={14} height="h-20"></GenericLoadingElement>
        </div>
    )
}
