import { TreeNodePathStack } from "../TreeNodePathStack";
import { IVisitor } from "../Visitor/IVisitor";

export interface IChildNodeStructure<T, C> {
    node: ITreeNode<T, C>,
    relationship: C
}

export class ITreeNode<T, C> {
    protected children: IChildNodeStructure<T, C>[] = [];
    protected nodeIdentifier: string;
    protected data: T;
    protected nodeSatisfied = false;
    public TreeChanged: (() => void) | undefined;
    protected isRoot = false;

    constructor(identifier: string, data: T) {
        this.nodeIdentifier = identifier;
        this.data = data;
    }

    public IsRoot(): boolean {
        return this.isRoot;
    }

    public SetIsRoot(value: boolean) {
        this.isRoot = value;
    }

    public AddChild(treeNode: ITreeNode<T, C>, relationship: C) {
        this.children.push({ node: treeNode, relationship: relationship });
    }

    public Children() {
        return this.children;
    }

    public Identifier() {
        return this.nodeIdentifier;
    }

    public Data(): T {
        return this.data;
    }

    public IsSatisfied() {
        return this.nodeSatisfied;
    }

    public SetIsSatisfied(satisfied: boolean) {
        this.nodeSatisfied = satisfied;
        if(this.TreeChanged != undefined) {
            this.TreeChanged();
        }
    }

    public SetTreeChanged(treeChanged: () => void) {
        this.TreeChanged = treeChanged;
    }

    public getRelativeRelationship(toNode: ITreeNode<T, C>): C | undefined {
        let relationship: C | undefined = undefined;
        this.children.forEach((childStructure: IChildNodeStructure<T, C>) => {
            if(childStructure.node == toNode) {
                relationship = childStructure.relationship;
            }
        })
        return relationship;
    }

    public Accept(visitor: IVisitor<T, C>, pathStack = new TreeNodePathStack(), parent?: ITreeNode<T, C>) {
        pathStack.Push(this);
        visitor.Visit(this, pathStack, parent);
        this.children.forEach(child => {
            child.node.Accept(visitor, pathStack, this)
        });
        pathStack.Pop();
    }

    public onInitialise() {
        //Will be overridden
    }
}
