import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { IWorkflowFactory } from "../factories/IWorkflowFactory";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, ITextField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class JobCommentsForWarrantNode extends CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    public static GetPrependedData() {
        const prependedFieldPaths = [
            "model.jobCommentPrependedMessages.warrantOfficerName",
            "model.jobCommentPrependedMessages.warrantOfficerContactNumber",
            "model.jobCommentPrependedMessages.whatThreeWordsReference"
        ]

        const prependedData = prependedFieldPaths.map((path) => {
            const value = Model.GetModel().Get(path);
            return value == undefined ? "" : value
        }).join('');

        return prependedData;
    }

    public static GetJobComments() {
        const prependedData = JobCommentsForWarrantNode.GetPrependedData();
        const comments = Model.GetModel().Get("model.results.jobComments");
        return prependedData + comments;
    }

    constructor(mpan: string, serviceLine: string) {

        const determineOffersPath = (): string => {
            const userType = Model.GetModel().Get("viewModel.fetchedData.UserTypeForCurrentUser");
            if(userType === "INTERNAL") {
                return `/bookJob/internalOffers/${mpan}/${serviceLine}`;
            }
            return `/bookJob/offers/${mpan}/${serviceLine}`;
        }

        const relationship = {
            workflowButtonText: "Get Job Dates",
            relationshipModelPath: IWorkflowFactory.sequenceRelationship.relationshipModelPath,
            modelValueWhenRelationshipIsTrue: IWorkflowFactory.sequenceRelationship.modelValueWhenRelationshipIsTrue,
            determinePathOnSelect: determineOffersPath
        } as IJbpChildNodeRelationship

        const commentsField = {
            fieldType: FieldType.TextArea,
            title: "Additional Comments",
            placeHolder: "Advise of any parking or access instructions/ restrictions, site directions, meter location information, health & safety concerns (e.g. face mask), and any other access information",
            modelPath: "model.results.jobComments",
            isValid: (_value: string) => {
                return {
                    isValid: true
                }
            },
            getDynamicFieldConstraints: () => {
                const jobCommentsStandardLength = 1900;
                //yes, this is a magic number. but given there isn't a way to have the node trigger a reconstruction once details have been entered for the warrant
                //details this needs to be hardcoded. 114 is the total length of "Warrant Office Name: <some 50chars>" and "Warrant Office Contact Number: 11digitshere"
                //should only allow 1886
                return {
                    maxLength: jobCommentsStandardLength - 114
                }
            }
        } as ITextField;

        super("jobComments", new JbpTreeNodeData("Please enter any further information or instructions relating to this job", [commentsField]), relationship);
    }
}
