import { getBookingSuccessType, } from "../../../APIDocs/apiTypes";
import { Model } from "../Model";
import { useModel } from "./useModel";

const setSiteContactDetails = (data: getBookingSuccessType, model: Model) => {
    if(data.SiteContactDetails?.Title != undefined) {
        model.Set('updateJobModel.siteContactDetails.title', data.SiteContactDetails?.Title);
    }
    if(data.SiteContactDetails?.ContactFirstName != undefined) {
        model.Set('updateJobModel.siteContactDetails.firstname', data.SiteContactDetails?.ContactFirstName);
    }
    if(data.SiteContactDetails?.ContactSurname != undefined) {
        model.Set('updateJobModel.siteContactDetails.surname', data.SiteContactDetails?.ContactSurname);
    }
    if(data.SiteContactDetails?.EmailAddress != undefined) {
        model.Set('updateJobModel.siteContactDetails.email', data.SiteContactDetails?.EmailAddress);
    }
    if(data.SiteContactDetails?.ContactNumber != undefined) {
        model.Set('updateJobModel.siteContactDetails.number', data.SiteContactDetails?.ContactNumber);
    }
}

const setSiteAddressDetails = (data: getBookingSuccessType, model: Model) => {
    if(data.AddressDetails.AddressName != undefined) {
        model.Set("updateJobModel.siteAddress.siteName", data.AddressDetails.AddressName);
    }
    if(data.AddressDetails.AddressLine1 != undefined) {
        model.Set("updateJobModel.siteAddress.line1", data.AddressDetails.AddressLine1);
    }
    if(data.AddressDetails.AddressLine2 != undefined) {
        model.Set("updateJobModel.siteAddress.line2", data.AddressDetails.AddressLine2);
    }
    if(data.AddressDetails.AddressLine3 != undefined) {
        model.Set("updateJobModel.siteAddress.line3", data.AddressDetails.AddressLine3);
    }
    if(data.AddressDetails.Town != undefined) {
        model.Set("updateJobModel.siteAddress.town", data.AddressDetails.Town);
    }
    if(data.AddressDetails.County != undefined) {
        model.Set("updateJobModel.siteAddress.county", data.AddressDetails.County);
    }
    if(data.AddressDetails.PostCode != undefined) {
        model.Set("updateJobModel.siteAddress.postCode", data.AddressDetails.PostCode);
    }
}



const setRebookingResultSiteContactDetails = (data: getBookingSuccessType, model: Model) => {
    if(data.SiteContactDetails?.Title != undefined) {
        model.Set('model.results.siteContactDetails.title', data.SiteContactDetails?.Title);
    }
    if(data.SiteContactDetails?.ContactFirstName != undefined) {
        model.Set('model.results.siteContactDetails.firstname', data.SiteContactDetails?.ContactFirstName);
    }
    if(data.SiteContactDetails?.ContactSurname != undefined) {
        model.Set('model.results.siteContactDetails.surname', data.SiteContactDetails?.ContactSurname);
    }
    if(data.SiteContactDetails?.EmailAddress != undefined) {
        model.Set('model.results.siteContactDetails.email', data.SiteContactDetails?.EmailAddress);
    }
    if(data.SiteContactDetails?.ContactNumber != undefined) {
        model.Set('model.results.siteContactDetails.number', data.SiteContactDetails?.ContactNumber);
    }
}
const setRebookingResultSiteAddressDetails = (data: getBookingSuccessType, model: Model) => {
    if(data.AddressDetails.AddressName != undefined) {
        model.Set("model.results.siteAddressDetails.siteName", data.AddressDetails.AddressName);
    }
    if(data.AddressDetails.AddressLine1 != undefined) {
        model.Set("model.results.siteAddressDetails.line1", data.AddressDetails.AddressLine1);
    }
    if(data.AddressDetails.AddressLine2 != undefined) {
        model.Set("model.results.siteAddressDetails.line2", data.AddressDetails.AddressLine2);
    }
    if(data.AddressDetails.AddressLine3 != undefined) {
        model.Set("model.results.siteAddressDetails.line3", data.AddressDetails.AddressLine3);
    }
    if(data.AddressDetails.Town != undefined) {
        model.Set("model.results.siteAddressDetails.town", data.AddressDetails.Town);
    }
    if(data.AddressDetails.County != undefined) {
        model.Set("model.results.siteAddressDetails.county", data.AddressDetails.County);
    }
    if(data.AddressDetails.PostCode != undefined) {
        model.Set("model.results.siteAddressDetails.postCode", data.AddressDetails.PostCode);
    }
}
const setAdditionalRebookingElements = (data: getBookingSuccessType, model: Model, serviceLine: string) => {

    //jobComments
    if(data.JobComments != undefined) {
        model.Set("model.results.jobComments", data.JobComments);
    }

    //jobType
    if(data.JobTypeCode != undefined) {
        model.Set("model.results.jobType", data.JobTypeCode);
    }

    //siteType
    if(["DNO", "BNO"].includes(serviceLine)) {
        model.Set("model.results.updatedSiteType", serviceLine);
    }
    else if(data.SiteDetails?.SiteType != undefined) {
        model.Set("model.results.updatedSiteType", data.SiteDetails.SiteType);
    }
}

export const useStoreBookingForRebooking = () => {
    const model = useModel();

    return {
        storeSite: (data: getBookingSuccessType, serviceLine: string) => {
            setRebookingResultSiteContactDetails(data, model);
            setRebookingResultSiteAddressDetails(data, model);
            setAdditionalRebookingElements(data, model, serviceLine);
        }
    }
}

export const useStoreBooking = () => {
    const model = useModel();

    return {
        storeSite: (data: getBookingSuccessType) => {

            setSiteAddressDetails(data, model);
            setSiteContactDetails(data, model);
        }
    }
}
