import { useNavigate, useParams } from "react-router-dom"

export const useDefinedParams = (expectedParams: string[]): string[] => {
    const navigate = useNavigate();
    const params = useParams();
    const paramsKeys = Object.keys(params);
    if(paramsKeys.length != expectedParams.length) {
        navigate('/404');
        return [];
    }
    else {
        const definedParams: string[] = [];
        paramsKeys.forEach((key) => {
            if(expectedParams.indexOf(key) == -1) {
                navigate('/404');
            }
            else {
                definedParams.push(params[key] as string)
            }
        })
        return definedParams;
    }
}
