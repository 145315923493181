import { useIsEnv } from "../../src/hooks/useIsEnv";


export interface props {
    content: string,
    className?: string
}

export const Typography = (props: props) => {
    const isDev = useIsEnv().isDev;

    return (
        <div className={`${props.className}`}>
            <span title={isDev ? "DevInfo: Text" : ""} className="jbp-typography">{props.content}</span>
        </div>
    )
}
