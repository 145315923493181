import { CompleteNode } from "../Nodes/CompleteNode";
import { ConditionalChoiceNode } from "../Nodes/ConditionalChoiceNode";
import { ExclusiveChoiceNode } from "../Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../Nodes/ITreeNode";
import { SequenceNode } from "../Nodes/SequenceNode";
import { TerminateNode } from "../Nodes/TerminateNode";
import { TreeNodePathStack } from "../TreeNodePathStack";
import { InfoNode } from "../Nodes/InfoNode";

export class IVisitor<T, C> {
    protected VisitCompleteNode(_node:CompleteNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitCompleteNode function in concrete Visitor'
    }
    protected VisitConditionalChoiceNode(_node:ConditionalChoiceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitConditionalChoiceNode function in concrete Visitor'
    }
    protected VisitExclusiveChoiceNode(_node:ExclusiveChoiceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitExclusiveChoice function in concrete Visitor'
    }
    protected VisitSequenceNode(_node:SequenceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitSequenceNode function in concrete Visitor'
    }
    protected VisitInfoNode(_node:SequenceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitInfoNode function in concrete Visitor'
    }
    protected VisitTerminateNode(_node:TerminateNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>) {
        throw 'Unimplemented VisitTerminateNode function in concrete Visitor'
    }

    public Visit(node:ITreeNode<T, C>, pathStack: TreeNodePathStack<T, C>, parent?:ITreeNode<T, C>) {
        if(node instanceof CompleteNode) {
            this.VisitCompleteNode(node as CompleteNode<T, C>, pathStack, parent)
        }
        else if(node instanceof ConditionalChoiceNode) {
            this.VisitConditionalChoiceNode(node as ConditionalChoiceNode<T, C>, pathStack, parent)
        }
        else if(node instanceof ExclusiveChoiceNode) {
            this.VisitExclusiveChoiceNode(node as ExclusiveChoiceNode<T, C>, pathStack, parent)
        }
        else if(node instanceof SequenceNode) {
            this.VisitSequenceNode(node as SequenceNode<T, C>, pathStack, parent)
        }
        else if(node instanceof InfoNode) {
            this.VisitInfoNode(node as InfoNode<T, C>, pathStack, parent)
        }
        else if(node instanceof TerminateNode) {
            this.VisitTerminateNode(node as TerminateNode<T, C>, pathStack, parent)
        }
        else {
            throw 'Unknown node type in Visitor'
        }
    }
}
