import { CommonRegularExpressions } from "../../../../../libs/ts/CommonRegularExpressions";
import { ConditionalChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ConditionalChoiceNode";
import { Model } from "../Model";
import { useChildNodeUndoBehaviour } from "../hooks/useChildNodeUndoBehaviour";
import { BookJobCommunicationsHandler } from "../relationshipHandlers/BookJobCommunicationsHandler";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IMultiButtonsField, IMultiButtonField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class ConfirmationSMSRequiredNode extends ConditionalChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    private readonly noButton = {
        button: {
            title: "No",
            modelValueWhenSelected: false
        } as IMultiButtonField,
        enabled: true
    };
    private readonly yesButton = {
        button: {
            title: "Yes",
            modelValueWhenSelected: true
        } as IMultiButtonField,
        enabled: true
    };

    private readonly buttons = [
        this.noButton, this.yesButton
    ]

    public static onYesSelected = () => {
        const prepopulatedValue = Model.GetModel().Get("model.results.siteContactDetails.number");
        if(prepopulatedValue != undefined && CommonRegularExpressions.PhoneNumberRegex.test(prepopulatedValue) == true) {
            Model.GetModel().Set("model.results.confirmationSMSNumber", prepopulatedValue);
        }
    }

    public static onNoSelected = () => {
        Model.GetModel().ClearModelValue("model.results.confirmationSMSNumber");
    }

    public static selectionUpdated = (modelValueToSet: any) => {
        if(modelValueToSet === true) {
            ConfirmationSMSRequiredNode.onYesSelected();
        } else if(modelValueToSet === false) {
            if(BookJobCommunicationsHandler.shouldOnlyShowSmsInput() == false) {
                ConfirmationSMSRequiredNode.onNoSelected();
            }
        }
    }

    private readonly field: IMultiButtonsField

    constructor() {
        const childNodeUndoBehaviour = useChildNodeUndoBehaviour();

        const field = {
            fieldType: FieldType.MultiButtons,
            modelPath: "model.results.confirmationSMSRequired",
            title: "Do you require confirmation via SMS of the booking of this job?",
            hideTitle: true,
            mandatory: true,
            isValid: (title: string) => {
                const isValidRes = title == undefined ? false : true

                return { isValid: isValidRes };
            },
            onChange: (modelValueToSet: any) => {
                ConfirmationSMSRequiredNode.selectionUpdated(modelValueToSet)
                //Since we're using a void relationship to get to this node we never end up setting choiceChosen via the usual method.
                //Needs to be invoked manually before tree shaking to ensure the correct indicator is set when selecting a value.
                this.SetChoiceChosen(true);
                childNodeUndoBehaviour.childNodeSelected(this, this);
                if(this.TreeChanged != undefined) {
                    this.TreeChanged();
                }
            },
            buttons: []
        } as IMultiButtonsField

        super("confirmationSmsRequired", new JbpTreeNodeData("Do you require confirmation via SMS of the booking of this job?", [field]));

        this.field = field;
    }

    public override onInitialise(): void {
        this.field.buttons = this.buttons.filter(b => b.enabled).map(b => b.button);
    }
}
