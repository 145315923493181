import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class SatisfiedFalseNodeFlagSetter extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    public override Visit(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>): void {
        if(node.Data().ShouldntRenderForAnyPathStack()) {
            node.SetIsSatisfied(false);
        }
    }
}
