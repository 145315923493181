import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, ITypographyField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class MeterAccessibleNode extends ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{
    constructor() {
        super("meterAccessible", new JbpTreeNodeData("Is all metering accessible and unrestricted?", [
            {
                fieldType: FieldType.Typography,
                text: "The metering equipment will need to be easily accessible, free from obstructions, and positioned no higher than 1.8m from the ground.",
            } as ITypographyField
        ]))
    }
}
