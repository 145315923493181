import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOffersSuccessType, getOfferServicePowerErrorResponse, getSiteErrorType, getSiteSuccessType, Offer } from "../../../APIDocs/apiTypes"
import { Button } from "../components/Button"
import { GenericLoadingElement } from "../components/GenericLoadingElement"
import { List } from "../components/List"
import { feedbackColour, ListItem } from "../components/ListItem"
import { Title } from "../components/Title"
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery"
import { useDefinedParams } from "../hooks/useDefinedParams"
import { useModel } from "../hooks/useModel"
import { useStoreSite } from "../hooks/useStoreSite"
import { Logger } from "../Logger"
import { useSynchronousState } from "../hooks/useSynchronousState"
import { ApiError } from "./ApiError"
import { DatePicker } from "../components/DatePicker"
import { DateTime } from "luxon"
import { Model } from "../Model"
import { Modal } from "../components/Modal"
import { Typography } from "../components/Typography"
import { ExternalLink } from "../components/ExternalLink"


export const BookJobOffers = () => {

    const showMoreIncrement = 7;
    const [showMoreCount, setShowMoreCount] = useState(showMoreIncrement);
    const getOffersError = useSynchronousState("");
    const navigate = useNavigate();
    const model = useModel();

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const storeSiteData = useStoreSite();


    //Modal controls
    const [showConfirmWarrantOfferSelection, setShowConfirmWarrantOfferSelection] = useState(false);
    const [showDateErrorModal, setShowDateErrorModal] = useState(false);

    const postCode = model.Get("model.results.siteAddressDetails.postCode") != null ? model.Get("model.results.siteAddressDetails.postCode") : model.Get("viewModel.fetchedData.siteAddress.postCode");

    const defaultAllDayTimeBandStart = "0800";
    const defaultAllDayTimeBandEnd = "1700";

    const preferredDate = DateTime.now().toFormat("yyyy-MM-dd");

    const [queryData, setQueryDataForGetOffers] = useState({
        mpxn: mpan,
        jobType: model.Get("model.results.jobType"),
        fuel: "E",
        siteMpid: model.Get("viewModel.fetchedData.MPID"),
        postcode: postCode,
        startDate: model.Get("viewModel.fetchedData.appointment.startDate"),
        endDate: model.Get("viewModel.fetchedData.appointment.endDate"),
        timeBandStart: defaultAllDayTimeBandStart,
        timeBandEnd: defaultAllDayTimeBandEnd,
        force: false,
        preferredDate: preferredDate
    });

    const onSiteData = (data: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, data);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Error(`Data found to not exist -> ${err.msg}`)
    }

    const determineFriendlyErrorMessageForGetOffers = (errorCode: string | undefined) => {
        switch(errorCode) {
            case "8":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "75":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "156":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "179":
                return "The Postcode of the Site the Appointment is not set up in the Siemens Scheduling Solution"
            default:
                return "There was an error with this request, please try again"
        }
    }

    const onGetOffersError = (err: getOfferServicePowerErrorResponse) => {
        console.log("Error returned from service power:" + err)
        const errorCodeFromSP = determineFriendlyErrorMessageForGetOffers(err?.errorCode);

        getOffersError.set(errorCodeFromSP);
    }

    const { isLoading, data } = useJbpQuery<getOffersSuccessType, getOfferServicePowerErrorResponse>(RestTypes.Post, queryData, [queryData.startDate], `jbp/offers/request`, true, serviceLine, undefined, onGetOffersError);

    useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site-bookJobOffers'], `jbp/${mpan}/site/`, true, serviceLine, onSiteData, onSiteDataError);

    if(storeSiteData.dataStored == false) {
        return <span></span>
    }

    const onShowMoreClick = () => {
        setShowMoreCount(showMoreCount + showMoreIncrement);
    }

    const listItemClicked = (offer: Offer) => {
        model.Set("model.results.offerSelected.scheduledDate", offer.ScheduledDate);
        model.Set("model.results.offerSelected.offerToken", offer.OfferToken);
        model.Set("model.results.offerSelected.spJobCode", offer.SpJobCode);
        model.Set("model.results.offerSelected.dayOfWeek", offer.DayOfWeek);
        model.Set("model.results.offerSelected.apptBand", offer.ApptBand);
        model.Set("model.results.offerSelected.apptDuration", offer.ApptDuration);
        model.Set("model.results.offerSelected.startTime", offer.StartTime);
        model.Set("model.results.offerSelected.endTime", offer.EndTime);
        model.Set("model.results.offerTokenSelected", true);

        if(isWarrantJob()) {
            setShowConfirmWarrantOfferSelection(true);
        } else {
            navigate(`/bookJob/communications/${mpan}/${serviceLine}`)
        }

    }

    const handleBookJobSelection = () => {
        navigate(`/bookingResult/${mpan}/${serviceLine}`)
    }

    const refetchOffersWithNewDate = (date: DateTime | undefined) => {
        const startDateOfMOPAppointment = DateTime.fromMillis(parseInt(Model.GetModel().Get("viewModel.fetchedData.appointment.startDate"))).startOf("day");
        const currentDate = DateTime.now().startOf("day");
        const newSelectedDate = date?.startOf("day");

        if(newSelectedDate) {
            const isSelectedDateAheadOfAppointmentStartDate = newSelectedDate >= startDateOfMOPAppointment;
            const isSelectedDateInFuture = newSelectedDate >= currentDate;
            if(isSelectedDateAheadOfAppointmentStartDate && isSelectedDateInFuture) {
                setQueryDataForGetOffers((prevState) => ({ ...prevState, startDate: `${newSelectedDate.toMillis()}` }))
            } else {
                setShowDateErrorModal(true);
            }
        }
    }

    const getDateTimeInPlainText = () => {
        return model.Get("model.results.offerSelected.scheduledDate");
    }

    const offersToDisplay = data != undefined ? data.filter((offer, index) => index < showMoreCount) : []


    const isWarrantJob = () => {
        const selectedJobType: string = Model.GetModel().Get('viewModel.selectedJobKind');

        if(selectedJobType?.toLowerCase().indexOf("warrant") > -1) {
            return true;
        }
        return false;
    }



    const title = "Your appointment offers"
    const isButtonDisabled = () => data != undefined && data.length <= showMoreCount

    const showWarrantInfo = () => {
        return isWarrantJob() && getOffersError.get() === "";
    }

    return (
        <div className="ml-10 pb-5">
            <Title text={title} className="mt-7 mb-4"></Title>

            {
                showWarrantInfo() === true ?
                    <div>
                        <Typography content={`Please select a date for the Warrant Job. We have initially provided a list of offers. If you require a different date, please use the date picker.`} className="mb-3" />
                        <Typography className="inline-block mr-1" content={`If your preferred date is not available, please contact siemens to arrange the appointment using the following link:`} />
                        <ExternalLink className="inline-block" link="https://queryportal.siemensmetering.co.uk" linkText="Siemens Query Portal" />

                        <DatePicker title="Select Date For Warrant Job" mandatory={false} onDateSelected={refetchOffersWithNewDate}></DatePicker>
                    </div>
                    :
                    <span></span>
            }
            {
                isLoading ?
                    <GenericLoadingElement count={showMoreIncrement} height="h-16" className="jbp-offers-loading w-4/6"></GenericLoadingElement>
                    :
                    offersToDisplay != undefined && offersToDisplay.length > 0 ?
                        <div>
                            <List title={title} data={offersToDisplay}>
                                {offersToDisplay.map((offer, index) => {
                                    return <ListItem title="Appointment" className="mr-10 w-4/6" cells={[`${offer.ScheduledDate}`]} feedbackColour={feedbackColour.green} key={index} tagText="Available" onClick={() => listItemClicked(offer)} />
                                })}
                            </List>
                            <Button text="Show more" primary={true} disabled={isButtonDisabled()} onClick={onShowMoreClick} className="mt-2" />
                        </div>
                        :
                        <ApiError errorDescription={getOffersError.get()}></ApiError>
            }
            {
                showDateErrorModal ?
                    <Modal title="Error" >
                        <Typography content={`The date you have selected is in the past and/or is not within your assigned appointment range.`} className="mb-3" />
                        <Typography content={`Please select another date or contact Siemens to arrange the appointment.`} className="mb-3" />
                        <Button primary={true} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => setShowDateErrorModal(false)} />
                    </Modal>
                    :
                    <span></span>
            }
            {
                showConfirmWarrantOfferSelection ?
                    <Modal title="Continue with Job Booking?" >
                        <Typography content={`You have selected: ${getDateTimeInPlainText()}.`} className="mb-3" />
                        <Typography content={`If this is correct click Confirm to complete the booking.`} className="mb-3" />
                        <Button primary={false} text="Cancel" className="mr-4 mt-5" disabled={false} onClick={() => setShowConfirmWarrantOfferSelection(false)} />
                        <Button primary={true} text="Confirm" className="mr-4 mt-5" disabled={false} onClick={handleBookJobSelection} />
                    </Modal>
                    :
                    <span></span>
            }
        </div>
    )
}
