import { useState } from "react"
import { Label } from "./Label";
import { TextInput } from "./TextInput";
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"
import { useModel } from "../hooks/useModel";
import { ValidationResponse } from "./ValidationResponse";

export interface props {
    title: string,
    initialValue?: string,
    modelPath?: string
    placeholder?: string,
    onBlur?: (value: string) => ValidationResponse | undefined
}

export const EditableLabel = (props: props) => {

    const getInitialValue = () => {
        const defaultValue = props.initialValue != undefined ? props.initialValue : props.placeholder
        if(props.modelPath != undefined && props.initialValue == undefined) {
            const valueFromModel = model.Get(props.modelPath) as string;
            if(valueFromModel != undefined) {
                return valueFromModel;
            }
            else {
                return defaultValue;
            }
        }
        return defaultValue
    }

    const model = useModel();
    const [isEditable, setIsEditable] = useState(false);
    const [content, setContent] = useState(getInitialValue());

    const validateValue = (value: string): ValidationResponse | undefined => {
        let validationResponse = undefined;
        if(props.onBlur != undefined) {
            validationResponse = props.onBlur(value);
        }
        if(validationResponse != undefined && validationResponse.isValid === true) {
            setContent(value);
            setIsEditable(false);
            if(props.modelPath != undefined) {
                model.Set(props.modelPath, value)
            }
        }
        return validationResponse;
    }

    const onClick = () => {
        if(isEditable == false) {
            setIsEditable(true)
        }
    }

    const controlId = `jbp-editable-label-${isEditable ? "editable" : "viewable"}-${ConvertStringToHtmlId(props.title)}`;

    const placeholder = props.placeholder != undefined ? props.placeholder : "";

    return (
        <div id={controlId} className={`${isEditable ? "" : "bg-interactive-bold-green-db-12 hover:bg-secondary-deep-blue-60 pl-3 pr-3 pb-1 pt-1 min-w-[30em] mb-4"} cursor-pointer w-fit`} onClick={onClick}>
            {isEditable ?
                <TextInput title={props.title} initialValue={content} placeholder={placeholder} onBlur={validateValue} />
                :
                <Label title={props.title} text={content != undefined ? content : ""} displayEditableIcon={true} placeholderContent={placeholder} />
            }
        </div>
    )
}
