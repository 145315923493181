import React from "react"
import { ChangeEvent, useEffect, useState } from "react"
import { Logger } from "../Logger"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";
import { ValidationResponse } from "./ValidationResponse";

export interface props {
    title: string
    placeholder?: string
    onBlur?: (value: string) => ValidationResponse | undefined
    mandatory?: boolean
    className?: string
    initialValue?: string,
    onClick?: () => void
}

enum validStates {
    uninitialised,
    invalid,
    valid
}

// This component does not handle model data as it is also composed within Editable label as well.

export const TextInput = (props: props) => {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [valid, setValid] = useState(props.mandatory == true ? validStates.uninitialised : validStates.valid);
    const [errorMessage, setErrorMessage] = useState("");
    const [textValue, setTextValue] = useState<string | undefined>(props.initialValue == props.placeholder ? "" : props.initialValue);
    const [onBlurCounter, setOnBlurCounter] = useState(0);
    const inputRef = React.createRef<HTMLInputElement>();

    useEffect(() => {
        if(props.onBlur && textValue != undefined && hasLoaded) {
            const validationResponse = props.onBlur(textValue)
            if(validationResponse) {
                if(validationResponse.isValid) {
                    setValid(validStates.valid)
                    setErrorMessage("");
                }
                else {
                    setValid(validStates.invalid)
                    if(validationResponse.errorMessage != undefined) {
                        setErrorMessage(validationResponse.errorMessage);
                    }
                    else {
                        setErrorMessage("An unknown error occurred")
                        Logger.Log(`Validation response object implied that an error was present, yet the error message was not defined: ${validationResponse}`)
                    }
                }
            }
        }
        else {
            setFocus();
            setHasLoaded(true);
        }
    }, [onBlurCounter])

    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
        setOnBlurCounter(onBlurCounter + 1)
    }

    const onClick = () => {
        setFocus();
        if(props.onClick != undefined) {
            props.onClick();
        }
    }

    const setFocus = () => {
        inputRef?.current?.focus();
    }

    const fieldIsInvalid = () => valid == validStates.invalid;

    const backgroundColour = fieldIsInvalid() ? "bg-feedback-dark-red" : "bg-interactive-bold-green-db-12";
    const borderColour = fieldIsInvalid() ? "border-b-feedback-red" : "border-b-solid-bold-green";
    const labelTextColour = fieldIsInvalid() ? "text-feedback-red" : "text-solid-bold-green";

    const errorExists = () => errorMessage != undefined && errorMessage != ""

    const errorControl = () => errorExists() ? <label className={`text-feedback-red text-xs jbp-text-field-validation-message-${ConvertStringToHtmlId(props.title)}`}>{errorMessage}</label> : <br />

    return (
        <div className={`mb-1 min-w-[30em] w-fit mt-2 ${props.className}`}>
            <div className={`cursor-text ${backgroundColour} ${borderColour} pl-3 border-b-2 pt-2 pb-2 text-solid-white placeholder-shown:text-secondary-deep-blue-30`} onClick={onClick}>
                <label className={`jbp-text-input-label cursor-text text-xs ${labelTextColour} block`}>{props.mandatory == true ? '*' : ''}{props.title}</label>
                <input ref={inputRef} id={`jbp-text-field-${ConvertStringToHtmlId(props.title)}`} className={`jbp-text-field ${backgroundColour} focus:outline-none w-full`} type="text" placeholder={props.placeholder} onBlur={onBlur} defaultValue={textValue} />
            </div>
            <div>
                {errorControl()}
            </div>
        </div>
    )
}
