import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IField, ITypographyField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const powerDownPrependedMessagePath = "model.jobCommentPrependedMessages.customerAgreesToPowerDown";

class PowerDownTreeNodeData extends JbpTreeNodeData {
    constructor(title: string, fields: IField[]) {
        super(title, fields);
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(powerDownPrependedMessagePath)
    }
}

export class PowerDownNode extends ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    public static PowerDownSelectedHandler() {
        Model.GetModel().Set(powerDownPrependedMessagePath, "Powerdown Agreed: Yes\n")
    }

    public static PowerDownNotSelectedHandler() {
        Model.GetModel().Set(powerDownPrependedMessagePath, "")
    }

    constructor() {
        super("powerDownNode", new PowerDownTreeNodeData("Customer agrees to power being turned off?", [
            {
                fieldType: FieldType.Typography,
                text: "The power will need to be turned off for the duration of the job, which will impact the use of electrical equipment on site"
            } as ITypographyField
        ]));
    }
}
