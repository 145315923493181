import { ITreeNode } from "./ITreeNode";

export abstract class ChoiceNode<T, C> extends ITreeNode<T, C> {
    private choiceChosen = false;

    constructor(identifier: string, data: T) {
        super(identifier, data);
    }

    public ChoiceChosen() {
        return this.choiceChosen;
    }

    public SetChoiceChosen(chosen:boolean) {
        this.choiceChosen = chosen;
    }
}
