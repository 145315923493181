import { SiteContactDetails } from "../../../APIDocs/apiTypes"
import { Label } from "../components/Label"

export interface props {
    contactDetails: SiteContactDetails
}

export const SiteContact = (props: props) => {
    return (
        <div>
            {props.contactDetails.Title == undefined ? <div></div> : <Label title="Title" text={props.contactDetails.Title}></Label>}
            {props.contactDetails.ContactFirstName == undefined ? <div></div> : <Label title="First Name" text={props.contactDetails.ContactFirstName}></Label>}
            {props.contactDetails.ContactSurname == undefined ? <div></div> : <Label title="Surname" text={props.contactDetails.ContactSurname}></Label>}
            {props.contactDetails.ContactNumber == undefined ? <div></div> : <Label title="Number" text={props.contactDetails.ContactNumber}></Label>}
            {props.contactDetails.EmailAddress == undefined ? <div></div> : <Label title="Email" text={props.contactDetails.EmailAddress}></Label>}
        </div>
    )
}
