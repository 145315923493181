import { Title } from "../../components/Title";
import { useEffect, useState } from "react";
import { ITreeNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JbpTreeNodeData, ValidState } from "../../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../../treeExtensions/IJbpChildNodeRelationship";
import { useSynchronousState } from "../../hooks/useSynchronousState";
import { useModel } from "../../hooks/useModel";
import { useGetRenderableNodes } from "../../hooks/useGetRenderableNodes";
import { IWorkflowFactory } from "../../factories/IWorkflowFactory";
import { Button } from "../../components/Button";
import { CompleteNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { RenderData } from "../../visitors/RenderableNodeVisitor";
import { IndicatorType } from "../../components/WorkflowIndicator";
import { ConvertStringToHtmlId } from "../../../../../../libs/ts/ConvertStringToHtmlId";
import { useNavigate } from "react-router-dom";

export interface props {
    workflowFactory: IWorkflowFactory,
    title: string,
    mpan: string,
    serviceLine: string
}

export const WorkflowView = (props: props) => {
    const root = useSynchronousState<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship> | undefined>(undefined);
    const model = useModel();

    const [view, setView] = useState(<div></div>)
    const getRenderableNodes = useGetRenderableNodes();
    const hasLoaded = useSynchronousState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(hasLoaded.get() == false) {
            hasLoaded.set(true);
            props.workflowFactory.Build(buildView)
                .then((tree) => {
                    root.set(tree);
                    buildView();
                });
        }
    }, [])

    const lastNodeIsCompleteNode = (nodes: RenderData[]) => {
        return nodes != undefined && nodes[nodes.length - 1].node instanceof CompleteNode;
    }

    const getCompleteButtonView = (nodes: RenderData[]) => {
        const completeNode = lastNodeIsCompleteNode(nodes) ? nodes[nodes.length - 1].node as CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship> : undefined;

        if(completeNode != undefined) {
            const relationship = completeNode.Relationship()

            const onClick = () => {
                if(relationship.onSelected != undefined) {
                    relationship.onSelected();
                }

                nodes.forEach((n) => {
                    const fields = n.node.Data().Fields();
                    const mandatoryFieldsUnattempted = n.node.Data().MandatedFieldsExistThatAreUnattempted();
                    if(mandatoryFieldsUnattempted) {
                        fields.forEach((field) => {
                            if(field.mandatory === true) {
                                const validationResp = field.isValid(model.Get(field.modelPath));
                                n.node.Data().SetFieldAsValid(field, validationResp != undefined && validationResp.isValid ? ValidState.valid : ValidState.invalid)
                                if(n.node.TreeChanged) {
                                    n.node.TreeChanged();
                                }
                            }
                        })
                    }
                })

                const navigateToNode = (n: RenderData) =>
                    document.getElementById(`jbp-workflow-node-${ConvertStringToHtmlId(n.node.Identifier())}`)
                        ?.scrollIntoView({ behavior: "smooth", block: "center" });


                const noNodesInError = nodes.every((n) => {
                    const nodeIsInError = n.node.Data().WorkflowIndicator() == IndicatorType.Error
                    if(nodeIsInError) {
                        navigateToNode(n);
                    }

                    return nodeIsInError == false;
                })

                if(noNodesInError) {
                    if(relationship.navigateToPathOnSelect != undefined) {
                        navigate(relationship.navigateToPathOnSelect);
                    }
                    if(relationship.determinePathOnSelect != undefined) {
                        navigate(relationship.determinePathOnSelect());
                    }
                    //on select functions trigger regardless of them being valid. so this was required.
                    if(relationship.triggerFunctionWhenNoErrorsOnSelected != undefined) {
                        relationship.triggerFunctionWhenNoErrorsOnSelected();
                    }
                    completeNode.SetIsSatisfied(true);
                }
            }

            return <Button primary={true} text={relationship.workflowButtonText} className="ml-7" icon={true} disabled={false} onClick={onClick} />
        }
        else {
            return <span></span>
        }
    }

    const buildView = () => {
        const currentRoot = root.get();
        if(currentRoot != undefined) {
            const nodes = getRenderableNodes(currentRoot);

            setView(
                <div className="mb-10">
                    {nodes.map((renderData, index) => {
                        return <div key={index} id={`jbp-workflow-node-${ConvertStringToHtmlId(renderData.node.Identifier())}`}>
                            {renderData.viewFactory.MakeView(renderData.node, props.mpan, props.serviceLine)}
                        </div>
                    })}
                    {getCompleteButtonView(nodes)}
                </div>
            )
        }
    }

    return (
        root == undefined ?
            <div>Loading</div>
            :
            <div className="ml-10 pb-4">
                {
                    props.title != "" ?
                        <Title className="mt-7 mb-5" text={`${props.title}: ${props.mpan}`} />
                        :
                        <span></span>
                }
                {view}
            </div>
    );
}
