import { CommonRegularExpressions } from "../../../../../libs/ts/CommonRegularExpressions";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class ConfirmationSMSNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    constructor() {
        super("confirmationSMS", new JbpTreeNodeData("Please confirm the mobile number to be used for SMS notifications", [
            {
                fieldType: FieldType.EditableLabelField,
                title: "Contact Number",
                placeHolder: "Please enter a contact number",
                modelPath: "model.results.confirmationSMSNumber",
                mandatory: true,
                isValid: (value: string) => {
                    const regex = CommonRegularExpressions.PhoneNumberRegex;
                    const passes = regex.test(value);
                    if(passes) return { isValid: true }

                    return {
                        isValid: false,
                        errorMessage: "Please check your phone number is a valid mobile number, ensuring no spaces"
                    }
                }
            } as IEditableLabelField
        ]))
    }
}
