import { ITreeNode } from "../Nodes/ITreeNode";
import { ITreeNodeBuilder } from "./ITreeNodeBuilder";
import { TreeNodeBuilder } from "./TreeNodeBuilder";

export class TreeBuilderMemento<T, C> implements ITreeNodeBuilder<T, C> {

    private node: ITreeNode<T, C>;
    private originalBuilder: TreeNodeBuilder<T, C>

    constructor(referenceNode: ITreeNode<T, C>, originalBuilder:TreeNodeBuilder<T, C>) {
        this.node = referenceNode;
        this.originalBuilder = originalBuilder;
    }

    public AddTreeNode(treeNode: ITreeNode<T, C>, relationship: C): ITreeNodeBuilder<T, C>;
    public AddTreeNode(treeNode: ITreeNode<T, C>, relationship: C, condition?: ()=>boolean): ITreeNodeBuilder<T, C> {
        this.originalBuilder.SetCurrentNode(this.node);
        return this.originalBuilder.AddTreeNode(treeNode, relationship, condition);
    }

    public Build(): ITreeNode<T, C> {
        return this.originalBuilder.Build();
    }

    public GetReferenceNode() {
        return this.node;
    }
}
