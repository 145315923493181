import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, ITagGroup, ITypographyField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const captureAccessInstructionsPrependedMessagePath = "model.jobCommentPrependedMessages.parkingInfo";


export class CaptureParkingInfo extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{
    constructor() {
        //Should be "access to parking"?
        super("captureParkingInfo", new JbpTreeNodeData("Please provide additional information regarding parking & access", [
            {
                text: "Select all the relevant items to describe the parking on site",
            } as ITypographyField,
            {
                fieldType: FieldType.TagGroup,
                modelPath: "model.results.parkingInformation",
                hideTitle: true,
                isValid: (_value: string[]) => {
                    return { isValid: true }
                },
                onChange: (value: string[]) => {
                    if(value.length > 0) {
                        Model.GetModel().Set(captureAccessInstructionsPrependedMessagePath, `Parking Info: ${value.toString()}\n`)
                    }
                    else {
                        Model.GetModel().Set(captureAccessInstructionsPrependedMessagePath, ``)
                    }
                },
                tags: [
                    {
                        tagId: "Free Parking",
                        turnOff: ["Pay & Display", "Payment App (via phone)"]
                    },
                    {
                        tagId: "Onsite Car Park",
                    },
                    {
                        tagId: "Pay & Display",
                        turnOff: ["Free Parking"]
                    },
                    {
                        tagId: "Permit Parking (Customer to provide)",
                    },
                    {
                        tagId: "Payment App (via phone)",
                        turnOff: ["Free Parking"]
                    },
                    {
                        tagId: "Short Walk",
                        turnOff: ["Long Walk"]
                    },
                    {
                        tagId: "Long Walk",
                        turnOff: ["Short Walk"]
                    },
                    {
                        tagId: "Time of Day Parking Restrictions",
                    },
                    {
                        tagId: "Vehicle height restrictions",
                    }
                ]

            } as ITagGroup
        ]))
    }
}
