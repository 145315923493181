import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

export interface props<T> {
    title: string,
    data: T[] | undefined,
    className?: string,
    children: JSX.Element[]
}

export const List = function <T>(props: props<T>) {
    return (
        <div className={`jbp-list-${ConvertStringToHtmlId(props.title)} mt-2 overflow-y-scroll pr-2 ${props.className}`} data-length={props.data == undefined ? 0 : props.data.length}>
            {props.children}
        </div>
    )
}
