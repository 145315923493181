import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";

export enum feedbackColour {
    blue = "Blue",
    green = "Green",
    yellow = "Yellow",
    orange = "Orange",
    red = "Red",
    redText = "RedText"
}

export interface props {
    title: string,
    subTitle?: string,
    cells: string[],
    tagText?: string,
    feedbackColour: feedbackColour
    onClick?: () => void
    className?: string
}

const getDynamicColours = (fbColour: feedbackColour | undefined) => {
    switch(fbColour) {
        case feedbackColour.blue:
            return ["border-feedback-blue", "bg-feedback-blue"];
        case feedbackColour.green:
            return ["border-feedback-green", "bg-feedback-green"];
        case feedbackColour.orange:
            return ["border-feedback-orange", "bg-feedback-orange"];
        case feedbackColour.red:
            return ["border-feedback-red", "bg-feedback-red"];
        case feedbackColour.redText:
            return ["border-feedback-red-text", "bg-feedback-red-text"];
        case feedbackColour.yellow:
            return ["border-feedback-yellow", "bg-feedback-yellow"];
        default:
            return ['', ''];
    }
}

export const ListItem = (props: props) => {
    const cellWidth = `w-1/${props.cells.length}`;


    const cellElements = props.cells?.map((cellOption, index: number) => {
        const classIdentifiers = `jbp-list-item-cell-${index}
            jbp-list-item-cell-${index}-with-title-${ConvertStringToHtmlId(props.title)}
            jbp-list-item-cell-${index}-with-subtitle-${props.subTitle == undefined ? "" : ConvertStringToHtmlId(props.subTitle)}`
        return (
            <div key={index} className={`inline-block ${cellWidth}`}>
                <span className={classIdentifiers}>{cellOption}</span>
            </div>
        )
    })

    const [indicatorBorder, tagBackground] = getDynamicColours(props.feedbackColour);

    const cursorJbp = props.onClick != undefined ? "cursor-pointer" : "";

    return (
        <div className={`
                jbp-list-item-with-title-${ConvertStringToHtmlId(props.title)}
                jbp-list-item-with-subtitle-${props.subTitle == undefined ? "" : ConvertStringToHtmlId(props.subTitle)}
                hover:bg-elevation-2 mb-2 rounded-sm bg-elevation-1 h-16 border-solid border-l-[5px] ${indicatorBorder} ${cursorJbp} ${props.className}`} onClick={props.onClick}>
            <div className="inline-block w-1/5 pl-4 mt-2">
                <div>
                    <span className="font-bold jbp-list-item-title">{props.title}</span>
                </div>
                <div>
                    <span className="text-sm text-soft-elevation-0 jbp-list-item-subtitle">{props.subTitle == undefined ? "" : props.subTitle}</span>
                </div>
            </div>
            <div className="w-3/5 inline-block align-top text-center mt-5">
                {cellElements}
            </div>
            <div className="inline-block w-1/5 align-top mt-6">
                <div className={`
                        jbp-list-item-tag-with-title-${ConvertStringToHtmlId(props.title)}
                        jbp-list-item-tag-with-subtitle-${props.subTitle == undefined ? "" : ConvertStringToHtmlId(props.subTitle)}
                        rounded-3xl ${tagBackground} bg-feedback-blue text-center w-28 text-solid-deep-blue font-bold text-sm float-right mr-4`}>
                    {props.tagText}
                </div>
            </div>
        </div>
    )
}
