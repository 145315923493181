import { CompleteNode } from "../Nodes/CompleteNode";
import { ConditionalChoiceNode } from "../Nodes/ConditionalChoiceNode";
import { ExclusiveChoiceNode } from "../Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../Nodes/ITreeNode";
import { SequenceNode } from "../Nodes/SequenceNode";
import { InfoNode } from "../Nodes/InfoNode";
import { TerminateNode } from "../Nodes/TerminateNode";
import { TreeNodePathStack } from "../TreeNodePathStack";
import { IVisitor } from "./IVisitor";

export class LeafValidatorVisitor<T, C> extends IVisitor<T, C> {

    private throwInvalidLeafException(identifier:string) {
        throw `Node "${identifier}" cannot be a leaf. Only Complete or Terminal nodes may be leaves`;
    }

    protected override VisitCompleteNode(node: CompleteNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length > 0) {
            throw `Complete nodes are not allowed to have child nodes -> ${node.Identifier()}`;
        }
    }

    protected override VisitConditionalChoiceNode(node: ConditionalChoiceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length == 0) {
            this.throwInvalidLeafException(node.Identifier());
        }
    }

    protected override VisitExclusiveChoiceNode(node: ExclusiveChoiceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length == 0) {
            this.throwInvalidLeafException(node.Identifier());
        }
    }

    protected override VisitSequenceNode(node: SequenceNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length == 0) {
            this.throwInvalidLeafException(node.Identifier());
        }
    }

    protected override VisitInfoNode(node: InfoNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length == 0) {
            this.throwInvalidLeafException(node.Identifier());
        }
    }

    protected override VisitTerminateNode(node: TerminateNode<T, C>, _pathStack: TreeNodePathStack<T, C>, _parent?:ITreeNode<T, C>): void {
        if(node.Children().length > 0) {
            throw `Terminate nodes are not allowed to have child nodes -> ${node.Identifier()}`;
        }
    }
}
