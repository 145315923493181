import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Search } from "./Search";
import { Site } from "./Site";
import { BookJobSiteInfo } from "./renderWorkflowView/BookJobSiteInfo";
import { _404 } from "./404";
import { BookJobOffers } from "./BookJobOffers";
import { BookJobCommunications } from "./renderWorkflowView/BookJobCommunications";
import { BookJobSiteJobType } from "./renderWorkflowView/BookJobSiteJobType";
import { BookingResult } from "./BookingResult";
import { Job } from "./Job";
import { BookJobWarrantInfo } from "./renderWorkflowView/BookJobWarrantInfo";
import { BookJobOffersInternal } from "./BookJobOffersInternal";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Search />,
        errorElement: <_404 />
    },
    {
        path: "site/:mpan/:serviceLine",
        element: <Site />
    },
    {
        path: "bookJob/siteJobType/:mpan/:serviceLine",
        element: <BookJobSiteJobType />
    },
    {
        path: "bookJob/siteInfo/:mpan/:serviceLine",
        element: <BookJobSiteInfo />
    },
    {
        path: "bookJob/offers/:mpan/:serviceLine",
        element: <BookJobOffers />
    },
    {
        path: "bookJob/internalOffers/:mpan/:serviceLine",
        element: <BookJobOffersInternal />
    },
    {
        path: "bookJob/warrantSiteInfo/:mpan/:serviceLine",
        element: <BookJobWarrantInfo />
    },
    {
        path: "bookJob/communications/:mpan/:serviceLine",
        element: <BookJobCommunications />
    },
    {
        path: "bookingResult/:mpan/:serviceLine",
        element: <BookingResult />
    },
    {
        path: "job/:bookingId/:mpan/:serviceLine",
        element: <Job />
    },
    {
        path: "404",
        element: <_404 />
    }
]);

const queryClient = new QueryClient()

export const PageContainer = () => {
    return (
        <div className='mb-10'>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </div>
    )
}
