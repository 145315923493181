export interface siteTypeCodeData {
    siteTypeCode: string,
    siteTypeDescription: string
}

export const useSiteTypes = () => {
    return (contractType: "2" | "4" | "8" | "HH" | "NHH" | "SUBMETER" | "DNO" | "BNO" | "100" | "101") => {
        const siteTypes = {
            HH: [
                { siteTypeCode: "LV CP5", siteTypeDescription: "Low Voltage COP5" },
                { siteTypeCode: "LV CP3", siteTypeDescription: "Low Voltage COP3" },
                { siteTypeCode: "CT LV", siteTypeDescription: "CT Low Voltage" },
                { siteTypeCode: "CT HV", siteTypeDescription: "CT High Voltage" },
                { siteTypeCode: "HV CP2", siteTypeDescription: "High Voltage COP 2" },
                { siteTypeCode: "HV CP3", siteTypeDescription: "High Voltage COP 3" },
                { siteTypeCode: "HV CP5", siteTypeDescription: "High Voltage COP 5" },
                { siteTypeCode: "WC 1PH", siteTypeDescription: "Whole Current 1 Phase" },
                { siteTypeCode: "WC 3PH", siteTypeDescription: "Whole Current 3 Phase" }
            ] as siteTypeCodeData[],
            NHH: [
                { siteTypeCode: "CT LV", siteTypeDescription: "CT Low Voltage" },
                { siteTypeCode: "CT HV", siteTypeDescription: "CT High Voltage" },
                { siteTypeCode: "WC 1PH", siteTypeDescription: "Whole Current 1 Phase" },
                { siteTypeCode: "WC 3PH", siteTypeDescription: "Whole Current 3 Phase" }
            ] as siteTypeCodeData[],
            SubMeter: [
                { siteTypeCode: "CT LV", siteTypeDescription: "CT Low Voltage" },
                { siteTypeCode: "WC 1PH", siteTypeDescription: "Whole Current 1 Phase" },
                { siteTypeCode: "WC 3PH", siteTypeDescription: "Whole Current 3 Phase" }
            ] as siteTypeCodeData[],
            DNO: [
                { siteTypeCode: "CT LV", siteTypeDescription: "CT Low Voltage" },
                { siteTypeCode: "WC 1PH", siteTypeDescription: "Whole Current 1 Phase" },
                { siteTypeCode: "WC 3PH", siteTypeDescription: "Whole Current 3 Phase" }
            ] as siteTypeCodeData[],
            BNO: [
                { siteTypeCode: "CT LV", siteTypeDescription: "CT Low Voltage" },
                { siteTypeCode: "WC 1PH", siteTypeDescription: "Whole Current 1 Phase" },
                { siteTypeCode: "WC 3PH", siteTypeDescription: "Whole Current 3 Phase" }
            ] as siteTypeCodeData[]
        }
        switch(contractType) {
            case "NHH":
            case "2":
                return siteTypes.NHH;
            case "SUBMETER":
            case "4":
                return siteTypes.SubMeter;
            case "HH":
            case "8":
                return siteTypes.HH;
            case "DNO":
            case "100":
                return siteTypes.DNO;
            case "BNO":
            case "101":
                return siteTypes.BNO;
        }
    }
}
