import { EnvironmentVariables } from "./EnvironmentVariables";

enum LogLevel {
    Log = "Log",
    Error = "Err"
}

interface LogEntries {
    level: LogLevel,
    msg: string
}

interface JBPConsole extends Console {
    jbplog: (msg: string) => void,
    jbperror: (msg: string) => void
}

declare let console: JBPConsole;

export class Logger {
    static logs: LogEntries[] = [];
    static readonly maxLogs = 1000;

    public static Log(msg: string) {
        Logger.teeToInternalLog(LogLevel.Log, `${msg} \n\n${new Error().stack}`)
        console.jbplog(msg);
    }

    public static Error(msg: string) {
        Logger.teeToInternalLog(LogLevel.Error, `${msg} \n\n${new Error().stack}`)
        Logger.postErrors();
        console.jbperror(msg);
    }

    static teeToInternalLog(level: LogLevel, msg: string) {
        Logger.logs.push({ level: level, msg: msg });
        while(Logger.logs.length > 1000) {
            Logger.logs.shift();
        }
    }

    static postErrors() {
        const bodyStr = JSON.stringify({
            logs: Logger.logs
        });
        fetch(`${EnvironmentVariables.ApiUrl}/error-logs`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
            body: bodyStr
        });
    }
}

console.jbplog = console.log.bind(console);
console.jbperror = console.error.bind(console);

console.log = Logger.Log;
console.error = Logger.Error;
