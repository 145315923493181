import { ValidationResponse } from "../components/ValidationResponse"

export enum FieldType {
    TextInput,
    TextArea,
    DropDown,
    Typography,
    ExternalLink,
    EditableLabelField,
    LabelField,
    RadioButtons,
    TagGroup,
    MultiButtons
}

export interface FieldConstraints {
    maxLength: number
}

export interface IField {
    fieldType: FieldType,
    title: string,
    mandatory: boolean,
    isValid: (value: string | string[]) => ValidationResponse | undefined,
    onChange: (value: string | string[] | boolean) => void,
    modelPath: string,
    getDynamicFieldConstraints?: () => FieldConstraints,
    initialValue?: string
}

export const fieldHasNoActionsToValidate = (field: IField) => {
    return 'text' in field || 'link' in field && 'displayText' in field
}

export interface ITextField extends IField {
    placeHolder: string
}

export interface ITypographyField extends IField {
    text: string
}

export interface IExternalLink extends IField {
    link: string,
    displayText: string
}

export interface IDropdownField extends IField {
    placeHolder: string,
    elementsModelPath: string
}

export interface IEditableLabelField extends IField {
    title: string
    placeHolder: string
}

export interface IRadioButtonField {
    title: string,
    description: string
}

export interface IRadioButtonsField extends IField {
    hideTitle?: boolean,
    buttons: IRadioButtonField[]
}
export interface IMultiButtonField {
    title: string,
    isPrimary?: boolean,
    modelValueWhenSelected: string | boolean
}

export interface IMultiButtonsField extends IField {
    hideTitle?: boolean,
    buttons: IMultiButtonField[]
}


export interface ITag {
    tagId: string,
    turnOff?: string[]
}

export interface ITagGroup extends IField {
    tags: ITag[]
    hideTitle?: boolean,
}
