import { LeafValidatorVisitor } from "../Visitor/LeafValidatorVisitor";
import { ITreeNode } from "../Nodes/ITreeNode";
import { SequenceNode } from "../Nodes/SequenceNode";
import { ITreeNodeBuilder } from "./ITreeNodeBuilder";
import { TreeBuilderMemento } from "./TreeBuilderMemento";
import { ConditionalChoiceNode } from "../Nodes/ConditionalChoiceNode";

export class TreeNodeBuilder<T, C> implements ITreeNodeBuilder<T, C> {
    private root: ITreeNode<T, C> | undefined;
    private currentNode: ITreeNode<T, C> | undefined;
    private treeChanged: () => void

    constructor(treeChanged: () => void) {
        this.treeChanged = treeChanged;
    }

    private handleAddingRoot(treeNode: ITreeNode<T, C>, condition?: () => boolean) {
        this.root = treeNode;
        this.root.SetTreeChanged(this.treeChanged)

        if(condition != undefined) {
            throw 'A root node cannot be added to the tree with conditions'
        }
    }

    private handleAddingToCurrentNode(treeNode: ITreeNode<T, C>, relationship: C, condition?: () => boolean) {
        if(this.currentNode instanceof SequenceNode && this.currentNode.Children().length == 1) {
            throw `Error: Sequence nodes -> '${this.currentNode.Identifier()}' may only contain 1 child`;
        }
        if(this.currentNode != undefined) {
            this.currentNode.AddChild(treeNode, relationship);
            treeNode.SetTreeChanged(this.treeChanged);

            const isConditionalChoiceNode = this.currentNode instanceof ConditionalChoiceNode

            if(isConditionalChoiceNode == true) {
                if(condition == undefined) {
                    throw 'Nodes added to Conditional choice nodes must be added with condition parameters'
                }
                else {
                    (this.currentNode as ConditionalChoiceNode<T, C>).AddCondition(condition)
                }
            }
        }
        else {
            throw 'Error: Root is defined yet the current node has not been set'
        }
    }

    public AddTreeNode(treeNode: ITreeNode<T, C>, relationship: C, condition?: () => boolean): ITreeNodeBuilder<T, C> {
        if(this.root == undefined) {
            this.handleAddingRoot(treeNode, condition);
        }
        else {
            this.handleAddingToCurrentNode(treeNode, relationship, condition);
        }
        return new TreeBuilderMemento(treeNode, this);
    }

    public Build(): ITreeNode<T, C> {
        if(this.root == undefined) throw 'Error: cannot build a tree without nodes';
        this.root.Accept(new LeafValidatorVisitor());
        this.root.SetIsRoot(true);
        return this.root;
    }

    public SetCurrentNode(node: ITreeNode<T, C>) {
        this.currentNode = node;
    }
}
