import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { getBookingResponse200 } from "../../../APIDocs/apiTypes";

export class EditSiteAddressDetailsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    constructor(bookingData: getBookingResponse200) {

        const SiteName = {
            fieldType: FieldType.EditableLabelField,
            title: "Site Name",
            placeHolder: "Please enter a siteName",
            initialValue: bookingData.AddressDetails.AddressName,
            modelPath: "model.results.siteAddressDetails.siteName",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure you have entered a Site Name"
                }
            }
        } as IEditableLabelField;

        const AddressLine1 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 1",
            placeHolder: "Enter Address Line 1",
            initialValue: bookingData.AddressDetails.AddressLine1,
            modelPath: "model.results.siteAddressDetails.line1",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const AddressLine2 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 2",
            placeHolder: "Enter Address Line 2",
            initialValue: bookingData.AddressDetails.AddressLine2,
            modelPath: "model.results.siteAddressDetails.line2",
            isValid: (value: string) => {
                const regex = /^[\w\W]{0,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const AddressLine3 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 3",
            placeHolder: "Enter Address Line 3",
            initialValue: bookingData.AddressDetails.AddressLine3,
            modelPath: "model.results.siteAddressDetails.line3",
            isValid: (value: string) => {
                const regex = /^[\w\W]{0,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const TownField = {
            fieldType: FieldType.EditableLabelField,
            title: "Town",
            placeHolder: "Please enter the town where the site is located",
            initialValue: bookingData.AddressDetails.Town,
            modelPath: "model.results.siteAddressDetails.town",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid town."
                }
            }
        } as IEditableLabelField;

        const CountyField = {
            fieldType: FieldType.EditableLabelField,
            title: "County",
            placeHolder: "Please enter the county where the site is located",
            initialValue: bookingData.AddressDetails.County,
            modelPath: "model.results.siteAddressDetails.county",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure your county is valid"
                }
            }
        } as IEditableLabelField;


        super("siteContactDetails", new JbpTreeNodeData("Please confirm the site address details", [SiteName, AddressLine1, AddressLine2, AddressLine3, TownField, CountyField]));
    }

    public override onInitialise(): void {
        const fetchedFieldMappings = [
            { fetched: "updateJobModel.siteAddress.siteName", result: "model.results.siteAddressDetails.siteName" },
            { fetched: "updateJobModel.siteAddress.line1", result: "model.results.siteAddressDetails.line1" },
            { fetched: "updateJobModel.siteAddress.line2", result: "model.results.siteAddressDetails.line2" },
            { fetched: "updateJobModel.siteAddress.line3", result: "model.results.siteAddressDetails.line3" },
            { fetched: "updateJobModel.siteAddress.town", result: "model.results.siteAddressDetails.town" },
            { fetched: "updateJobModel.siteAddress.county", result: "model.results.siteAddressDetails.county" }
        ]

        fetchedFieldMappings.forEach((mapping) => {
            if(Model.GetModel().Get(mapping.result) == undefined) {
                const fetchedValue = Model.GetModel().Get(mapping.fetched);
                if(fetchedValue != undefined) Model.GetModel().Set(mapping.result, fetchedValue)
            }
        })
    }
}
