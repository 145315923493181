import { ExternalFooterLink } from "../components/ExternalLink";


export const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div className='fixed bottom-0 left-0 right-0 border-t-[1px] border-secondary-deep-blue-80 pt-2 bg-solid-deep-blue'>
            <ExternalFooterLink className="inline-block mr-5 ml-5" link="https://www.siemens.com/global/en/general/terms-of-use.html" linkText="Terms of Use" />
            <ExternalFooterLink className="inline-block" link="https://www.siemens.com/global/en/general/privacy-notice.html" linkText="Privacy Notice" />
            <span className='ml-10 text-sm'>© Siemens {currentYear}</span>
        </div>
    )
}
