import { SiteAddress as SiteAddressType } from "../../../APIDocs/apiTypes"
import { Label } from "../components/Label"

export interface props {
    siteAddress: SiteAddressType
}

export const SiteAddress = (props: props) => {
    return (
        <div>
            {props.siteAddress.AddressName == undefined ? <div></div> : <Label title="Company Name" text={props.siteAddress.AddressName}></Label>}
            {props.siteAddress.AddressLine1 == undefined ? <div></div> : <Label title="Address Line 1" text={props.siteAddress.AddressLine1}></Label>}
            {props.siteAddress.AddressLine2 == undefined ? <div></div> : <Label title="Address Line 2" text={props.siteAddress.AddressLine2}></Label>}
            {props.siteAddress.AddressLine3 == undefined ? <div></div> : <Label title="Address Line 3" text={props.siteAddress.AddressLine3}></Label>}
            {props.siteAddress.AddressLine4 == undefined ? <div></div> : <Label title="Address Line 4" text={props.siteAddress.AddressLine4}></Label>}
            {props.siteAddress.AddressLine5 == undefined ? <div></div> : <Label title="Address Line 5" text={props.siteAddress.AddressLine5}></Label>}
            {props.siteAddress.AddressLine6 == undefined ? <div></div> : <Label title="Address Line 6" text={props.siteAddress.AddressLine6}></Label>}
            {props.siteAddress.Town == undefined ? <div></div> : <Label title="Town" text={props.siteAddress.Town}></Label>}
            {props.siteAddress.County == undefined ? <div></div> : <Label title="County" text={props.siteAddress.County}></Label>}
            {props.siteAddress.PostCode == undefined ? <div></div> : <Label title="Post Code" text={props.siteAddress.PostCode}></Label>}
        </div>
    )
}
