import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { ConditionalChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ConditionalChoiceNode";
import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { TerminateNode } from "../../../../../libs/ts/WorkflowTree/Nodes/TerminateNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class RenderFlagSetter extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    private relationshipHasBeenSelected(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>): boolean {
        if(parent != undefined) {
            const relativeRelationship = parent.getRelativeRelationship(node);
            if(relativeRelationship != undefined && relativeRelationship.relationshipModelPath != undefined) {
                return Model.GetModel().Get(relativeRelationship.relationshipModelPath) === relativeRelationship.modelValueWhenRelationshipIsTrue;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    private setShouldRender(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, shouldRender: boolean) {
        node.Data().SetShouldRender(pathStack, shouldRender);
    }

    private nodeIsRoot(parentNode: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship> | undefined) {
        return parentNode == undefined
    }

    private shouldRender(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, parent: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship> | undefined, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const pathStackForGrandparent = () => pathStack.newPathStackFromIndex(pathStack.Length() - 2)
        let render = false;
        if(this.nodeIsRoot(parent)) {
            render = true;
        }
        else {
            const grandParentStack = pathStackForGrandparent();
            if(parent?.Data().IsEndOfBranch(grandParentStack) == false && parent?.Data().ShouldRender(grandParentStack) === true) {
                if(parent instanceof ConditionalChoiceNode) {
                    const predicate = (parent as ConditionalChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>).PredicateForNode(node)
                    if(predicate != undefined && predicate() === true) {
                        render = true;
                    }
                }
                else if(parent instanceof ExclusiveChoiceNode) {
                    const relationshipSelected = this.relationshipHasBeenSelected(node, parent);
                    if(relationshipSelected == true) {
                        render = true;
                    }
                }
                else {
                    render = true;
                }
            }
        }
        return render;
    }

    protected override VisitCompleteNode(node: CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        let render = this.shouldRender(node, parent, pathStack);
        if(parent instanceof ConditionalChoiceNode || parent instanceof ExclusiveChoiceNode) {
            const relationshipSelected = this.relationshipHasBeenSelected(node, parent);
            if(render == true && relationshipSelected == false) {
                render = false;
            }
        }
        this.setShouldRender(node, pathStack, render);
        const isTheEndOfTheBranch = render === true
        node.Data().SetEndOfBranch(pathStack, isTheEndOfTheBranch);
    }

    protected override VisitTerminateNode(node: TerminateNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        let render = this.shouldRender(node, parent, pathStack);
        if(parent instanceof ConditionalChoiceNode || parent instanceof ExclusiveChoiceNode) {
            const relationshipSelected = this.relationshipHasBeenSelected(node, parent);
            if(render == true && relationshipSelected == false) {
                render = false;
            }
        }
        this.setShouldRender(node, pathStack, render);
        const isTheEndOfTheBranch = render === true
        node.Data().SetEndOfBranch(pathStack, isTheEndOfTheBranch);
    }

    protected override VisitSequenceNode(node: SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const render = this.shouldRender(node, parent, pathStack);
        this.setShouldRender(node, pathStack, render);
    }

    protected override VisitInfoNode(node: InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const render = this.shouldRender(node, parent, pathStack);
        this.setShouldRender(node, pathStack, render);
    }

    protected override VisitConditionalChoiceNode(node: ConditionalChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const render = this.shouldRender(node, parent, pathStack);
        this.setShouldRender(node, pathStack, render);
        const isTheEndOfTheBranch = render === true && node.IsSatisfied() === false
        node.Data().SetEndOfBranch(pathStack, isTheEndOfTheBranch);
    }

    protected override VisitExclusiveChoiceNode(node: ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>, pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const render = this.shouldRender(node, parent, pathStack);
        this.setShouldRender(node, pathStack, render);
        const isTheEndOfTheBranch = render === true && node.IsSatisfied() === false
        node.Data().SetEndOfBranch(pathStack, isTheEndOfTheBranch);
    }
}
