import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { ConditionalChoiceView } from "../elements/nodeViews/ConditionalChoiceView";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IViewFactory } from "./IViewFactory";

export class CondtionalChoiceFactory extends IViewFactory {
    public override MakeView(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, mpan: string, _serviceLine: string): JSX.Element {
        return <ConditionalChoiceView key={node.Identifier()} node={node} mpan={mpan} />;
    }
}
