import { BookingResponse } from "../../../APIDocs/apiTypes";
import { CompletionMessageFactory } from "../factories/CompletionMessageFactory";
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery"
import { useDefinedParams } from "../hooks/useDefinedParams";
import { ApiError } from "./ApiError";
import { BookingSuccess } from "./BookingSuccess";
import { useNavigate } from "react-router-dom";
import { Model } from "../Model";
import { useState } from "react";
export const BookingResult = () => {
    // mpan is not being used but if its removed the serviceLine becomes undefined
    //@ts-ignore
    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const { isLoading, data, refetch } = useJbpQuery<BookingResponse, BookingResponse>(RestTypes.Post, CompletionMessageFactory.MakeMessage(serviceLine), ["booking"], `jbp/${mpan}/booking`, false, serviceLine);
    const nav = useNavigate();
    const [bookJobRequestMade, setBookingRequestMade] = useState(false);

    const shouldPostBookingRequest: boolean | undefined = Model.GetModel().Get("model.results.offerTokenSelected")

    let backButtonPrevented = false;
    history.pushState(null, document.title, location.href);

    function popStateListener() {
        if(backButtonPrevented === false) {
            history.pushState(null, document.title, location.href);
            backButtonPrevented = true;
            nav(`/site/${mpan}/${serviceLine}`);
        } else {
            window.removeEventListener('popstate', popStateListener);
        }
    }

    window.addEventListener('popstate', popStateListener);

    const triggerBookJob = () => {
        setBookingRequestMade(true);
        refetch();
    }

    if(shouldPostBookingRequest === true && bookJobRequestMade === false) {
        triggerBookJob();
        return <span></span>
    }
    else if(shouldPostBookingRequest == null || shouldPostBookingRequest == false) {
        return <ApiError errorDescription={"You have not completed the questions required to confirm your booking. Please return to the site page and follow the book job process "}></ApiError>
    }
    else if(isLoading) {
        return <div>We're waiting for your booking to be accepted. Please wait</div>
    }
    else {
        if(data?.Status == "Success" && data?.ConfirmationID != undefined) {
            return <div>
                <BookingSuccess bookingId={data.ConfirmationID}></BookingSuccess>
            </div>
        }
        else {
            return <div>
                <ApiError errorDescription={data?.ErrorDescription}></ApiError>
            </div>
        }
    }
}
