import { IField } from "./FieldInterfaces";
import { IJbpChildNodeRelationship } from "./IJbpChildNodeRelationship";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { Model } from "../Model";
import { IndicatorType } from "../components/WorkflowIndicator";


export type fetchHandler = (() => Promise<void>) | undefined;

export enum ValidState {
    valid,
    invalid,
    unattempted
}

interface internalFieldsData {
    field: IField,
    isValid: ValidState
}

export class JbpTreeNodeData {
    protected title: string;
    protected fields: internalFieldsData[];
    protected fetchFn: fetchHandler;
    protected shouldRender: Map<string, boolean> = new Map();
    protected endOfBranch: Map<string, boolean> = new Map();
    protected isLastNodeInRenderList = false;
    protected allFieldEntriesSatisfied = false;
    protected workflowIndicator: IndicatorType | undefined;

    constructor(title: string, fields: IField[], fetchFn?: fetchHandler) {

        const getPreviousValidState = (field: IField) => {
            if(field.modelPath == undefined) {
                return ValidState.unattempted;
            }
            const modelData = Model.GetModel().Get(field.modelPath);
            if(modelData == undefined) {
                return ValidState.unattempted;
            }
            const validResponse = field.isValid(modelData);
            if(validResponse == undefined) {
                return ValidState.unattempted;
            }
            else if(validResponse.isValid == true) {
                return ValidState.valid;
            }
            else {
                return ValidState.invalid;
            }
        }

        this.title = title;
        this.fields = fields.map((field) => {
            return { field: field, isValid: getPreviousValidState(field) }
        });
        this.fetchFn = fetchFn;
        this.Fetch = this.Fetch.bind(this);
    }

    public Title() {
        return this.title;
    }

    public Fields() {
        return this.fields.map((f) => f.field);
    }

    public SetFieldAsValid(field: IField, valid: ValidState) {
        const selectedFieldArray = this.fields.filter((f) => f.field.title == field.title && f.field.modelPath == field.modelPath);

        if(selectedFieldArray == undefined || selectedFieldArray.length == 0) {
            throw `Unable to find field ${field.title}`;
        }
        else {
            selectedFieldArray[0].isValid = valid;
        }
    }

    private fieldIsMandated(field: IField) {
        return field.mandatory == true;
    }

    public ContainsMandatedFields() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == true).length > 0;
    }

    public ContainsOptionalFields() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == false).length > 0;
    }

    public AllMandatedFieldsAreValid() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == true).every((f) => f.isValid == ValidState.valid);
    }

    public MandatedFieldsExistThatAreUnattempted() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == true).filter((f) => f.isValid == ValidState.unattempted).length > 0;
    }

    public AllOptionalFieldsAreValid() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == false).every((f) => f.isValid == ValidState.valid);
    }

    public OptionalFieldsExistThatAreUnattempted() {
        return this.fields.filter((f) => this.fieldIsMandated(f.field) == false).filter((f) => f.isValid == ValidState.unattempted).length > 0;
    }

    public ErrorExistsOnAnyField() {
        const errorFields = this.fields.filter((f) => f.isValid === ValidState.invalid);
        return errorFields.length > 0;
    }

    public IsLastNodeInRenderList() {
        return this.isLastNodeInRenderList;
    }

    public SetIsLastNodeInRenderList(isLast: boolean) {
        this.isLastNodeInRenderList = isLast;
    }

    public WorkflowIndicator() {
        return this.workflowIndicator;
    }

    public SetWorkflowIndicator(indicator: IndicatorType | undefined) {
        this.workflowIndicator = indicator;
    }

    public async Fetch() {
        if(this.fetchFn != undefined) {
            return this.fetchFn();
        }
        return Promise.resolve();
    }

    public ShouldRender(pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const shouldRenderValue = this.shouldRender.get(pathStack.toString());
        return shouldRenderValue != undefined && shouldRenderValue === true;
    }

    public SetShouldRender(pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, shouldRender: boolean) {
        this.shouldRender.set(pathStack.toString(), shouldRender);
    }

    public IsEndOfBranch(pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const endOfBranchValue = this.endOfBranch.get(pathStack.toString());
        return endOfBranchValue != undefined && endOfBranchValue === true;
    }

    public SetEndOfBranch(pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, reachedEndOfBranch: boolean) {
        this.endOfBranch.set(pathStack.toString(), reachedEndOfBranch);
    }

    public ShouldntRenderForAnyPathStack() {
        return Array.from(this.shouldRender.values()).every((v) => v == false);
    }

    public ShouldRenderForAllPathStacks() {
        return Array.from(this.shouldRender.values()).every((v) => v == true);
    }

    public UndoDataChanges() {
        this.fields.forEach((f) => {
            if(f.field.modelPath != undefined) {
                Model.GetModel().ClearModelValue(f.field.modelPath);
            }
            f.isValid = ValidState.unattempted;
        });
    }
}
