import { ITreeNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode"
import { Title } from "../../components/Title"
import { WorkflowIndicator } from "../../components/WorkflowIndicator"
import { useWorkflowIndicator } from "../../hooks/useWorkflowIndicator"
import { JbpTreeNodeData } from "../../treeExtensions/JbpTreeNodeData"
import { IJbpChildNodeRelationship } from "../../treeExtensions/IJbpChildNodeRelationship"
import { Fields } from "../Fields"

export interface props {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    mpan: string
}

export const SequenceView = (props: props) => {

    const { indicatorType, isFirst, isLast } = useWorkflowIndicator(props.node);

    const handleIsValid = (valid: boolean) => {
        props.node.SetIsSatisfied(valid);
    }

    return (
        <div id="sequence-view" className="flex flex-row">
            <WorkflowIndicator type={indicatorType} isFirst={isFirst} isLast={isLast} title={props.node.Data().Title()} />
            <div className="mb-5">
                <Title className="mt-7 mb-5" text={props.node.Data().Title()} />
                <Fields node={props.node} isValid={handleIsValid} />
            </div>
        </div>
    )
}
