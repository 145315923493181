import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class EmailForRamsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{
    constructor() {
        super("ramsEmail", new JbpTreeNodeData("Please confirm the email address to be used for RAMS notifications", [
            {
                fieldType: FieldType.EditableLabelField,
                title: "Email for RAMS notifications",
                placeHolder: "Please enter a valid email",
                modelPath: "model.results.emailForRams",
                mandatory: true,
                isValid: (value: string) => {
                    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
                    const passes = regex.test(value);
                    if(passes) return { isValid: true }

                    return {
                        isValid: false,
                        errorMessage: "Please ensure your email is valid"
                    }
                }
            } as IEditableLabelField
        ]))
    }
}
