import { WorkflowView } from "./WorkflowView";
import { useDefinedParams } from "../../hooks/useDefinedParams";
import { useStoreSite } from "../../hooks/useStoreSite";
import { getSiteErrorType, getSiteSuccessType } from "../../../../APIDocs/apiTypes";
import { RestTypes, useJbpQuery } from "../../hooks/useJbpQuery";
import { Logger } from "../../Logger";
import { BookJobWarrantWorkflowFactory } from "../../factories/BookJobWarrantWorkflowFactory";

export const BookJobWarrantInfo = () => {
    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const storeSiteData = useStoreSite();

    const onSiteData = (data: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, data);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Log(`Data found to not exist -> ${err.msg}`)
    }

    const { refetch } = useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site-bookJobWarrantInfo'], `jbp/${mpan}/site`, false, serviceLine, onSiteData, onSiteDataError)

    if(storeSiteData.dataStored == false) {
        refetch();
        return <span></span>
    }
    return <WorkflowView mpan={mpan} serviceLine={serviceLine} title="Booking Job for" workflowFactory={new BookJobWarrantWorkflowFactory(mpan, serviceLine)} />
}
