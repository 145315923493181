import { ChoiceNode } from "./ChoiceNode";
import { ITreeNode } from "./ITreeNode";

export class ConditionalChoiceNode<T, C> extends ChoiceNode<T, C> {
    private predicates: (()=>boolean)[] = [];

    constructor(identifier: string, data: T) {
        super(identifier, data);
    }

    public AddCondition(predicate:()=>boolean) {
        this.predicates.push(predicate);
    }

    public Predicates() : (()=>boolean)[] {
        return this.predicates;
    }

    public PredicateForNode(node:ITreeNode<T, C>):(()=>boolean)|undefined {
        let predicate:(()=>boolean)|undefined = undefined;
        this.children.forEach((child, index)=>{
            if(node == child.node) {
                predicate = this.predicates[index]
            }
        })
        return predicate;
    }
}
