import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { FetchVisitor } from "../visitors/FetchVisitor";
import { IsSatisfiedReconstructionVisitor } from "../visitors/IsSatisfiedReconstructionVisitor";

export abstract class IWorkflowFactory {
    public static voidWorkflowRelationship = { workflowButtonText: "", relationshipModelPath: "viewModel.voidNodeModel", modelValueWhenRelationshipIsTrue: undefined }
    public static sequenceRelationship = { workflowButtonText: "Next", relationshipModelPath: "viewModel.sequenceVisited", modelValueWhenRelationshipIsTrue: undefined }

    public static progressiveVoidWorkflowRelationship = { workflowButtonText: "", relationshipModelPath: "viewModel.voidNodeModel", modelValueWhenRelationshipIsTrue: true }
    public static progressiveSequenceRelationship = { workflowButtonText: "Next", relationshipModelPath: "viewModel.sequenceVisited", modelValueWhenRelationshipIsTrue: true }

    protected mpan: string;
    protected serviceLine: string;

    constructor(mpan: string, serviceLine: string) {
        this.mpan = mpan;
        this.serviceLine = serviceLine;
    }

    protected async finaliseTree(tree: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        const fetchVisitor = new FetchVisitor();
        tree.Accept(fetchVisitor);
        const isSatisfiedReconstructor = new IsSatisfiedReconstructionVisitor();
        tree.Accept(isSatisfiedReconstructor);
        await fetchVisitor.FetchAllNodeData();
    }

    public abstract Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>>;
}
