import { useModel } from "../hooks/useModel"
import { IMultiButtonField } from "../treeExtensions/FieldInterfaces"
import { Title } from "./Title"
import { ValidationResponse } from "./ValidationResponse"
import { Button } from "./Button"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

export interface props {
    title: string,
    hideTitle?: boolean,
    modelPath: string,
    buttons: IMultiButtonField[],
    mandatory: boolean,
    onSelect: (button: IMultiButtonField) => ValidationResponse | undefined
}

export const MultiButtonGroup = (props: props) => {

    const model = useModel();

    const onSelected = (indexOfSelectedItem: number) => {
        props.buttons.map((button, index) => {
            if(index != indexOfSelectedItem)
                props.buttons[index].isPrimary = false;
        })
        model.Set(props.modelPath, props.buttons[indexOfSelectedItem].modelValueWhenSelected)
        props.buttons[indexOfSelectedItem].isPrimary = true;
        props.onSelect(props.buttons[indexOfSelectedItem])
    }

    return <div>
        <Title text={props.title} className={`mt-7 ${props.hideTitle == true ? "hidden" : ""}`} />
        {props.buttons.map((button, index) => {
            return <Button primary={button.isPrimary === true} key={index} text={button.title} className={`ml-5 jbp-workflow-button jbp-workflow-button-${ConvertStringToHtmlId(props.title)}-${button.title}`} icon={false} disabled={false} onClick={() => onSelected(index)} />
        })}
    </div>
}
