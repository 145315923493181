import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { FormModal, Modal } from "../components/Modal";
import { Typography } from "../components/Typography";
import { editBookingErrorType, editBookingSuccessType, getBookingResponse200 } from "../../../APIDocs/apiTypes"
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { WorkflowView } from "./renderWorkflowView/WorkflowView";
import { EditJobWorkflowFactory } from "../factories/EditJobWorkflowFactory";
import { Observable, ObservableEvents } from "../Observable";
import { EditJobMessageFactory } from "../factories/EditJobMessageFactory";


export interface props {
    bookingId: string,
    mpan: string,
    serviceLine: string
    booking: getBookingResponse200
}

export const EditJob = (props: props) => {

    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [showUpdateWorkflow, setShowUpdateWorkflow] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [editJobData, setEditJobData] = useState(null);

    const onEditJobSuccess = () => {
        setShowLoadingModal(false);
        setShowSuccessModal(true);
    }

    const onEditJobError = () => {
        setShowLoadingModal(false);
        setShowErrorModal(true);
    }

    const { refetch: patchEditJobData, isFetching: submittingJobDataInProgress } = useJbpQuery<editBookingSuccessType, editBookingErrorType>(RestTypes.Patch, editJobData, [`${props.bookingId}-updateBooking`], `jbp/${props.mpan}/booking/${props.bookingId}`, false, props.serviceLine, onEditJobSuccess, onEditJobError);

    const modalTitle = "Edit Job";

    useEffect(() => {
        const fetchData = async () => {
            if(submittingJobDataInProgress === true) {
                setShowUpdateWorkflow(false);
                setShowLoadingModal(true);
            }
        };
        fetchData();
    }, [submittingJobDataInProgress]);

    const updateState = async (newState: any) => {
        setEditJobData(newState);
    };

    const handleJobSuccess = () => {
        setShowSuccessModal(false);
        Observable.GetObservable().emit(ObservableEvents.JobEditSuccessful);
    }

    useEffect(() => {
        const handleMessage = async () => {
            console.log("Message received, attempting to deal with edited job.");
            await updateState(EditJobMessageFactory.MakeMessage());
            await patchEditJobData();
        };

        Observable.GetObservable().observe(ObservableEvents.SubmitEditedJobForProcessing, handleMessage);

        return () => {
            Observable.RemoveObservable();
        };

    }, []);

    return (
        <div>
            {
                <Button text="Edit Job" primary={true} onClick={() => { setShowUpdateWorkflow(true) }} disabled={false} widthStyling="w-full" className="mt-2 inline-block" />
            }
            {
                showLoadingModal ?
                    <Modal title={modalTitle} >
                        <Typography content={`Please wait whilst your job update is processed`} className="mb-3 loading" />
                        <span className="pl-10"></span>
                    </Modal>
                    :
                    <span></span>
            }
            {
                showUpdateWorkflow ?
                    <FormModal title={`${modalTitle} - ${props.bookingId}`}>
                        <WorkflowView mpan={""} serviceLine={props.serviceLine} title="" workflowFactory={new EditJobWorkflowFactory(props.mpan, props.serviceLine, props.booking)} />
                        <Button text="X" primary={false} onClick={() => { setShowUpdateWorkflow(false) }} disabled={false} className="w-1/2 text-center absolute right-0 top-0" />
                    </FormModal>
                    :
                    <span></span>
            }
            {
                showSuccessModal ?
                    <Modal title={modalTitle} >
                        <Typography content={`The Job has been updated successfully`} className="mb-3" />
                        <Button primary={false} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => { handleJobSuccess() }} />
                    </Modal>
                    :
                    <span></span>
            }
            {
                showErrorModal ?
                    <Modal title={modalTitle} >
                        <Typography content={`Something went wrong, please try again later`} className="mb-3" />
                        <Button primary={false} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => setShowErrorModal(false)} />
                    </Modal>
                    :
                    <span></span>
            }
        </div>
    )
}
