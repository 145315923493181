import { useState } from "react";
import { Button } from "../components/Button";
import { TextInput } from "../components/TextInput"
import { Title } from "../components/Title";
import { ValidationResponse } from "../components/ValidationResponse";
import { Dropdown } from "../components/Dropdown";
import { useModel } from "../hooks/useModel";
import { useSynchronousState } from "../hooks/useSynchronousState";
import { getUserDetailsSuccessType, getUserDetailsErrorType, UserPermissions } from "../../../APIDocs/apiTypes";
import { useJbpQuery, RestTypes } from "../hooks/useJbpQuery";
import { Logger } from "../Logger";
import { Model } from "../Model";
import { Typography } from "../components/Typography";
import { Observable, ObservableEvents } from "../Observable";

export const Search = () => {

    const [searchDisabled, setSearchDisabled] = useState(true);
    const [dataErrorOccurred, setDataErrorOccurred] = useState(false)
    const [userPermissions, setUserPermissions] = useState<UserPermissions | undefined>(undefined)
    const mpan = useSynchronousState<string | undefined>(undefined)
    const selectedServiceLine = useSynchronousState<string | undefined>(undefined)
    const model = useModel(true);

    const onUserVerification = (userData: getUserDetailsSuccessType) => {
        Model.GetModel().Set("viewModel.fetchedData.UserTypeForCurrentUser", userData.userType);
        Model.GetModel().Set("viewModel.fetchedData.ValidMPIDSForCurrentUser", userData.mpid);
        Model.GetModel().Set("viewModel.fetchedData.UserRoleForCurrentUser", userData.role);
        Model.GetModel().Set("viewModel.fetchedData.UserPermissionsForCurrentUser", JSON.stringify(userData.permissions));
        const stringListOfMpids = userData.mpid.toString();
        sessionStorage.removeItem("userMPID");
        sessionStorage.setItem("listOfMpids", stringListOfMpids);
        sessionStorage.setItem("userTypeForCurrentUser", userData.userType);
        Observable.GetObservable().emit(ObservableEvents.UserInfoLoaded, userData.user);
        setUserPermissions(userData.permissions as UserPermissions | undefined);
    }

    const onUserVerificationError = (err: getUserDetailsErrorType) => {
        Logger.Log(`Error occurred validating the user details -> ${err?.msg}`)
        setDataErrorOccurred(true);
    }
    const { isLoading: userDataLoading, data } = useJbpQuery<getUserDetailsSuccessType, getUserDetailsErrorType>(RestTypes.Get, undefined, ['user-info'], 'jbp/userInfo', true, "", onUserVerification, onUserVerificationError)

    type serviceLine = {
        code: string,
        description: string,
        contractType: string,
        active: boolean | undefined
    }

    const serviceLines: Array<serviceLine> = [
        {
            code: "HH",
            description: "Half Hourly",
            contractType: "8",
            active: userPermissions?.canUseHHServiceLine
        },
        {
            code: "NHH",
            description: "Non Half Hourly",
            contractType: "2",
            active: userPermissions?.canUseNHHServiceLine
        },
        {
            code: "SUBMETER",
            description: "Sub Metering",
            contractType: "4",
            active: userPermissions?.canUseSubmeterServiceLine
        },
        {
            code: "DNO",
            description: "DNO",
            contractType: "100",
            active: userPermissions?.canUseDNOServiceLine
        },
        {
            code: "BNO",
            description: "BNO",
            contractType: "101",
            active: userPermissions?.canUseBNOServiceLine
        }
    ]

    const filteredServiceLines = serviceLines.filter(sl => sl.active === true);
    model.Set("model.dataFieldsSource.availableServiceLines", filteredServiceLines.map(sl => sl.description))

    const setDisabledButtonFlag = () => {
        const searchButtonShouldBeDisabled = mpan.get() == undefined || selectedServiceLine.get() == undefined
        setSearchDisabled(searchButtonShouldBeDisabled)
    }

    const onMpanBlur = (value: string): ValidationResponse | undefined => {
        const mpanRegex = /^[0-9]{13}$/;

        if(mpanRegex.test(value.trim())) {
            mpan.set(value.trim());
            setDisabledButtonFlag();
            return { isValid: true };
        }
        else {
            mpan.set(undefined)
            setDisabledButtonFlag();
            return { isValid: false, errorMessage: "The MPAN is invalid, it should contain 13 digits" };
        }
    }

    const onServiceLineSelected = (index: number): ValidationResponse | undefined => {
        selectedServiceLine.set(filteredServiceLines[index].code);
        model.Set("model.selectedContractType", serviceLines[index].contractType);
        setDisabledButtonFlag();
        return { isValid: true }
    }

    const searchClicked = () => {
        window.location.pathname = `/site/${mpan.get()}/${selectedServiceLine.get()}`
    }

    return (
        <div id="jbp-search-screen">
            <div className='w-1/2 inline-block align-top'>
                <div className='ml-10'>
                    <Title className="mt-7" text="Welcome to the Siemens Job Booking Portal" />

                    {userDataLoading ? <Typography content="Please wait whilst we verify your user credentials" /> : <span></span>}
                    {dataErrorOccurred ? <Typography content="We could not validate your user credentials, please contact support." /> : <span></span>}

                    {!data ? <span></span> :
                        <div>
                            <TextInput title="MPAN" placeholder="Enter your site MPAN" onBlur={onMpanBlur} />
                            <Dropdown title="Service Line" mandatory={false} placeholder="Please select the service line" elementsDataSource="model.dataFieldsSource.availableServiceLines" onSelect={onServiceLineSelected} modelPath="model.selectedServiceLine" />
                            <Button primary={true} text="Search" icon={true} disabled={searchDisabled} onClick={searchClicked} />
                        </div>
                    }
                </div>
            </div>
            <div className="inline-block w-1/2 h-screen bg-center bg-origin-content bg-cover" style={{ backgroundImage: "url(/searchImagery.jpeg)" }}></div>
        </div>
    )
}
