import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { IWorkflowFactory } from "../factories/IWorkflowFactory";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, ITextField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { getBookingResponse200 } from "../../../APIDocs/apiTypes";
import { Observable, ObservableEvents } from "../Observable";

export class EditJobCommentsNode extends CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    constructor(bookingData: getBookingResponse200) {
        const notifyEditJobComponentOfJobSubmission = () => {
            Observable.GetObservable().emit(ObservableEvents.SubmitEditedJobForProcessing);
        }

        const relationship = {
            workflowButtonText: "Submit Updated Job",
            relationshipModelPath: IWorkflowFactory.sequenceRelationship.relationshipModelPath,
            modelValueWhenRelationshipIsTrue: IWorkflowFactory.sequenceRelationship.modelValueWhenRelationshipIsTrue,
            triggerFunctionWhenNoErrorsOnSelected: notifyEditJobComponentOfJobSubmission,
        } as IJbpChildNodeRelationship

        const commentsField = {
            fieldType: FieldType.TextArea,
            title: "Additional Comments",
            placeHolder: "Advise of any parking or access instructions/ restrictions, site directions, meter location information, health & safety concerns (e.g. face mask), and any other access information",
            modelPath: "model.results.jobComments",
            initialValue: bookingData.JobComments,
            isValid: (_value: string) => {
                return {
                    isValid: true
                }
            },
            getDynamicFieldConstraints: () => {
                const jobCommentsStandardLength = 2000;
                return {
                    maxLength: jobCommentsStandardLength
                }
            }
        } as ITextField;

        super("jobComments", new JbpTreeNodeData("Please enter any further information or instructions relating to this job", [commentsField]), relationship);
    }
}
