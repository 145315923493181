import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class UndoModelRelationshipVisitor extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    public override Visit(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>): void {
        const modelPath = parent?.getRelativeRelationship(node)?.relationshipModelPath;
        if(modelPath != undefined) {
            Model.GetModel().ClearModelValue(modelPath);
        }
    }
}
