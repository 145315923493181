import { DateTime } from "luxon"
import React, { ChangeEvent } from "react"
import { useState } from "react"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

enum validStates {
    uninitialised,
    invalid,
    valid
}

export interface props {
    title: string,
    mandatory?: boolean,
    onDateSelected: (date: DateTime | undefined) => void,
    className?: string
}

const setupDays = (aroundDate: DateTime) => {
    let iterator = DateTime.fromObject({ day: 1, month: aroundDate.month, year: aroundDate.year });
    iterator = iterator.minus({ days: iterator.weekday - 1 });

    let lastDayInDisplay = DateTime.fromObject({ day: aroundDate.daysInMonth, month: aroundDate.month, year: aroundDate.year })
    lastDayInDisplay = lastDayInDisplay.plus({ days: 7 - lastDayInDisplay.weekday });

    const iteratorNotPastFinalWeek = () => iterator <= lastDayInDisplay
    const days: DateTime[][] = [];

    while(iteratorNotPastFinalWeek()) {
        const newWeek = [];
        for(let i = 0; i < 7; ++i) {
            newWeek.push(iterator);
            iterator = iterator.plus({ days: 1 });
        }
        days.push(newWeek)
    }

    return days
}

export const DatePicker = (props: props) => {

    const [displayPicker, setDisplayPicker] = useState(false);
    const inputRef = React.createRef<HTMLInputElement>();
    const [viewportDate, setViewportDate] = useState(DateTime.now())
    const [selectedDate, setSelectedDate] = useState(DateTime.now())
    const [days, setDays] = useState(setupDays(selectedDate))
    const [valid, setValid] = useState(validStates.uninitialised);
    const [errorMessage, setErrorMessage] = useState("");

    const viewLastMonth = () => {
        const nextDate = viewportDate.minus({ month: 1 });
        setViewportDate(nextDate);
        setDays(setupDays(nextDate));
    }

    const viewNextMonth = () => {
        const nextDate = viewportDate.plus({ month: 1 });
        setViewportDate(nextDate);
        setDays(setupDays(nextDate));
    }

    const handleClickDate = (date: DateTime) => {
        setSelectedDate(date);
        setDisplayPicker(false);
        const dateString = date.toFormat('dd/MM/yyyy')
        setValid(validStates.valid);
        setErrorMessage("");
        if(inputRef.current != undefined) inputRef.current.value = dateString;
        props.onDateSelected(date);
    }

    const onClickInput = () => {
        setFocus();
        setDisplayPicker(!displayPicker);
    }

    const textInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validDateRegex = /\d{1,2}\/\d{1,2}\/\d{4}/;
        if(validDateRegex.test(value)) {
            const newDate = DateTime.fromFormat(value, "d/MM/yyyy");
            if(newDate.isValid) {
                setSelectedDate(newDate);
                setViewportDate(newDate)
                setDays(setupDays(newDate))
                setValid(validStates.valid);
                setErrorMessage(``);
                props.onDateSelected(newDate);
            }
            else {
                setValid(validStates.invalid);
                setErrorMessage(`Please enter a date as DD/MM/YYYY`);
                props.onDateSelected(undefined);
            }
        }
        else {
            setValid(validStates.invalid);
            setErrorMessage(`Please enter a date as DD/MM/YYYY`);
            props.onDateSelected(undefined);
        }
    }

    const headerControl = () => (
        <div>
            <div className="h-[76px] border-elevation-0 border-b-[1px] text-center pt-6 text-interactive-coral font-semibold text-sm">
                <img src="/datePickerLess.png" className="cursor-pointer inline-block mr-14 datepicker-previous-month" onClick={viewLastMonth}></img>
                <div className={`inline-block w-1/3 datepicker-month-${viewportDate.monthLong}`}>{`${viewportDate.monthLong} ${viewportDate.year}`}</div>
                <img src="/datePickerMore.png" className="cursor-pointer inline-block ml-14 datepicker-next-month" onClick={viewNextMonth}></img>
            </div>
        </div>
    )

    const daysOfTheWeekHeaders = () => (
        <div className="text-xs font-bold text-center pt-6 pl-12 pr-12">
            <div className="inline-block w-1/7">Mon</div>
            <div className="inline-block w-1/7">Tue</div>
            <div className="inline-block w-1/7">Wed</div>
            <div className="inline-block w-1/7">Thu</div>
            <div className="inline-block w-1/7">Fri</div>
            <div className="inline-block w-1/7">Sat</div>
            <div className="inline-block w-1/7">Sun</div>
        </div>
    )
    const datesAreEqual = (l: DateTime, r: DateTime) => l.day === r.day && l.month === r.month && l.year === r.year;

    const dayEntries = () => (
        <div className="text-xs font-bold text-center pl-12 pr-12 pt-4">
            {days.map((week: DateTime[], index: number) => {
                return (
                    <div key={`jbp-date-picker-week-${index}`} className="text-interactive-coral font-thin">
                        {week.map((date: DateTime, index: number) => {
                            const isToday = datesAreEqual(date, DateTime.now());
                            const isSelectedDate = datesAreEqual(date, selectedDate);
                            const jbpClass = `cursor-pointer inline-block pt-[2px] w-1/7 h-8 font-thin ${isSelectedDate ? "bg-solid-bold-green font-black text-elevation-0 datepicker-selected-date" : "hover:bg-elevation-2"} ${date.month != viewportDate.month ? "text-secondary-deep-blue-40" : `datepicker-day-${date.day}`}`;
                            return (
                                <div onClick={() => handleClickDate(date)} key={`jbp-date-picker-date-${index}`} className={jbpClass}>
                                    <div className={`h-7 rounded-3xl m-2 mt-0 border-solid-white pt-[5px] ${isToday && isSelectedDate === false ? "border-[1px]" : ""}`}>
                                        {date.day}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )

    const setFocus = () => {
        inputRef?.current?.focus();
    }

    const fieldIsInvalid = valid == validStates.invalid;

    const backgroundColour = fieldIsInvalid ? "bg-feedback-dark-red" : "bg-interactive-bold-green-db-12";
    const borderColour = fieldIsInvalid ? "border-b-feedback-red" : "border-b-solid-bold-green";
    const labelTextColour = fieldIsInvalid ? "text-feedback-red" : "text-solid-bold-green";

    const errorExists = () => errorMessage != undefined && errorMessage != ""

    const errorControl = () => errorExists() ? <label className={`text-feedback-red text-xs jbp-text-field-validation-message-${ConvertStringToHtmlId(props.title)}`}>{errorMessage}</label> : <br />


    return (
        <div className={`min-w-[30em] w-fit ${props.className}`}>
            <div className={`mb-1 min-w-[30em] w-fit mt-2`}>
                <div className={`cursor-text ${backgroundColour} ${borderColour} pl-3 border-b-2 pt-2 pb-2 text-solid-white placeholder-shown:text-secondary-deep-blue-30`} onClick={onClickInput}>
                    <label className={`jbp-text-input-label cursor-text text-xs ${labelTextColour} block`}>{props.mandatory == true ? '*' : ''}{props.title}</label>
                    <input ref={inputRef} id={`jbp-text-field-${ConvertStringToHtmlId(props.title)}`} className={`jbp-text-field ${backgroundColour} focus:outline-none w-full`} type="text" placeholder="DD/MM/YYYY" onChange={textInputOnChange} />
                </div>
                <div className={`absolute w-[25em] pb-8 bg-elevation-1 ${displayPicker ? "" : "hidden"}`}>
                    {headerControl()}
                    {daysOfTheWeekHeaders()}
                    {dayEntries()}
                </div>
                <div>
                    {errorControl()}
                </div>
            </div>
        </div>
    )
}
