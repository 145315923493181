import { useState } from "react"
import { useModel } from "../hooks/useModel"
import { IRadioButtonField } from "../treeExtensions/FieldInterfaces"
import { RadioButton } from "./RadioButton"
import { Title } from "./Title"
import { ValidationResponse } from "./ValidationResponse"

export interface props {
    title: string,
    hideTitle?: boolean,
    modelPath: string,
    buttons: IRadioButtonField[],
    mandatory: boolean,
    onSelect: (button: IRadioButtonField) => ValidationResponse | undefined
}

export const RadioButtonGroup = (props: props) => {

    const model = useModel();

    const getInitialValue = () => {
        const previousData = model.Get(props.modelPath);
        let previousIndex: number | undefined = undefined;
        if(previousData != undefined) {
            props.buttons.forEach((b, index) => {
                if(b.title === previousData) {
                    previousIndex = index;
                }
            })
        }
        return previousIndex;
    }

    const [expandedStates, setExpandedStates] = useState(props.buttons.map(() => false))
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(getInitialValue());

    const onExpanderClicked = (index: number) => {
        const newExpandedStates = Array.from(expandedStates);
        newExpandedStates[index] = !newExpandedStates[index];
        newExpandedStates.forEach((e, i) => {
            if(i != index) newExpandedStates[i] = false;
        })
        setExpandedStates(newExpandedStates)
    }

    const onSelected = (index: number) => {
        model.Set(props.modelPath, props.buttons[index].title)
        setSelectedIndex(index)
        props.onSelect(props.buttons[index])
    }

    return <div>
        <Title text={props.title} className={`mt-7 ${props.hideTitle == true ? "hidden" : ""}`} />
        {props.buttons.map((button, index) => {
            const expandedState = (index == selectedIndex === true) || (expandedStates[index] === true)

            return <RadioButton checked={index == selectedIndex} expanded={expandedState} key={index} title={button.title} description={button.description} group={props.title} onExpanderClicked={() => onExpanderClicked(index)} onSelected={() => onSelected(index)} />
        })}
    </div>
}
