// ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>("siteInductionNode", new JbpTreeNodeData("Is a site induction required (for the field operative)?", []))

import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const siteInductionPrependedMessagePath = "model.jobCommentPrependedMessages.siteInductionNotRequired";

class SiteInductionTreeNodeData extends JbpTreeNodeData {
    constructor(title: string, fields: IField[]) {
        super(title, fields);
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(siteInductionPrependedMessagePath)
    }
}

export class SiteInductionNode extends ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>{

    public static SiteInductionNeededHandler() {
        Model.GetModel().Set(siteInductionPrependedMessagePath, "")
    }
    public static SiteInductionNotNeededHandler() {
        Model.GetModel().Set(siteInductionPrependedMessagePath, "Induction: No\n")
    }

    constructor() {
        super("siteInductionNode", new SiteInductionTreeNodeData("Is a site induction required (for the field operative)?", []))
    }
}
