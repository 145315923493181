import { StandingdataDNOBNOResponse } from "../../../APIDocs/apiTypes";
import { EnvironmentVariables } from "../EnvironmentVariables";
import { Model } from "../Model";
import { JbpTreeNodeData, fetchHandler } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IDropdownField, IEditableLabelField, IField, ITextField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { getSupplierIdForHeader } from "../../src/hooks/useJbpQuery";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";


class DNOBNOSpecificQuestionsNodes extends JbpTreeNodeData {
    constructor(title: string, fields: IField[], fetchFunction: fetchHandler) {
        super(title, fields, fetchFunction);
    }
}

const hvMeteringQuestionOptions = ["Yes", "No"];
const customerOwnedSwitchQuestionOptions = ["Yes", "No", "Unknown"];


export class DNOBNOQuestions extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    constructor() {

        const fetchStandingdataDNOBNOResponses = () => {
            return new Promise<void>((res, rej) => {

                const contractType = Model.GetModel().Get('model.selectedContractType');
                const path = `${EnvironmentVariables.ApiUrl}/jbp/standingData/dnoBnoDropdowns`
                const MPID = getSupplierIdForHeader("")

                console.log(`Querying ${path}`)

                fetch(path, {
                    headers: {
                        "Contract-Type": contractType,
                        "MPID": MPID
                    },
                    credentials: 'include'
                })
                    .then((responseData) => responseData.json())
                    .then((dnoBnoRes: StandingdataDNOBNOResponse) => {
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.ctRatio", dnoBnoRes.ctRatio);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.vtRatio", dnoBnoRes.vtRatio);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.serviceVoltage", dnoBnoRes.serviceVoltage);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.fuseSize", dnoBnoRes.fuseSize);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.meterPointVoltage", dnoBnoRes.mtrPointVoltage);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.serviceRating", dnoBnoRes.serviceRating);

                        //Non standing data fields.
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.hvMetering", hvMeteringQuestionOptions);
                        Model.GetModel().Set("model.dataFieldsSource.dnoBnoStandingData.customerOwnedSwitch", customerOwnedSwitchQuestionOptions);

                        res();
                    })
                    .catch(rej);
            })
        }

        const exportCapacityField = {
            fieldType: FieldType.EditableLabelField,
            title: "*Export Capacity",
            placeHolder: "Please enter a export capacity value",
            modelPath: "model.results.dnoBnoSiteInformation.exportCapacity",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valida export capacity value."
                }
            }
        } as IEditableLabelField;

        const importCapacityField = {
            fieldType: FieldType.EditableLabelField,
            title: "Import Capacity",
            placeHolder: "Please enter a import capacity value",
            modelPath: "model.results.dnoBnoSiteInformation.importCapacity",
            mandatory: false,
            isValid: (value: string) => {
                const regex = /^[\w\W]{0,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valida import capacity value."
                }
            }
        } as ITextField

        const serviceVoltageField = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.serviceVoltage",
            title: "Service Voltage",
            placeHolder: "Please select a Service Voltage",
            modelPath: "model.results.dnoBnoSiteInformation.serviceVoltage",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a service voltage"
                }
            }
        } as IDropdownField

        const meterPointVoltageField = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.meterPointVoltage",
            title: "Meter Point Voltage",
            placeHolder: "Please select a Meter Point Voltage",
            modelPath: "model.results.dnoBnoSiteInformation.meterPointVoltage",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a meter point voltage"
                }
            }
        } as IDropdownField

        const hvMetering = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.hvMetering",
            title: "HV Metering",
            placeHolder: "Please select a HV Metering",
            modelPath: "model.results.dnoBnoSiteInformation.hvMetering",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a HV Metering"
                }
            }
        } as IDropdownField

        const ctRatio = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.ctRatio",
            title: "CT Ratio",
            placeHolder: "Please select a CT Ratio",
            modelPath: "model.results.dnoBnoSiteInformation.ctRatio",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a CT Ratio"
                }
            }
        } as IDropdownField

        const vtRatio = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.vtRatio",
            title: "VT Ratio",
            placeHolder: "Please select a VT Ratio",
            modelPath: "model.results.dnoBnoSiteInformation.vtRatio",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a VT Ratio"
                }
            }
        } as IDropdownField

        const serviceRatingField = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.serviceRating",
            title: "Service Rating",
            placeHolder: "Please select a Service Rating",
            modelPath: "model.results.dnoBnoSiteInformation.serviceRating",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a Service Rating"
                }
            }
        } as IDropdownField

        const fuseSize = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.fuseSize",
            title: "Fuse Size",
            placeHolder: "Please select a Fuse Size",
            modelPath: "model.results.dnoBnoSiteInformation.fuseSize",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a Fuse Size"
                }
            }
        } as IDropdownField

        const switchCustomerOwnedField = {
            fieldType: FieldType.DropDown,
            elementsModelPath: "model.dataFieldsSource.dnoBnoStandingData.customerOwnedSwitch",
            title: "Customer Owned Switch",
            placeHolder: "Please select a Customer Owned Switch",
            modelPath: "model.results.dnoBnoSiteInformation.customerOwnedSwitch",
            mandatory: true,
            isValid: (value: string) => {
                const passes = value.length > 0;
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a Customer Owned Switch"
                }
            }
        } as IDropdownField

        const poNumber = {
            fieldType: FieldType.EditableLabelField,
            title: "PO Number",
            placeHolder: "Please enter a PO Number",
            modelPath: "model.results.dnoBnoSiteInformation.poNumber",
            mandatory: false,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please enter a valid PO Number"
                }
            }
        } as ITextField

        super("selectJobType", new DNOBNOSpecificQuestionsNodes("Please enter DNO/BNO Specific information required for this job booking",
            [exportCapacityField, importCapacityField, serviceVoltageField, meterPointVoltageField, hvMetering, ctRatio, vtRatio, serviceRatingField, fuseSize, switchCustomerOwnedField, poNumber],
            fetchStandingdataDNOBNOResponses));
    }
}
