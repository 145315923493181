import { TreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { CompleteBookingNode } from "../nodes/CompleteBookingNode";
import { ConfirmationSMSNode } from "../nodes/ConfirmationSMSNode";
import { ConfirmationSMSRequiredNode } from "../nodes/ConfirmationSMSRequiredNode";
import { ReminderSMSRequiredNode } from "../nodes/ReminderSMSRequiredNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./IWorkflowFactory";
import { BookJobCommunicationsHandler } from "../relationshipHandlers/BookJobCommunicationsHandler";

export class BookJobCommunicationsWorkflowFactory extends IWorkflowFactory {

    constructor(mpan: string, serviceLine: string) {
        super(mpan, serviceLine);
    }

    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);

        const confirmationSmsRequiredNode = new ConfirmationSMSRequiredNode();
        const reminderSmsRequiredNode = new ReminderSMSRequiredNode();
        const completeBookingNode = new CompleteBookingNode(this.mpan, this.serviceLine);
        const confirmationSMSNode = new ConfirmationSMSNode();

        const smsBuilder = builder
            .AddTreeNode(confirmationSmsRequiredNode, IWorkflowFactory.progressiveVoidWorkflowRelationship)
            .AddTreeNode(reminderSmsRequiredNode, IWorkflowFactory.progressiveVoidWorkflowRelationship, () => BookJobCommunicationsHandler.confirmationSmsRequiredCompleted() === true)

        smsBuilder
            .AddTreeNode(completeBookingNode, IWorkflowFactory.progressiveSequenceRelationship, () => BookJobCommunicationsHandler.showCompleteWhereNoConfirmationsRequired() == true)

        smsBuilder
            .AddTreeNode(confirmationSMSNode, IWorkflowFactory.progressiveSequenceRelationship, () => BookJobCommunicationsHandler.shouldOnlyShowSmsInput() == true)
            .AddTreeNode(completeBookingNode, IWorkflowFactory.progressiveSequenceRelationship, () => BookJobCommunicationsHandler.showCompleteForBothInputs() == true)

        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }
}
