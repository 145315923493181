import { Button } from "../components/Button"
import { IChildNodeStructure, ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode"
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData"
import { ConditionalChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ConditionalChoiceNode"
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship"
import { useModel } from "../hooks/useModel"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

export interface props {
    node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>,
    mpan: string,
    disabled: boolean,
    buttonConditionResults?: boolean[],
    nodeSelected: (node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>) => void
}

export const WorkflowButtons = (props: props) => {

    const model = useModel();

    const handleButtonClick = (child: IChildNodeStructure<JbpTreeNodeData, IJbpChildNodeRelationship>) => {
        if(child.relationship.relationshipModelPath != undefined) {
            model.Set(child.relationship.relationshipModelPath, child.relationship.modelValueWhenRelationshipIsTrue)
        }
        if(child.relationship.onSelected != undefined) {
            child.relationship.onSelected();
        }
        props.nodeSelected(child.node);
    }

    return (
        <div className="jbp-workflow-btns">
            {
                props.node.Children().map((child, index) => {
                    const isConditionalChoiceNode = props.node instanceof ConditionalChoiceNode;
                    if(isConditionalChoiceNode) {
                        if(props.buttonConditionResults == undefined) {
                            throw 'Conditional nodes must have buttonConditionResults on props';
                        }
                        if(props.buttonConditionResults?.length != props.node.Children().length) {
                            throw 'Conditional nodes must have equal buttonConditionResults and children';
                        }
                        if(props.buttonConditionResults[index] === false) {
                            return <span key={index}></span>
                        }
                    }
                    let primary = false;
                    if(child.relationship.relationshipModelPath != undefined) {
                        if(model.Get(child.relationship.relationshipModelPath) === child.relationship.modelValueWhenRelationshipIsTrue) {
                            primary = true;
                        }
                    }
                    return <Button primary={primary} icon={false} className={`mr-5 jbp-workflow-button jbp-workflow-button-${ConvertStringToHtmlId(props.node.Data().Title())}`} key={index} text={child.relationship.workflowButtonText} disabled={props.disabled} onClick={() => handleButtonClick(child)} />
                })
            }
        </div>
    )
}
