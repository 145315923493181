import { ITreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/ITreeNodeBuilder";
import { TreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ExclusiveChoiceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ExclusiveChoiceNode";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { Model } from "../Model";
import { CaptureAccessInstructionsNode } from "../nodes/CaptureAccessInstructionsNode";
import { WhatThreeWordsCommentsNode } from "../nodes/WhatThreeWordsCommentsNode";
import { CaptureParkingInfo } from "../nodes/CaptureParkingInfo";
import { EmailForRamsNode } from "../nodes/EmailForRamsNode";
import { JobCommentsNode } from "../nodes/JobCommentsNode";
import { LiftOnSiteNode } from "../nodes/LiftOnSiteNode";
import { MeterAccessibleNode } from "../nodes/MeterAccessibleNode";
import { PowerDownNode } from "../nodes/PowerDownNode";
import { SiteAddressDetailsNode } from "../nodes/SiteAddressDetailsNode";
import { SiteContactDetailsNode } from "../nodes/SiteContactDetailsNode";
import { SiteInductionNode } from "../nodes/SiteInductionNode";
import { TerminateBookJobNode } from "../nodes/TerminateBookJobNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./IWorkflowFactory";

export class GenericBookJobSiteInfoWorkflowFactory extends IWorkflowFactory {

    private powerDownNode = new PowerDownNode();
    private cannotPowerDownNode = new TerminateBookJobNode("cannotPowerDown");
    private liftOnSiteNode = new LiftOnSiteNode();
    private liftOnSiteMessageNode = new InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>("liftOnSiteMessageNode", new JbpTreeNodeData("Please be advised that the lift will need to be identifiable as out of use for the duration of the job", []));
    private siteInductionNode = new SiteInductionNode();
    private siteInductionNeeded = new TerminateBookJobNode("siteInductionNeeded");
    private parkingAvailableNode = new ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>("parkingAvailable", new JbpTreeNodeData("Is parking available?", []));
    private parkingNeededNode = new TerminateBookJobNode("parkingNeeded");
    private captureAccessInstructions = new CaptureAccessInstructionsNode();
    private whatThreeWordsCommentsNode = new WhatThreeWordsCommentsNode();
    private captureParkingInfo = new CaptureParkingInfo();
    private meterAccessible = new MeterAccessibleNode();
    private meterNotAccessible = new TerminateBookJobNode("meterNotAccessible");
    private ramsRequiredNode = new ExclusiveChoiceNode<JbpTreeNodeData, IJbpChildNodeRelationship>("ramsRequired", new JbpTreeNodeData("Are risk assessments or method statements required (RAMS)?", []));
    private emailForRamsNode = new EmailForRamsNode();
    private updateToSiteAndAddressDetailsMessageNode = new InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>("updateToSiteAndAddressDetailsMessageNode", new JbpTreeNodeData("Please be advised updates to address details below will not update the details held in our portfolio. To do so please submit a D0302 flow file", []));
    private siteContactDetailsNode = new SiteContactDetailsNode();
    private siteAddressDetailsNode = new SiteAddressDetailsNode();
    private jobCommentsNode = new JobCommentsNode(this.mpan, this.serviceLine);

    constructor(mpan: string, serviceLine: string) {
        super(mpan, serviceLine);
    }

    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);

        const siteInductionBuilder = this.generateInitialTree(builder);

        const parkingAvailableBuilder = siteInductionBuilder.AddTreeNode(this.parkingAvailableNode, { workflowButtonText: "No", relationshipModelPath: "model.results.siteInductionNeeded", modelValueWhenRelationshipIsTrue: false, onSelected: SiteInductionNode.SiteInductionNotNeededHandler });

        siteInductionBuilder.AddTreeNode(this.siteInductionNeeded, { workflowButtonText: "Yes", relationshipModelPath: "model.results.siteInductionNeeded", modelValueWhenRelationshipIsTrue: true, onSelected: SiteInductionNode.SiteInductionNeededHandler });

        parkingAvailableBuilder.AddTreeNode(this.parkingNeededNode, { workflowButtonText: "No", relationshipModelPath: "model.results.parkingAvailable", modelValueWhenRelationshipIsTrue: false });

        const meterAccessibleBuilder = parkingAvailableBuilder
            .AddTreeNode(this.captureAccessInstructions, { workflowButtonText: "Yes", relationshipModelPath: "model.results.parkingAvailable", modelValueWhenRelationshipIsTrue: true })
            .AddTreeNode(this.captureParkingInfo, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.whatThreeWordsCommentsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.meterAccessible, IWorkflowFactory.sequenceRelationship);

        meterAccessibleBuilder
            .AddTreeNode(this.meterNotAccessible, { workflowButtonText: "No", relationshipModelPath: "model.results.meterAccessible", modelValueWhenRelationshipIsTrue: false });

        const ramsRequiredBuilder = meterAccessibleBuilder.AddTreeNode(this.ramsRequiredNode, { workflowButtonText: "Yes", relationshipModelPath: "model.results.meterAccessible", modelValueWhenRelationshipIsTrue: true });

        ramsRequiredBuilder
            .AddTreeNode(this.siteContactDetailsNode, { workflowButtonText: "No", relationshipModelPath: "model.results.ramsRequired", modelValueWhenRelationshipIsTrue: false });

        ramsRequiredBuilder
            .AddTreeNode(this.emailForRamsNode, { workflowButtonText: "Yes", relationshipModelPath: "model.results.ramsRequired", modelValueWhenRelationshipIsTrue: true })
            .AddTreeNode(this.siteContactDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.updateToSiteAndAddressDetailsMessageNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.siteAddressDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.jobCommentsNode, IWorkflowFactory.sequenceRelationship);

        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }

    private generateInitialTree(builder: TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>) {
        let siteInductionBuilder: ITreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship> | undefined = undefined;

        const isWc = Model.GetModel().Get("viewModel.isWCJob");
        const selectedJobType: string = Model.GetModel().Get('model.results.jobTypeCategoryTitle');

        const shouldShowPowerRelatedQuestionsBasedOnJobType = (): boolean => {
            const listOfPowerRelatedJobTypes: string[] = ["Downgrade", "Upgrade", "Change of Measurement Class"];

            const exclusiveJobTypesThatRequireTheSiteTypeBeWholeCurrent: string[] = ["Check Meter Installation", "Meter Exchange / Replacement"];

            if(listOfPowerRelatedJobTypes.includes(selectedJobType)) {
                return true;
            } else if(exclusiveJobTypesThatRequireTheSiteTypeBeWholeCurrent.includes(selectedJobType) && isWc === true) {
                return true;
            } else {
                return false;
            }
        }

        if(shouldShowPowerRelatedQuestionsBasedOnJobType()) {
            const powerDownBuilder = builder.AddTreeNode(this.powerDownNode, IWorkflowFactory.voidWorkflowRelationship);

            powerDownBuilder.AddTreeNode(this.cannotPowerDownNode, { workflowButtonText: "No", relationshipModelPath: "model.results.powerDown", modelValueWhenRelationshipIsTrue: false, onSelected: PowerDownNode.PowerDownNotSelectedHandler });

            const liftOnSiteBuilder = powerDownBuilder.AddTreeNode(this.liftOnSiteNode, { workflowButtonText: "Yes", relationshipModelPath: "model.results.powerDown", modelValueWhenRelationshipIsTrue: true, onSelected: PowerDownNode.PowerDownSelectedHandler });

            siteInductionBuilder = liftOnSiteBuilder.AddTreeNode(this.siteInductionNode, { workflowButtonText: "No", relationshipModelPath: "model.results.liftOnSite", modelValueWhenRelationshipIsTrue: false, onSelected: LiftOnSiteNode.LiftOnSiteNotSelectedHandler });
            liftOnSiteBuilder
                .AddTreeNode(this.liftOnSiteMessageNode, { workflowButtonText: "Yes", relationshipModelPath: "model.results.liftOnSite", modelValueWhenRelationshipIsTrue: true, onSelected: LiftOnSiteNode.LiftOnSiteSelectedHandler })
                .AddTreeNode(this.siteInductionNode, IWorkflowFactory.sequenceRelationship);
        }
        else {
            siteInductionBuilder = builder.AddTreeNode(this.siteInductionNode, IWorkflowFactory.voidWorkflowRelationship);
        }
        return siteInductionBuilder;
    }
}
