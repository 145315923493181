import { ITreeNode } from "./ITreeNode";

export class CompleteNode<T, C> extends ITreeNode<T, C> {
    private relationship: C;
    constructor(identifier: string, data: T, relationship: C) {
        super(identifier, data);
        this.relationship = relationship;
    }

    public Relationship() {
        return this.relationship;
    }
}
