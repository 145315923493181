import { TerminateNode } from "../../../../../libs/ts/WorkflowTree/Nodes/TerminateNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IExternalLink } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class TerminateBookJobNode extends TerminateNode<JbpTreeNodeData, IJbpChildNodeRelationship> {
    private static genericBookingError = "Sorry, it appears that you may need a member of Siemens to assist with this booking. Please use the following link to the Query Portal";

    constructor(linkedNode: string) {

        const linkField = {
            fieldType: FieldType.ExternalLink,
            displayText: "Siemens Query Portal",
            link: "https://queryportal.siemensmetering.co.uk"
        } as IExternalLink

        super(linkedNode, new JbpTreeNodeData(TerminateBookJobNode.genericBookingError, [linkField]));
    }
}
