import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class SiteAddressDetailsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    constructor() {

        const SiteName = {
            fieldType: FieldType.EditableLabelField,
            title: "Site Name",
            placeHolder: "Please enter a siteName",
            modelPath: "model.results.siteAddressDetails.siteName",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure you have entered a Site Name"
                }
            }
        } as IEditableLabelField;

        const AddressLine1 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 1",
            placeHolder: "Enter Address Line 1",
            modelPath: "model.results.siteAddressDetails.line1",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const AddressLine2 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 2",
            placeHolder: "Enter Address Line 2",
            modelPath: "model.results.siteAddressDetails.line2",
            isValid: (value: string) => {
                const regex = /^[\w\W]{0,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const AddressLine3 = {
            fieldType: FieldType.EditableLabelField,
            title: "Address Line 3",
            placeHolder: "Enter Address Line 3",
            modelPath: "model.results.siteAddressDetails.line3",
            isValid: (value: string) => {
                const regex = /^[\w\W]{0,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid address line"
                }
            }
        } as IEditableLabelField

        const TownField = {
            fieldType: FieldType.EditableLabelField,
            title: "Town",
            placeHolder: "Please enter the town where the site is located",
            modelPath: "model.results.siteAddressDetails.town",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid town."
                }
            }
        } as IEditableLabelField;

        const CountyField = {
            fieldType: FieldType.EditableLabelField,
            title: "County",
            placeHolder: "Please enter the county where the site is located",
            modelPath: "model.results.siteAddressDetails.county",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure your county is valid"
                }
            }
        } as IEditableLabelField;

        const PostcodeField = {
            fieldType: FieldType.EditableLabelField,
            title: "Postcode",
            placeHolder: "Please enter the postcode of the site",
            modelPath: "model.results.siteAddressDetails.postCode",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^([A-PR-UWYZ]([0-9]([0-9]|[A-HJKPSTUW])?|[A-HK-Y][0-9]([0-9]|[ABEHMNPRVWXY])?) ?[0-9][ABD-HJLNP-UW-Z]{2})$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure your post code is valid"
                }
            }
        } as IEditableLabelField;


        super("siteContactDetails", new JbpTreeNodeData("Please confirm the site address details", [SiteName, AddressLine1, AddressLine2, AddressLine3, TownField, CountyField, PostcodeField]));
    }

    public override onInitialise(): void {
        const fetchedFieldMappings = [
            { fetched: "viewModel.fetchedData.siteAddress.siteName", result: "model.results.siteAddressDetails.siteName" },
            { fetched: "viewModel.fetchedData.siteAddress.line1", result: "model.results.siteAddressDetails.line1" },
            { fetched: "viewModel.fetchedData.siteAddress.line2", result: "model.results.siteAddressDetails.line2" },
            { fetched: "viewModel.fetchedData.siteAddress.line3", result: "model.results.siteAddressDetails.line3" },
            { fetched: "viewModel.fetchedData.siteAddress.town", result: "model.results.siteAddressDetails.town" },
            { fetched: "viewModel.fetchedData.siteAddress.county", result: "model.results.siteAddressDetails.county" },
            { fetched: "viewModel.fetchedData.siteAddress.postCode", result: "model.results.siteAddressDetails.postCode" }
        ]

        fetchedFieldMappings.forEach((mapping) => {
            if(Model.GetModel().Get(mapping.result) == undefined) {
                const fetchedValue = Model.GetModel().Get(mapping.fetched);
                if(fetchedValue != undefined) Model.GetModel().Set(mapping.result, fetchedValue)
            }
        })
    }
}
