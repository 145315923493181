import { useIsEnv } from "../../src/hooks/useIsEnv";
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

export interface props {
    title: string,
    text: string | undefined,
    className?: string,
    displayEditableIcon?: boolean,
    placeholderContent?: string
}

export const Label = (props: props) => {
    const isDev = useIsEnv().isDev;

    const textTitleColour = "text-solid-bold-green";
    const labelBorderColour = "border-b-solid-bold-green";

    const opaqueClassForPlaceholder = props.placeholderContent != "" && props.placeholderContent === props.text ? true : false;

    const labelTextColour = opaqueClassForPlaceholder ? "text-secondary-deep-blue-30" : "text-solid-white";

    return (
        <div className={`${props.className} mb-1`} id={`jbp-label-${ConvertStringToHtmlId(props.title)}`} title={isDev ? "DevInfo: Label" : ""}>
            <label className={`jbp-label top-11 left-3 text-xs ${textTitleColour}`} >
                {props.title}
                {props.displayEditableIcon === true ? <img key="img" src={`/editButton.png`} className="inline-block w-[14px] pb-1 pl-1" /> : <span></span>}
            </label>
            <div>
                <label className={`jbp-label-content h-12 mt-5 pt-4 ${labelBorderColour} ${labelTextColour}`}>{props.text}</label>
            </div>
        </div>
    )
}
