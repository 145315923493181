import { EditBookingRequest } from "../../../APIDocs/apiTypes";
import { Model } from "../Model";

export class EditJobMessageFactory {

    public static MakeMessage(): EditBookingRequest {
        const model = Model.GetModel();

        //We don't edit the postCode as part of update/edit job therefor grab from data source that useStoreBooking.ts puts it in.
        const request: EditBookingRequest = {
            AddressDetails: {
                AddressName: model.Get("model.results.siteAddressDetails.siteName"),
                AddressLine1: model.Get("model.results.siteAddressDetails.line1"),
                AddressLine2: model.Get("model.results.siteAddressDetails.line2"),
                AddressLine3: model.Get("model.results.siteAddressDetails.line3"),
                Town: model.Get("model.results.siteAddressDetails.town"),
                County: model.Get("model.results.siteAddressDetails.county"),
                PostCode: model.Get("updateJobModel.siteAddress.postCode")
            },
            SiteDetails: {
                Title: model.Get("model.results.siteContactDetails.title"),
                ContactFirstName: model.Get("model.results.siteContactDetails.firstname"),
                ContactSurname: model.Get("model.results.siteContactDetails.surname"),
                EmailAddress: model.Get("model.results.siteContactDetails.email"),
                ContactNumber: model.Get("model.results.siteContactDetails.number")
            },
            JobComments: model.Get("model.results.jobComments"),
        };
        return request;
    }
}
