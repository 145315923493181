import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { Dropdown } from "../components/Dropdown";
import { Typography } from "../components/Typography";
import { cancelBookingErrorType, cancelBookingSuccessType } from "../../../APIDocs/apiTypes"
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { useModel } from "../hooks/useModel";
import { useSynchronousState } from "../hooks/useSynchronousState";
import { ValidationResponse } from "../components/ValidationResponse";

const siemensUserCancellationReasons = [
    { description: "Booked in Error" },
    { description: "Dispatch Issue" },
    { description: "ECO Job Took Priority" },
    { description: "Force Majeure" },
    { description: "Insufficient Consumable Equipment" },
    { description: "Insufficient Meter Stock" },
    { description: "Insufficient Time to Complete Job" },
    { description: "Lack of Resource - Annual Leave" },
    { description: "Lack of Resource - Emergency Leave" },
    { description: "Lack of Resource - Engineer Left" },
    { description: "Lack of Resource - Meeting" },
    { description: "Lack of Resource - Sickness" },
    { description: "Lack of Resource - Stood Down" },
    { description: "Lack of Resource - Training" },
    { description: "New Date Required" },
    { description: "Requested by Supplier/ Customer" },
    { description: "Site Not Ready" },
    { description: "Vehicle Breakdown" },
    { description: "Weather" }
];

const supplierUserCancellationReasons = [
    { description: "Booked in Error" },
    { description: "Duplicate Booking" },
    { description: "Incorrect Job Type" },
    { description: "Network/ System Outage" },
    { description: "New Date Required" },
    { description: "No Permit/ RAMS received" },
    { description: "Site Not Ready" },
    { description: "Work No Longer Required" }
];

export interface props {
    bookingId: string,
    mpan: string,
    serviceLine: string
}

export const CancelJob = (props: props) => {

    const model = useModel();
    const navigate = useNavigate();

    const selectedReasonForCancellation = useSynchronousState<string | undefined>(undefined);
    const selectedSupplierReasonForCancellation = useSynchronousState<string | undefined>(undefined);

    const reasonsForCancellationPath = "model.dataFieldsSource.reasonsForCancellation";
    const supplierReasonsForCancellationPath = "model.dataFieldsSource.supplierReasonsForCancellation";

    const reasonForCancellationPath = "model.selectedReasonForCancellation";
    const supplierReasonForCancellationPath = "model.selectedSupplierReasonCancellation";


    const userType = model.Get("viewModel.fetchedData.UserTypeForCurrentUser");

    const [showCancelJobModal, setShowCancelJobModal] = useState(false);
    const [showCancelLoadingModal, setShowCancelLoadingModal] = useState(false);
    const [showCancelJobSuccessModal, setShowCancelJobSuccessModal] = useState(false);
    const [showCancelJobErrorModal, setShowCancelJobErrorModal] = useState(false);

    const mapOfModalFunctions: { [key: string]: React.Dispatch<React.SetStateAction<boolean>> } = {
        "cancelJobModal": setShowCancelJobModal,
        "cancelJobLoadingModal": setShowCancelLoadingModal,
        "cancelJobSuccessModal": setShowCancelJobSuccessModal,
        "cancelJobErrorModal": setShowCancelJobErrorModal
    };

    const openSpecificModalAndCloseAllOtherModals = (key: string) => {
        Object.keys(mapOfModalFunctions).forEach((funcKey: string) => {
            if(funcKey !== key || key === "closeEverything") {
                mapOfModalFunctions[funcKey](false);
            }
        });

        if(mapOfModalFunctions[key] != undefined) {
            mapOfModalFunctions[key](true);
        } else {
            console.log("Invalid key, no modal to show");
        }
    }

    const [showSupplierReasonsDropdown, setShowSupplierReasonsDropdown] = useState(false);
    const userCancellationReasons = userType === 'INTERNAL' ? siemensUserCancellationReasons : supplierUserCancellationReasons;

    model.Set(reasonsForCancellationPath, userCancellationReasons.map(cr => cr.description));
    model.Set(supplierReasonsForCancellationPath, supplierUserCancellationReasons.map(cr => cr.description));

    const resetAbortDataAndShowCancellationDialog = () => {
        openSpecificModalAndCloseAllOtherModals("cancelJobModal");
        model.ClearModelValue(reasonForCancellationPath);
        model.ClearModelValue(supplierReasonForCancellationPath)
        selectedReasonForCancellation.set(undefined);
        selectedSupplierReasonForCancellation.set(undefined);
    }

    const onReasonForCancellationSelected = (index: number): ValidationResponse | undefined => {
        const reasonForCancellation = userCancellationReasons[index].description;
        selectedReasonForCancellation.set(reasonForCancellation);
        if(selectedReasonForCancellation.get()?.toLowerCase() === 'requested by supplier/ customer') {
            setShowSupplierReasonsDropdown(true);
        } else {
            setShowSupplierReasonsDropdown(false);
            model.Set(reasonForCancellationPath, reasonForCancellation);
        }
        return { isValid: true };
    }

    const onSupplierReasonsSelected = (index: number): ValidationResponse | undefined => {
        const supplierReasonsDropdown = supplierUserCancellationReasons[index].description;
        selectedSupplierReasonForCancellation.set(supplierReasonsDropdown);
        model.Set(supplierReasonForCancellationPath, supplierReasonsDropdown);
        return { isValid: true };
    }

    const createCancelBookingPayload = () => {
        //define data to send to CHUBI
        const supplierReason = selectedSupplierReasonForCancellation.get();
        const customerReason = selectedReasonForCancellation.get();

        const cancelBookingData = {
            jobCancellationReason: showSupplierReasonsDropdown === true ? supplierReason : customerReason
        }

        return cancelBookingData;

    }

    const submitJobCancellation = () => {
        triggerSubmitJobCancellation();
        openSpecificModalAndCloseAllOtherModals("cancelJobLoadingModal");
    }

    const isCancellationSubmitButtonDisabled = (): boolean => {
        if(selectedReasonForCancellation.get()?.toLowerCase() === 'requested by supplier/ customer' && selectedSupplierReasonForCancellation.get() == undefined) {
            return true;
        }
        if(selectedReasonForCancellation.get() != undefined) {
            return false;
        }
        return true;
    }

    const onSuccessfulJobCancellation = () => {
        openSpecificModalAndCloseAllOtherModals("cancelJobSuccessModal");
    }

    const onJobCancellationError = () => {
        openSpecificModalAndCloseAllOtherModals("cancelJobErrorModal");
    }

    const handleJobSuccessfullyCancelledModal = () => {
        openSpecificModalAndCloseAllOtherModals("closeEverything");
        navigate(`/site/${props.mpan}/${props.serviceLine}`);
    }

    const { refetch: triggerSubmitJobCancellation } = useJbpQuery<cancelBookingSuccessType, cancelBookingErrorType>(RestTypes.Delete, createCancelBookingPayload(), [`${props.bookingId}-cancelJob`], `jbp/${props.mpan}/booking/${props.bookingId}`, false, props.serviceLine, onSuccessfulJobCancellation, onJobCancellationError);

    const modalTitle = "Job Cancellation";

    return (
        <div>
            {
                <Button text="Cancel Job" primary={true} onClick={resetAbortDataAndShowCancellationDialog} disabled={false} className="mt-2" widthStyling="w-full" />
            }
            {
                showCancelLoadingModal ?
                    <Modal title={modalTitle}>
                        <Typography content={`Please wait whilst your job cancellation is processed`} className="mb-3 loading" />
                        <span className="pl-10"></span>
                    </Modal>
                    :
                    <span></span>
            }
            {
                showCancelJobModal ?
                    <Modal title={modalTitle}>
                        <Dropdown title="Reason for Cancellation" mandatory={true} placeholder="Please select the Reason for Cancellation"
                            elementsDataSource={reasonsForCancellationPath} onSelect={onReasonForCancellationSelected} modelPath={reasonForCancellationPath} />
                        {
                            showSupplierReasonsDropdown ?
                                <Dropdown title="Supplier Reasons for Cancellation" mandatory={true} placeholder="Please select Supplier Reason for Cancellation"
                                    elementsDataSource={supplierReasonsForCancellationPath} onSelect={onSupplierReasonsSelected} modelPath={reasonForCancellationPath} />
                                :
                                <span></span>
                        }
                        <Button primary={false} text="Cancel" className="mr-4 mt-5" disabled={false} onClick={() => setShowCancelJobModal(false)} />
                        <Button text="Submit" primary={true} onClick={submitJobCancellation} disabled={isCancellationSubmitButtonDisabled()} className="mt-2" />
                    </Modal>
                    :
                    <span></span>
            }
            {
                showCancelJobSuccessModal ?
                    <Modal title={modalTitle}>
                        <Typography content={`The Job has been cancelled successfully`} className="mb-3" />
                        <Button primary={false} text="OK" className="mr-4 mt-5" disabled={false} onClick={handleJobSuccessfullyCancelledModal} />
                    </Modal>
                    :
                    <span></span>
            }
            {
                showCancelJobErrorModal ?
                    <Modal title={modalTitle}>
                        <Typography content={`Something went wrong, please try again later`} className="mb-3" />
                        <Button primary={false} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => setShowCancelJobErrorModal(false)} />
                    </Modal>
                    :
                    <span></span>
            }
        </div>
    )
}
