import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOffersSuccessType, getOfferServicePowerErrorResponse, getSiteErrorType, getSiteSuccessType, Offer, OffersRequest, getPromiseSetStandingDataSuccessType, getPromiseSetStandingDataErrorType, getPromiseSetStandingDataResponse200, UserPermissions } from "../../../APIDocs/apiTypes"
import { Button } from "../components/Button"
import { GenericLoadingElement } from "../components/GenericLoadingElement"
import { List } from "../components/List"
import { feedbackColour, ListItem } from "../components/ListItem"
import { Title } from "../components/Title"
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery"
import { useDefinedParams } from "../hooks/useDefinedParams"
import { useModel } from "../hooks/useModel"
import { useStoreSite } from "../hooks/useStoreSite"
import { Logger } from "../Logger"
import { useSynchronousState } from "../hooks/useSynchronousState"
import { ApiError } from "./ApiError"
import { DatePicker } from "../components/DatePicker"
import { DateTime } from "luxon"
import { Model } from "../Model"
import { Modal } from "../components/Modal"
import { Typography } from "../components/Typography"
import { Dropdown } from "../components/Dropdown"
import { ValidationResponse } from "../components/ValidationResponse"

//NON SUPPLIER VERSION OF THE OFFERS PROCESS, SPECIFICALLY TO ALLOW TIME BAND AND FORCE OPTIONS WHERE APPLICABLE.
export const BookJobOffersInternal = () => {

    const showMoreIncrement = 7;
    const [showMoreCount, setShowMoreCount] = useState(showMoreIncrement);
    const getOffersError = useSynchronousState("");
    const standingDataPopulated = useSynchronousState(false);
    const navigate = useNavigate();
    const model = useModel();

    const verifyUserType = () => {
        const userType = Model.GetModel().Get("viewModel.fetchedData.UserTypeForCurrentUser");
        if(userType == null || userType !== "INTERNAL") {
            navigate('/404');
            return <span></span>
        }
    }

    //GET US OUT OF HERE IF NOT CORRECT USER TYPE.
    verifyUserType();

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const storeSiteData = useStoreSite();

    const timeBandModelSource = 'model.dataFieldsSource.timeBandForGetOffers';

    const timeBandTwoHoursObjectModelSource = 'model.dataFieldsSource.timeBandOffers2Hours';
    const timeBandFourHoursObjectModelSource = 'model.dataFieldsSource.timeBandOffers4Hours';

    const timeBandTwoHoursModelSource = 'model.dataFieldsSource.timeBandSlotsTwoHours';
    const timeBandFourHoursModelSource = 'model.dataFieldsSource.timeBandSlotsFourHours';
    const forceOffersOptionsModelSource = 'model.dataFieldsSource.forceOffersSelection';
    const isWindowJobBookingModelSource = 'model.dataFieldsSource.isWindowJobBooking';

    const timeBandSlotOptions = ["All Day", "4 Hour", "2 Hour"];
    const yesNoDropdownOptions = ["Yes", "No"];

    model.Set(timeBandModelSource, timeBandSlotOptions);
    model.Set(forceOffersOptionsModelSource, yesNoDropdownOptions);
    model.Set(isWindowJobBookingModelSource, yesNoDropdownOptions);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState("All Day");

    //Modal controls
    const [showConfirmOfferSelection, setShowConfirmOfferSelection] = useState(false);
    const [showDateErrorModal, setShowDateErrorModal] = useState(false);
    const [isWindowJobBooking, setIsWindowJobBooking] = useState(false);
    const [isDateSelected, setDateSelected] = useState(false);

    const postCode = model.Get("model.results.siteAddressDetails.postCode") != null ? model.Get("model.results.siteAddressDetails.postCode") : model.Get("viewModel.fetchedData.siteAddress.postCode");

    const defaultAllDayTimeBandStart = "0800";
    const defaultAllDayTimeBandEnd = "1700";

    const preferredDate = DateTime.now().toFormat("yyyy-MM-dd");

    const [queryData, setQueryDataForGetOffers] = useState<OffersRequest>({
        mpxn: mpan,
        jobType: model.Get("model.results.jobType"),
        fuel: "E",
        siteMpid: model.Get("viewModel.fetchedData.MPID"),
        postcode: postCode,
        startDate: model.Get("viewModel.fetchedData.appointment.startDate"),
        endDate: model.Get("viewModel.fetchedData.appointment.endDate"),
        timeBandStart: defaultAllDayTimeBandStart,
        timeBandEnd: defaultAllDayTimeBandEnd,
        force: false,
        promiseSetId: "",
        preferredDate: preferredDate,
    });

    const onSiteData = (siteData: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, siteData);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Error(`Data found to not exist -> ${err.msg}`)
    }

    const determineFriendlyErrorMessageForGetOffers = (errorCode: string | undefined) => {
        switch(errorCode) {
            case "8":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "75":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "156":
                return "There is no Siemens engineer coverage for this site between the Job Start and End Dates requests"
            case "179":
                return "The Postcode of the Site the Appointment is not set up in the Siemens Scheduling Solution"
            default:
                return "There was an error with this request, please try again"
        }
    }

    const onGetPromiseSetDataSuccess = (standingDataResp: getPromiseSetStandingDataSuccessType) => {
        const timeBand2Hours = standingDataResp?.timeBands.filter((timeBand) => {
            return timeBand.promiseSetDescription === '2 Hours';
        })

        const timeBand4Hours = standingDataResp?.timeBands.filter((timeBand) => {
            return timeBand.promiseSetDescription === '4 Hours';
        })

        model.Set(timeBandTwoHoursObjectModelSource, timeBand2Hours);
        model.Set(timeBandFourHoursObjectModelSource, timeBand4Hours);

        model.Set(timeBandTwoHoursModelSource, timeBand2Hours.map((timeBand) => timeBand?.apptBandDescription));
        model.Set(timeBandFourHoursModelSource, timeBand4Hours.map(timeBand => timeBand?.apptBandDescription));

        standingDataPopulated.set(true);
        console.log("Finished mapping standing data for promise set onto the model");
    }

    const onGetPromiseSetDataError = (err: getPromiseSetStandingDataErrorType) => {
        console.log("Error returned from service power:" + err)
        const errorCodeFromSP = determineFriendlyErrorMessageForGetOffers(err?.msg);

        getOffersError.set(errorCodeFromSP);
    }

    const onGetOffersError = (err: getOfferServicePowerErrorResponse) => {
        console.log("Error returned from service power:" + err)
        const errorCodeFromSP = determineFriendlyErrorMessageForGetOffers(err?.errorCode);

        getOffersError.set(errorCodeFromSP);
    }

    const { isLoading, data, refetch: refetchOffers, isFetching } = useJbpQuery<getOffersSuccessType, getOfferServicePowerErrorResponse>(RestTypes.Post, queryData, ['offersInternal'], `jbp/offers/request`, true, serviceLine, undefined, onGetOffersError);

    const { isLoading: loadingPromiseSetStandingData } = useJbpQuery<getPromiseSetStandingDataSuccessType, getPromiseSetStandingDataErrorType>(RestTypes.Get, undefined, ['standingDataPromiseSets'], `jbp/standingData/promiseSets`, true, serviceLine, onGetPromiseSetDataSuccess, onGetPromiseSetDataError);

    useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site-bookJobOffers'], `jbp/${mpan}/site/`, true, serviceLine, onSiteData, onSiteDataError);

    if(storeSiteData.dataStored == false) {
        return <span></span>
    }

    const onShowMoreClick = () => {
        setShowMoreCount(showMoreCount + showMoreIncrement);
    }

    const isWarrantJob = () => {
        const selectedJobType: string = Model.GetModel().Get('viewModel.selectedJobKind');

        if(selectedJobType?.toLowerCase().indexOf("warrant") > -1) {
            return true;
        }
        return false;
    }

    const listItemClicked = (offer: Offer) => {
        model.Set("model.results.offerSelected.scheduledDate", offer.ScheduledDate);
        model.Set("model.results.offerSelected.offerToken", offer.OfferToken);
        model.Set("model.results.offerSelected.spJobCode", offer.SpJobCode);
        model.Set("model.results.offerSelected.dayOfWeek", offer.DayOfWeek);
        model.Set("model.results.offerSelected.apptBand", offer.ApptBand);
        model.Set("model.results.offerSelected.apptDuration", offer.ApptDuration);
        model.Set("model.results.offerSelected.startTime", offer.StartTime);
        model.Set("model.results.offerSelected.endTime", offer.EndTime);
        model.Set("model.results.offerTokenSelected", true);

        if(isWarrantJob()) {
            setShowConfirmOfferSelection(true);
        } else {
            navigate(`/bookJob/communications/${mpan}/${serviceLine}`)
        }
    }

    const handleWindowJobBooking = () => {
        if(isDateSelected) {
            model.Set("model.results.offerTokenSelected", true);
            if(isWarrantJob()) {
                navigate(`/bookingResult/${mpan}/${serviceLine}`);
            } else {
                navigate(`/bookJob/communications/${mpan}/${serviceLine}`);
            }
        }
    }

    const handleBookJobSelection = () => {
        navigate(`/bookingResult/${mpan}/${serviceLine}`)
    }

    const updateDateValueToSearchWith = (date: DateTime | undefined) => {
        const startDateOfMOPAppointment = DateTime.fromMillis(parseInt(Model.GetModel().Get("viewModel.fetchedData.appointment.startDate"))).startOf("day");
        const currentDate = DateTime.now().startOf("day");
        const newSelectedDate = date?.startOf("day");

        if(newSelectedDate && date) {
            setDateSelected(true);

            const isSelectedDateAheadOfAppointmentStartDate = newSelectedDate >= startDateOfMOPAppointment;
            const isSelectedDateInFuture = newSelectedDate >= currentDate;
            const newPreferredDate = date?.toFormat("yyyy-MM-dd") ?? '';
            const windowStartDate = date?.toISO()?.split('.')[0] + "Z";

            model.Set("model.windowBookingStartDate", windowStartDate);

            if(isSelectedDateAheadOfAppointmentStartDate && isSelectedDateInFuture) {
                setQueryDataForGetOffers((prevState) => ({ ...prevState, startDate: `${newSelectedDate.toMillis()}`, preferredDate: newPreferredDate }));
            } else if((serviceLine === "SUBMETER" || serviceLine === "DNO" || serviceLine === "BNO") && isSelectedDateInFuture && !isSelectedDateAheadOfAppointmentStartDate) {
                //SUBMETER jobs can have no appointment and should allow any future job to be booked against them.
                setQueryDataForGetOffers((prevState) => ({ ...prevState, startDate: `${newSelectedDate.toMillis()}`, preferredDate: newPreferredDate }));
            } else {
                setShowDateErrorModal(true);
            }
        }
    }

    const getDateTimeInPlainText = () => {
        return model.Get("model.results.offerSelected.scheduledDate");
    }

    const offersToDisplay = data != undefined ? data.filter((offer, index) => index < showMoreCount) : []

    const isShowMoreButtonDisbled = () => {
        return data != undefined && data.length <= showMoreCount
    }

    const showForceOption = () => {
        const userPermissionsString: string = Model.GetModel().Get("viewModel.fetchedData.UserPermissionsForCurrentUser");
        let userPermissionsFromModel: UserPermissions | undefined = undefined;
        try {
            userPermissionsFromModel = JSON.parse(userPermissionsString);
        } catch(error) {
            console.warn('Failed to parse user perms, this is expected until the model is populated at which point a re-render will happen and be successful');
        }

        return userPermissionsFromModel?.canForceOffers === true;
    }

    const onTimeSlotSelection = (index: number): ValidationResponse | undefined => {
        console.log("TimeSlot selected: " + timeBandSlotOptions[index]);
        const selectedTimeSlot = timeBandSlotOptions[index];

        //to stop the previous time band/promiseSetId being used when All Day has been re-selected
        if(selectedTimeSlot === 'All Day') {
            setQueryDataForGetOffers((prevState) => ({ ...prevState, timeBandStart: defaultAllDayTimeBandStart, timeBandEnd: defaultAllDayTimeBandEnd, promiseSetId: "", timeBandId: "A1" }))
        }

        setSelectedTimeSlot(selectedTimeSlot);
        return { isValid: true };
    }

    const onForceOffersSelection = (index: number): ValidationResponse | undefined => {
        const forceValueForGetOffers = yesNoDropdownOptions[index];
        const forceResult = forceValueForGetOffers === "Yes" ? true : false;
        setQueryDataForGetOffers((prevState) => ({ ...prevState, force: forceResult }))
        return { isValid: true };
    }

    const onWindowJobBookingSelection = (index: number): ValidationResponse | undefined => {
        setIsWindowJobBooking(yesNoDropdownOptions[index] === "Yes");
        return { isValid: true };
    }

    const onTimeBandSelection = (index: number): ValidationResponse | undefined => {
        const timeOptions = selectedTimeSlot === '4 Hour' ? model.Get(timeBandFourHoursModelSource) : model.Get(timeBandTwoHoursModelSource);
        const timeObjectOptions: getPromiseSetStandingDataResponse200['timeBands'] = selectedTimeSlot === '4 Hour' ? model.Get(timeBandFourHoursObjectModelSource) : model.Get(timeBandTwoHoursObjectModelSource);

        const timeBandValueArr = timeOptions[index].split(' - ');
        const newTimeBandStart = timeBandValueArr[0].replaceAll(":", "");
        const newTimeBandEnd = timeBandValueArr[1].replaceAll(":", "");

        //filter the timeBands response data for the timeOptions[index] and return the object associated with the selected timeslot
        //then grab the appointmentBandId and the promiseSetId value to set on the query object.
        const timeBand = timeObjectOptions[index].appointmentBandId;
        const promiseSetId = timeObjectOptions[index].promiseSetId;

        setQueryDataForGetOffers((prevState) => ({ ...prevState, timeBandStart: newTimeBandStart, timeBandEnd: newTimeBandEnd, promiseSetId: promiseSetId, timeBandId: timeBand }));
        return { isValid: true };
    }

    const determineTimeBandDropDown = () => {
        return selectedTimeSlot === "2 Hour" ? timeBandTwoHoursModelSource : timeBandFourHoursModelSource;
    }


    const title = "Your appointment offers"
    return (
        <div className="ml-10 pb-5">
            <Title text={title} className="mt-7 mb-4"></Title>

            {
                !loadingPromiseSetStandingData && standingDataPopulated.get() === true ?
                    <div>
                        <div className="block">
                            <Typography content={`Please select a date for the job. We have initially provided a list of offers. If you require a different date, please use the date picker.`} className="mb-3" />
                        </div>
                        <div className="block">
                            <DatePicker className="inline-block mr-5" title="Select Date For Job" mandatory={false} onDateSelected={updateDateValueToSearchWith} ></DatePicker>

                            <Dropdown classProps="inline-block mr-5" title="Window Job" mandatory={false} placeholder="Please Confirm whether this job is a window booking?" elementsDataSource={isWindowJobBookingModelSource} onSelect={onWindowJobBookingSelection} modelPath="model.isWindowJobBooking" initialValue="No" />

                            <Dropdown classProps="inline-block mr-5" title="Time Slot" mandatory={false} placeholder="Please Select the appointment time slot" elementsDataSource={timeBandModelSource} onSelect={onTimeSlotSelection} modelPath="model.selectedTimeSlotValueForGetOffers" initialValue="All Day" />
                            {
                                selectedTimeSlot !== "All Day" ?
                                    <Dropdown classProps="inline-block mr-5" title="Time Band" mandatory={false} placeholder="Please Select the appointment timeband" elementsDataSource={determineTimeBandDropDown()} onSelect={onTimeBandSelection} modelPath="model.selectedTimeBandForGetOffers" initialValue="" />
                                    :
                                    <span></span>
                            }

                            {
                                showForceOption() === true ?
                                    <Dropdown classProps="inline-block mr-5" title="Force" mandatory={false} placeholder="Force Job Onto Schedule?" elementsDataSource={forceOffersOptionsModelSource} onSelect={onForceOffersSelection} modelPath="model.forceOffersSelection" initialValue="No" />
                                    :
                                    <span></span>
                            }

                        </div>
                        {
                            isWindowJobBooking ?
                                <Button text="Continue With Window Job Booking" primary={true} disabled={isLoading} onClick={handleWindowJobBooking} className="mb-2"></Button>
                                :
                                <Button text="Search" primary={true} disabled={isLoading} onClick={refetchOffers} className="mb-2"></Button>
                        }
                    </div> :
                    <span></span>
            }
            {
                isLoading || loadingPromiseSetStandingData || isFetching ?
                    <GenericLoadingElement count={showMoreIncrement} height="h-16" className="jbp-offers-loading w-4/6"></GenericLoadingElement>
                    :
                    offersToDisplay != undefined && offersToDisplay.length > 0 && !isWindowJobBooking ?
                        <div>
                            <List title={title} data={offersToDisplay}>
                                {offersToDisplay.map((offer, index) => {
                                    return <ListItem title="Appointment" className="mr-10 w-4/6" cells={[`${offer.ScheduledDate}`]} feedbackColour={feedbackColour.green} key={index} tagText="Available" onClick={() => listItemClicked(offer)} />
                                })}
                            </List>
                            <Button text="Show more" primary={true} disabled={isShowMoreButtonDisbled()} onClick={onShowMoreClick} className="mt-2" />
                        </div>
                        :
                        isWindowJobBooking ?
                            <div>
                                <Typography content={`You have selected to book a window job. Please ensure a date has been selected before continuing.`} className="mb-3" />
                            </div>
                            :
                            <ApiError errorDescription={getOffersError.get()}></ApiError>
            }
            {
                showDateErrorModal ?
                    <Modal title="Error" >
                        <Typography content={`The date you have selected is in the past and/or is not within your assigned appointment range.`} className="mb-3" />
                        <Typography content={`Please select another date or contact Siemens to arrange the appointment.`} className="mb-3" />
                        <Button primary={true} text="OK" className="mr-4 mt-5" disabled={false} onClick={() => setShowDateErrorModal(false)} />
                    </Modal>
                    :
                    <span></span>
            }
            {
                showConfirmOfferSelection ?
                    <Modal title="Continue with Job Booking?" >
                        <Typography content={`You have selected: ${getDateTimeInPlainText()}.`} className="mb-3" />
                        <Typography content={`If this is correct click Confirm to complete the booking.`} className="mb-3" />
                        <Button primary={false} text="Cancel" className="mr-4 mt-5" disabled={false} onClick={() => setShowConfirmOfferSelection(false)} />
                        <Button primary={true} text="Confirm" className="mr-4 mt-5" disabled={false} onClick={handleBookJobSelection} />
                    </Modal>
                    :
                    <span></span>
            }
        </div>
    )
}
