import { useState } from "react"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"

export interface props {
    title: string,
    initiallySelected: boolean,
    onClick?: (selected: boolean) => void,
    currentlySelected?: boolean
}

export const Tag = (props: props) => {

    const [selected, setSelected] = useState(props.initiallySelected)

    if(props.currentlySelected != undefined && props.currentlySelected != selected) {
        setSelected(props.currentlySelected)
    }

    const onClick = () => {
        const newSelectedValue = !selected;
        if(props.onClick != undefined) {
            props.onClick(newSelectedValue);
        }
        setSelected(newSelectedValue);
    }

    const selectedClass = "bg-solid-bold-green text-solid-deep-blue border-solid-bold-green hover:text-solid-deep-blue jbp-tag-selected";
    const unselectedClass = "text-interactive-coral border-interactive-coral hover:border-solid-bold-green jbp-tag-unselected"

    return (
        <div id={`jbp-tag-${ConvertStringToHtmlId(props.title)}`} onClick={onClick} className={`inline-block cursor-pointer p-2 pr-5 pl-5 m-1 ml-0 mr-2 font-bold text-sm rounded-3xl border-2 hover:text-solid-bold-green ${selected ? selectedClass : unselectedClass}`}>
            <span className="inline-block">{props.title}</span>
        </div>
    )
}
