import { TreeNodeBuilder } from "../../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ITreeNode } from "../../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JobCommentsNode } from "../../nodes/JobCommentsNode";
import { SiteAddressDetailsNode } from "../../nodes/SiteAddressDetailsNode";
import { SiteContactDetailsNode } from "../../nodes/SiteContactDetailsNode";
import { JbpTreeNodeData } from "../../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./../IWorkflowFactory";
import { DNOBNOQuestions } from "../../nodes/DNOBNOSpecificQuestionsNode";

export class DNOBookJobSiteInfoWorkflowFactory extends IWorkflowFactory {

    private siteContactDetailsNode = new SiteContactDetailsNode();
    private siteAddressDetailsNode = new SiteAddressDetailsNode();
    private jobCommentsNode = new JobCommentsNode(this.mpan, this.serviceLine);
    private dnoBnoSpecificQuestionsNode = new DNOBNOQuestions();

    constructor(mpan: string, serviceLine: string) {
        super(mpan, serviceLine);
    }

    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);

        builder
            .AddTreeNode(this.siteContactDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.siteAddressDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.dnoBnoSpecificQuestionsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.jobCommentsNode, IWorkflowFactory.sequenceRelationship);

        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }
}
