import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldConstraints, FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const captureWhatThreeWordsLocationRefMessagePath = "model.jobCommentPrependedMessages.whatThreeWordsReference";

class whatThreeWordsCommentsNode extends JbpTreeNodeData {
    constructor() {
        super("If known, please provide the What 3 Words location information", [
            {
                fieldType: FieldType.TextArea,
                title: "What 3 Words Location Information",
                placeHolder: "What 3 Words reference in the following format: wordone.wordtwo.wordthree",
                modelPath: "model.results.whatThreeWordsReference",
                isValid: (value: string) => {
                    const what3wordsRegex = /^[a-z]+\.[a-z]+\.[a-z]+$/;
                    if(value != null && value.length > 0) {
                        if(what3wordsRegex.test(value) === true) {
                            return {
                                isValid: what3wordsRegex.test(value)
                            }
                        } else {
                            return {
                                isValid: false,
                                errorMessage: "Please ensure your the What 3 Words reference is in the correct format. Three lowercase words seperated with fullstops and does not include any spaces."
                            }
                        }
                    }
                    return {
                        isValid: true
                    }
                },
                onChange: (value: string) => {
                    if(value.trim().length > 0) {
                        Model.GetModel().Set(captureWhatThreeWordsLocationRefMessagePath, `What 3 Words: ${value} \n`)
                    }
                    else {
                        Model.GetModel().Set(captureWhatThreeWordsLocationRefMessagePath, ``)
                    }
                },
                getDynamicFieldConstraints: () => {
                    return {
                        maxLength: 100
                    } as FieldConstraints;
                }
            } as IEditableLabelField
        ])
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(captureWhatThreeWordsLocationRefMessagePath);
    }
}

export class WhatThreeWordsCommentsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {
    constructor() {
        super("whatThreeWords", new whatThreeWordsCommentsNode())
    }
}
