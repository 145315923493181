import { useIsEnv } from "../../src/hooks/useIsEnv";

export interface props {
    text: string | undefined
    className?: string
}

export const Title = (props: props) => {
    const isDev = useIsEnv().isDev;
    return (
        <h1 title={isDev ? "DevInfo: Title" : ""} className={`text-xl jbp-title ${props.className}`}>{props.text}</h1>
    );
}
