import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";

export interface props {
    title: string,
    description: string,
    group: string,
    onExpanderClicked: () => void,
    expanded: boolean,
    onSelected: () => void,
    checked: boolean
}

const expander = (onclick: () => void, isExpanded: boolean, expanderId: string) => {

    if(!isExpanded) {
        return <svg id={`${expanderId}`} onClick={onclick} width="45" height="45" viewBox="0 0 45 45" className={`fill-interactive-coral hover:fill-solid-bold-green inline-block ml-2 w-4`} xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4312 19.5869L25.5038 0.0314517L20.6149 0.0133064L20.5423 19.5687L0.986914 19.4961L0.968769 24.385L20.5242 24.4576L20.4516 44.013L25.3405 44.0311L25.413 24.4757L44.9685 24.5483L44.9866 19.6595L25.4312 19.5869Z" />
        </svg>
    } else {
        return <svg id={`${expanderId}`} onClick={onclick} width="44" height="5" viewBox="0 0 44 5" className={`fill-interactive-coral hover:fill-solid-bold-green inline-block ml-2 w-4`} xmlns="http://www.w3.org/2000/svg">
            <path d="M44 0V4.29268H0V0H44Z" />
        </svg>
    }

}

export const RadioButton = (props: props) => {
    const radioTitle = ConvertStringToHtmlId(props.title);
    const expanderId = `jbp-radio-button-expander-${radioTitle}`
    const radioButtonId = `jbp-radio-button-${radioTitle}`
    const radioDescription = `jbp-radio-button-description-${radioTitle}`;

    return <div className="mb-5">
        <div className="w-80 inline-block" onClick={props.onSelected}>
            <input type="radio" id={`${radioButtonId}`} name={props.group} value={radioTitle} readOnly checked={props.checked}
                className={`translate-y-5 w-4 h-4 border-[2px] rounded-full bg-interactive-bold-green-db-12 border-secondary-deep-blue-30 hover:border-solid-bold-green before:w-2 before:h-2 before:rounded-full before:scale-0 before:shadow-radio-button checked:before:scale-[1px] checked:border-solid-bold-green`} />
            <label htmlFor={radioTitle} className="ml-7">{props.title}</label>
        </div>
        <div className="inline-block">
            {expander(props.onExpanderClicked, props.expanded, expanderId)}
        </div>

        <span id={radioDescription} className={`block whitespace-pre-wrap mt-3 mb-5 ml-8 mr-8 leading-6 text-sm text-secondary-deep-blue-20 font-italic overflow-hidden transition-[max-height] ease-in duration-300 ${props.expanded === true ? "max-h-40" : "max-h-0"}`}>
            {props.description}
        </span>
    </div>
}
