import { WorkflowView } from "./WorkflowView";
import { GenericBookJobSiteInfoWorkflowFactory } from "../../factories/GenericBookJobSiteInfoWorkflowFactory";
import { useDefinedParams } from "../../hooks/useDefinedParams";
import { useStoreSite } from "../../hooks/useStoreSite";
import { getSiteErrorType, getSiteSuccessType } from "../../../../APIDocs/apiTypes";
import { RestTypes, useJbpQuery } from "../../hooks/useJbpQuery";
import { Logger } from "../../Logger";
import { DNOBookJobSiteInfoWorkflowFactory } from "../../factories/SiteTypeBookJobSiteInfoWorkflowFactories/DNOBookJobSiteInfoWorkflowFactory";
import { BNOBookJobSiteInfoWorkflowFactory } from "../../factories/SiteTypeBookJobSiteInfoWorkflowFactories/BNOBookJobSiteInfoWorkflowFactory";

export const BookJobSiteInfo = () => {
    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const storeSiteData = useStoreSite();

    const onSiteData = (data: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, data);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Log(`Data found to not exist -> ${err.msg}`)
    }

    //should move this somewhere, but it doesn't feel right to have nested factories
    const determineSiteInfoWorkflow = (mpan: string, serviceLine: string) => {
        if(serviceLine === "DNO") {
            return new DNOBookJobSiteInfoWorkflowFactory(mpan, serviceLine);
        } else if(serviceLine === "BNO") {
            return new BNOBookJobSiteInfoWorkflowFactory(mpan, serviceLine);
        } else {
            return new GenericBookJobSiteInfoWorkflowFactory(mpan, serviceLine);
        }
    }

    const { refetch } = useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site-bookJobSiteInfo'], `jbp/${mpan}/site`, false, serviceLine, onSiteData, onSiteDataError)

    if(storeSiteData.dataStored == false) {
        refetch();
        return <span></span>
    }

    const siteInfoWorkflow = determineSiteInfoWorkflow(mpan, serviceLine);

    return <WorkflowView mpan={mpan} serviceLine={serviceLine} title="Booking Job for" workflowFactory={siteInfoWorkflow} />

}
