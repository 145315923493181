import { getSiteSuccessType } from "../../../APIDocs/apiTypes"
import { Card } from "../components/Card"
import { Label } from "../components/Label"
import { Title } from "../components/Title"
import { Typography } from "../components/Typography"
import { SiteAddress as SiteAddressComponent } from "./SiteAddress"

export interface props {
    dataErrorOccurred: boolean,
    data: getSiteSuccessType | undefined,
    error: { msg?: string | undefined } | null
    className?: string
}

export const SiteDetail = (props: props) => {

    return (
        <div className={props.className}>
            <Title className="mt-7 mb-4" text="Site Details:" />
            {props.error != undefined && props.error.msg == "Site not found" ?
                <Typography content="No data has been retrieved for the MPAN" />
                :
                <span></span>
            }
            {props.dataErrorOccurred == true ? <Typography content="Sorry, an error appears to exist with this data please contact ... for assistance" /> : <span></span>}
            {props.data == undefined && props.error != undefined && props.error.msg == "The server was unable to respond" ?
                <Typography content="Sorry, an error has occurred with the server, please contact ... for assistance" />
                :
                <span></span>
            }
            <Card className="p-5 pt-3" render={true} identifier="siteDetail">
                {props.data?.SiteAddress != undefined ? <SiteAddressComponent siteAddress={props.data?.SiteAddress} /> : <span></span>}
                {props.data?.SiteTypeDescription == undefined ? <div></div> : <Label title="Site Type" text={props.data.SiteTypeDescription}></Label>}
            </Card>
        </div>
    )
}
