import React from "react"
import { ChangeEvent, useEffect, useState } from "react"
import { Logger } from "../Logger"
import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";
import { useModel } from "../hooks/useModel";
import { useIsEnv } from "../../src/hooks/useIsEnv";

import { ValidationResponse } from "./ValidationResponse";

export interface props {
    title: string
    placeholder?: string
    onBlur?: (value: string) => ValidationResponse | undefined
    mandatory?: boolean
    className?: string
    initialValue?: string,
    modelPath?: string,
    maxLength?: number
}

enum validStates {
    uninitialised,
    invalid,
    valid
}

export const TextArea = (props: props) => {
    const isDev = useIsEnv().isDev;

    const getInitialValue = () => {
        if(props.modelPath != undefined && props.initialValue == null) {
            const valueFromModel = model.Get(props.modelPath) as string;
            if(valueFromModel != undefined) {
                return valueFromModel;
            }
            else {
                return props.initialValue == props.placeholder ? "" : props.initialValue
            }
        }
        else {
            return props.initialValue == props.placeholder ? "" : props.initialValue
        }
    }

    const model = useModel();
    const [valid, setValid] = useState(props.mandatory == true ? validStates.uninitialised : validStates.valid);
    const [errorMessage, setErrorMessage] = useState("");
    const [textValue, setTextValue] = useState<string | undefined>(getInitialValue());
    const [onBlurCounter, setOnBlurCounter] = useState(0);
    const inputRef = React.createRef<HTMLTextAreaElement>();

    const validateValue = (value: string | undefined) => {
        if(props.onBlur && value != undefined) {
            const validationResponse = props.onBlur(value)
            if(validationResponse) {
                if(validationResponse.isValid) {
                    setValid(validStates.valid)
                    setErrorMessage("");
                    if(props.modelPath != undefined) {
                        model.Set(props.modelPath, value)
                    }
                }
                else {
                    setValid(validStates.invalid)
                    if(validationResponse.errorMessage != undefined) {
                        setErrorMessage(validationResponse.errorMessage);
                    }
                    else {
                        setErrorMessage("An unknown error occurred")
                        Logger.Log(`Validation response object implied that an error was present, yet the error message was not defined: ${validationResponse}`)
                    }
                }
            }
        }
        else {
            setFocus();
        }
    }

    useEffect(() => {
        validateValue(textValue);
    }, [onBlurCounter])

    const onBlur = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setTextValue(e.target.value);
        setOnBlurCounter(onBlurCounter + 1)
    }

    const setFocus = () => {
        inputRef?.current?.focus();
    }

    const fieldIsInvalid = valid == validStates.invalid;

    const backgroundColour = fieldIsInvalid ? "bg-feedback-dark-red" : "bg-interactive-bold-green-db-12";
    const borderColour = fieldIsInvalid ? "border-b-feedback-red" : "border-b-solid-bold-green";
    const labelTextColour = fieldIsInvalid ? "text-feedback-red" : "text-solid-bold-green";

    const errorExists = () => errorMessage != undefined && errorMessage != ""

    const errorControl = () => errorExists() ? <label className={`text-feedback-red text-xs jbp-text-area-validation-message-${ConvertStringToHtmlId(props.title)}`}>{errorMessage}</label> : <br />

    return (
        <div title={isDev ? "DevInfo: TextArea" : ""} className={`${props.className} mb-1 min-w-[30em] w-fit mt-2`}>
            <div className={`cursor-text ${backgroundColour} ${borderColour} pl-3 border-b-2 pt-2 pb-2 pr-2 text-solid-white placeholder-shown:text-secondary-deep-blue-30`} onClick={setFocus}>
                <label className={`jbp-text-area cursor-text text-xs ${labelTextColour} block`}>{props.mandatory == true ? '*' : ''}{props.title}</label>
                <textarea maxLength={props.maxLength} ref={inputRef} id={`jbp-text-area-${ConvertStringToHtmlId(props.title)}`} className={`jbp-text-area mr-2 ${backgroundColour} focus:outline-none w-full min-h-[10em] scroll-my-1 ${errorExists() ? "invalid" : "valid"}`} placeholder={props.placeholder} onBlur={onBlur} defaultValue={textValue} />
            </div>
            <div>
                {errorControl()}
            </div>
        </div>
    )
}
