export const ConvertStringToHtmlId = (value:string) => {
    return RemoveInvalidHtmlIdCharacters(ConvertToKebabCase(value))
}

// https://www.w3.org/TR/html4/types.html#type-id
export const RemoveInvalidHtmlIdCharacters = (value:string) => {
    return value.replaceAll(/[~`!@#£$%^&*()+={[}\]|\\;:"'<,>?/' ]/g, '');
}

export const ConvertToKebabCase = (value:string):string=>{
    if(typeof value !== 'string'){
       throw 'Error: cannot convert non string values to kebab case'
    }
    if(value.replaceAll(" ", "") == "") {
        throw 'Error: cannot convert empty strings to kebab case'
    }
    if(value.trim() != value) {
        throw 'Error: values must not start or end with an empty string'
    }
    return value.replaceAll(' ', '-')
}
