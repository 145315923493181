import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { TreeNodePathStack } from "../../../../../libs/ts/WorkflowTree/TreeNodePathStack";
import { IVisitor } from "../../../../../libs/ts/WorkflowTree/Visitor/IVisitor";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

export class FetchVisitor extends IVisitor<JbpTreeNodeData, IJbpChildNodeRelationship> {

    private fetchArray: (() => void)[] = [];

    public override Visit(node: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>, _pathStack: TreeNodePathStack<JbpTreeNodeData, IJbpChildNodeRelationship>, _parent?: ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>): void {
        this.fetchArray.push(node.Data().Fetch);
    }

    public async FetchAllNodeData() {
        for(let i = 0; i < this.fetchArray.length; ++i) {
            await this.fetchArray[i]();
        }
        return Promise.resolve();
    }
}
