import { TreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./IWorkflowFactory";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { EditSiteAddressDetailsNode } from "../nodes/EditSiteAddressDetailsNode";
import { getBookingResponse200 } from "../../../APIDocs/apiTypes";
import { EditSiteContactDetailsNode } from "../nodes/EditSiteContactDetailsNode";
import { EditJobCommentsNode } from "../nodes/EditJobCommentsNode";

export class EditJobWorkflowFactory extends IWorkflowFactory {
    private siteContactDetailsNode: EditSiteContactDetailsNode;
    private jobCommentsNode: EditJobCommentsNode;
    private siteAddressDetailsNode: EditSiteAddressDetailsNode;

    private updateToSiteAndAddressDetailsMessageNode = new InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>("updateToSiteAndAddressDetailsMessageNode", new JbpTreeNodeData("Please be advised updates to address details below will not update the details held in our portfolio. To do so please submit a D0302 flow file", []));

    constructor(mpan: string, serviceLine: string, bookingData: getBookingResponse200) {
        super(mpan, serviceLine);

        this.siteContactDetailsNode = new EditSiteContactDetailsNode(bookingData);
        this.siteAddressDetailsNode = new EditSiteAddressDetailsNode(bookingData);
        this.jobCommentsNode = new EditJobCommentsNode(bookingData);
    }



    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);

        builder
            .AddTreeNode(this.updateToSiteAndAddressDetailsMessageNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.siteAddressDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.siteContactDetailsNode, IWorkflowFactory.sequenceRelationship)
            .AddTreeNode(this.jobCommentsNode, IWorkflowFactory.sequenceRelationship);

        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }
}
