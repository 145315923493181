import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";

export class WarrantOfficeDetailsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    constructor() {
        const warrantOfficerNameJobCommentsPath = "model.jobCommentPrependedMessages.warrantOfficerName"
        const warrantOfficerContactNumberJobCommentsPath = "model.jobCommentPrependedMessages.warrantOfficerContactNumber"

        const nameField = {
            fieldType: FieldType.EditableLabelField,
            title: "Name",
            placeHolder: "Please enter the full name of the warrant officer",
            modelPath: "model.results.warrantOfficerDetails.name",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) {
                    Model.GetModel().Set(warrantOfficerNameJobCommentsPath, `Warrant Officer Name: ${value} \n`);
                    return {
                        isValid: true
                    }
                }

                return {
                    isValid: false,
                    errorMessage: "Please ensure you enter a valid name"
                }
            }
        } as IEditableLabelField;

        const contactNumberField = {
            fieldType: FieldType.EditableLabelField,
            title: "Contact Number",
            placeHolder: "Please enter a contact number for the warrant officer",
            modelPath: "model.results.warrantOfficerDetails.contactNumber",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^0[12378]\d{8}\d?$/;
                const passes = regex.test(value);
                if(passes && value != null) {
                    Model.GetModel().Set(warrantOfficerContactNumberJobCommentsPath, `Warrant Officer Contact Number: ${value} \n`);
                    return { isValid: true }
                }

                return {
                    isValid: false,
                    errorMessage: "Please check your phone number is valid, ensuring no spaces"
                }
            }
        } as IEditableLabelField

        super("warrantOfficerDetails", new JbpTreeNodeData("Please provide contact details for the warrant officer who will be attending", [nameField, contactNumberField]));
    }
}
