import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId";
import { useIsEnv } from "../../src/hooks/useIsEnv";

export interface props {
    children: JSX.Element[] | JSX.Element
    className?: string,
    identifier: string,
    render: boolean
}

export const Card = (props: props) => {
    const isDev = useIsEnv().isDev;

    if(props.render) {
        return (
            <div title={isDev ? "DevInfo: Card" : ""} className={`jbp-card jbp-card-${ConvertStringToHtmlId(props.identifier)} ${props.className} bg-elevation-1 w-full`}>
                {props.children}
            </div>
        )
    }
    else {
        return <span className={`jbp-card-not-displayed-${ConvertStringToHtmlId(props.identifier)}`}></span>
    }
}
