import { ITreeNode } from "./Nodes/ITreeNode";

export class TreeNodePathStack<T, C> {
    private stack : ITreeNode<T, C>[] = [];

    public Push(node: ITreeNode<T, C>){
        this.stack.push(node);
    }

    public Pop() {
        this.stack.pop();
    }

    public toString():string {
        return this.stack.map((node)=>{
            return node.Identifier()
        }).join("/");
    }

    public Length() {
        return this.stack.length
    }

    public newPathStackFromIndex(index:number) {
        if(index <= this.stack.length - 1) {
            const newStack = new TreeNodePathStack<T, C>();
            this.stack.forEach((node, arrayIndex)=>{
                if(arrayIndex <= index) newStack.Push(node);
            })
            return newStack;
        }
        else {
            throw 'Failed to construct new pathStack from the given index, the current stack does not contain that many elements'
        }
    }
}
