import { ConvertStringToHtmlId } from "../../../../../libs/ts/ConvertStringToHtmlId"
import { Meters } from "../../../APIDocs/apiTypes"
import { Card } from "../components/Card"
import { Title } from "../components/Title"
import { Typography } from "../components/Typography"

export interface props {
    meters: Meters | undefined,
    dataErrorOccurred: boolean
    className?: string
}

export const MeterDetail = (props: props) => {

    const noMetersExist = (props.meters == undefined || props.meters?.length == 0) && props.dataErrorOccurred == false

    return (
        <div className={`${props.className} pl-5 pr-5`}>
            <Title className="mt-7 mb-4" text="Meters:" />
            <div className="overflow-y-scroll h-screen pr-2">
                {noMetersExist ? <Typography content="There are no meter details available to view. Once a meter has been installed, it will be displayed here" /> : <span></span>}
                {
                    props.meters?.map((meter, index) => {
                        if(meter == undefined || meter.Serial == undefined) {
                            return <span key={index}></span>
                        }
                        else {
                            const cardContent = `Manufacturer: ${meter.Manufacturer != null ? meter.Manufacturer : "Unknown"}
                        Year of Manufacture: ${meter.YearOfManufacture != null ? meter.YearOfManufacture : "Unknown"}
                        Supplier: ${meter.Supplier != null ? meter.Supplier : "Unknown"}
                        `
                            const title = `Serial Number: ${meter.Serial}`;

                            return (
                                <Card key={index} className="p-5 mb-4" render={true} identifier="meterDetail">
                                    <h2 className="font-bold pb-2">{title}</h2>
                                    <span id={`jbp-card-content-${ConvertStringToHtmlId(title)}`} className="whitespace-pre-line text-soft-elevation-0">{cardContent}</span>
                                </Card>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}
