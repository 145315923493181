import { useNavigate } from "react-router";
import { Button } from "../components/Button";
import { Typography } from "../components/Typography";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { ExternalLink } from "../components/ExternalLink";

export interface props {
    errorDescription: string | undefined
}

export const ApiError = (props: props) => {

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const navigate = useNavigate();

    const routeToSiteScreen = () => {
        navigate(`/site/${mpan}/${serviceLine}`);
    }

    const routeToSearchScreen = () => {
        navigate(`/`);
    }

    const errorMessage = props.errorDescription == undefined ? "There has been an unexpected network error processing your request" : props.errorDescription;

    return (
        <div id="jbp-job-screen">
            <div className="ml-10 mr-10">
                <Typography className="inline-block mr-1 pt-5" content={`${errorMessage}. If this issue persists please raise a ticket via the `}></Typography>
                <ExternalLink className="inline-block" link="https://queryportal.siemensmetering.co.uk" linkText="Siemens Query Portal" />
                <br></br>
                <Button text="Back to Site Screen" primary={true} onClick={routeToSiteScreen} className="mt-2" widthStyling="w-1/4" disabled={false} />
                <br></br>
                <Button text="Back to Search Screen" primary={true} onClick={routeToSearchScreen} className="mt-2" widthStyling="w-1/4" disabled={false} />
            </div>
        </div>
    )


}
