import { Model } from "../Model";

//To avoid polluting the BookJobCommunicationsWorkflowFactory with show/hide logic this class has been created.
export class BookJobCommunicationsHandler {

    public static shouldOnlyShowSmsInput = (): boolean => {
        const confSmsReq = Model.GetModel().Get("model.results.confirmationSMSRequired");
        const remSmsReq = Model.GetModel().Get("model.results.reminderSMSRequired");

        if((confSmsReq === true || remSmsReq === true) && (confSmsReq != undefined && remSmsReq != undefined)) {
            return true
        }

        return false;
    }

    public static confirmationSmsRequiredCompleted = (): boolean => {
        const confSmsReq = Model.GetModel().Get("model.results.confirmationSMSRequired");
        return confSmsReq === true || confSmsReq === false;
    }

    public static showCompleteWhereNoConfirmationsRequired = (): boolean => {
        const confSmsReq = Model.GetModel().Get("model.results.confirmationSMSRequired");
        const remSmsReq = Model.GetModel().Get("model.results.reminderSMSRequired");

        if((confSmsReq === false && remSmsReq === false)) {
            return true
        }

        return false;
    }


    public static showCompleteForBothInputs = (): boolean => {
        const shouldShowInputs = BookJobCommunicationsHandler.shouldOnlyShowSmsInput() === true;

        return shouldShowInputs;
    }
}
