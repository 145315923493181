import { useEffect, useState } from "react";
import { Observable, ObservableEvents } from "../Observable";
import { Modal } from "../components/Modal";
import { Typography } from "../components/Typography";
import { Button } from "../components/Button";
import { cookieExists, setCookie } from "../hooks/useCookieManager";
export interface props {
    userEmailForHeader: string
}

export const Header = () => {

    const checkForCookieAcceptance = () => {
        const showNotice = false;

        if(cookieExists("cookieNoticeAccepted") === false) {
            return true;
        }

        return showNotice;
    }

    const [userEmail, setUserEmail] = useState<string>("");
    const [showCookieNotice, setShowCookieNotice] = useState(checkForCookieAcceptance());

    useEffect(() => {
        Observable.GetObservable().observe(ObservableEvents.UserInfoLoaded, async (value: string) => {
            setUserEmail(value);
        });

        // Clean up the subscription when the component unmounts
        return () => {
            Observable.RemoveObservable();
        };
    }, []);

    const resetToSearchScreen = () => {
        window.location.pathname = `/`;
    }

    const cookiesRejectedRedirect = () => {
        window.location.href = 'https://siemens.com'
    }

    const saveAcceptenceCookieAndCloseBanner = () => {
        setCookie("cookieNoticeAccepted", "true", 5);
        setShowCookieNotice(false);
    }

    return (
        <div>
            {
                showCookieNotice ?
                    <Modal title="Cookie Notice" className="mr-[30%]">
                        <Typography content="By clicking “Accept and Continue”, you agree to the storing of cookies on your device that are required solely for the purpose of enabling site features. Deletion or rejection of cookies will result in navigation away from this site.
                        Cookies are not used for any marketing or tracking purposes."></Typography>
                        <Button text="Reject and Exit Site" primary={true} onClick={cookiesRejectedRedirect} disabled={false} className="mt-2 mr-4" />
                        <Button text="Accept and Continue" primary={true} onClick={saveAcceptenceCookieAndCloseBanner} disabled={false} className="mt-2" />
                    </Modal>
                    :
                    <span></span>
            }
            <div className="ml-10 grid grid-cols-10 gap-4 mr-10">
                <img src='/siemensLogoWhiteBanner.png' className='h-7 mt-7 col-start-1 col-end-4' id="jbp-siemens-logo" onClick={resetToSearchScreen} style={{ cursor: 'pointer' }} />
                <a className="mt-7 col-start-9 col-end-10 justify-self-end">{userEmail}</a>
                <img src='/user.png' className='h-5 mt-8 col-start-10 col-end-10' />
            </div>
            <div className='border-b-[1px] border-secondary-deep-blue-80 pb-4'>
            </div>
        </div>
    );
}
